import React from 'react';
import avatar from "../../img/avatar.png";
import {Trans} from "@lingui/macro";
import {Check, Edit, Eye} from "react-feather";

const ProfileDisplay = ({worker, selectionMode, addSelection, selected, editEmployee, preview}) => {
    return (
        <div className="profile-container">
            <div className="profile-information">
                <a className="machKeinAuge" target="_blank" rel="noreferrer"
                   href={"https://bumpee.de/" + worker.tag[0]}>
                    <Eye stroke={"#000"}/>
                </a>
                {
                    selectionMode || preview ?
                        <button className={"selection-ball top-right" + (selected ? " selected" : "")}
                                onClick={() => addSelection(worker)}
                        >
                            <Check/>
                        </button> : <></>
                }
                <img className="skeleton" src={worker.profile ?? avatar} alt={"Profile Picture of " + worker.name}/>
                <h2>{worker.name}</h2>
                <p style={{minHeight: "21px"}}>{worker.description}</p>
            </div>
            {
                selectionMode || preview ?
                    <button className={"btn" + (!selected ? " selectBtn" : "")}
                            onClick={() => addSelection(worker)}
                    >
                        {
                            !selected ?
                                <Trans>Auswählen</Trans>
                                :
                                <Trans>Ausgewählt</Trans>
                        }
                    </button>
                    :
                    <button className="btn whiteButton"
                            onClick={() => {
                                editEmployee(worker.id)
                            }}
                    >
                        <Edit stroke={"#0a0a0a"}/>
                        <Trans>Bearbeiten</Trans>
                    </button>
            }
        </div>
    );
};

export default ProfileDisplay;