import React from 'react';
import {Trans} from "@lingui/macro";
import LoadableButton from "./LoadableButton";
import {Check, X} from "react-feather";

const OptionButtons = ({cancel, action, waiting = false, savePrompt = <Trans>Speichern</Trans>}) => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "1rem"
        }}>
            <button type="button" onClick={cancel} className="btn greyButton">
                <X stroke={"#0a0a0a"}/>
                <Trans>Abbrechen</Trans>
            </button>
            <button className="btn optionButton"
                    onClick={action}
            >
                <LoadableButton waiting={waiting} prompt={
                    <>
                        <Check stroke={"#fffffe"}/>
                        {savePrompt}
                    </>
                }/>
            </button>
        </div>
    );
};

export default OptionButtons;