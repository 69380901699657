import {initializeApp} from 'firebase/app';
import {getStorage} from "firebase/storage";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    updatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from "firebase/auth";

import {
    getFirestore,
} from "firebase/firestore";

import {
    getFunctions,
    httpsCallable
} from "firebase/functions";

import errorMessages from "./components/Login/FirebaseErrors.json"
import {t} from "@lingui/macro";

const firebaseConfig = {
    apiKey: "AIzaSyAfV_212rurPclTNaWObwBc1mXa7L8JLR4",
    authDomain: "cardsdev-4a55c.firebaseapp.com",
    databaseURL: "https://cardsdev-4a55c.firebaseio.com",
    projectId: "cardsdev-4a55c",
    storageBucket: "cardsdev-4a55c.appspot.com",
    messagingSenderId: "786751354633",
    appId: "1:786751354633:web:0f31b1e4ab66b8b5f845d8",
    measurementId: "G-9HGDTCZ3CS",
};

const app = initializeApp(firebaseConfig, {
    expexperimentalForceLongPolling: true
});
const functions = getFunctions(app, "europe-west3");
const auth = getAuth()
const db = getFirestore(app);
let storage = getStorage();

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async (setError) => {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err) {
        setError(mapErrorCode(err.message));
    }
};

const logInWithEmailAndPassword = async (email, password, setError) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        setError(mapErrorCode(err.code))
    }
};

const registerWithEmailAndPassword = async (email, password, setError) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
        // const user = res.user;
        // await addDoc(collection(db, "teams"), {
        //     uid: user.uid,
        //     name,
        //     authProvider: "local",
        //     email,
        // });
    } catch (err) {
        setError(mapErrorCode(err.code))
    }
};

const sendPasswordReset = async (email, setError) => {
    try {
        await sendPasswordResetEmail(auth, email);
        setError(t`E-Mail für die Zurücksetzung wurde gesendet.`)
    } catch (err) {
        setError(mapErrorCode(err.code))
    }
};

const logout = () => {
    signOut(auth);
};

const mapErrorCode = (code) => errorMessages[code]

/// Cloud Functions 
async function onRegister() {
    // Create new team and link it to current user
    let onAccountCreate = httpsCallable(functions, 'onAccountCreate');
    await onAccountCreate()
}

const changePassword = async (oldPassword, newPassword, setErrors) => {
    try {
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            oldPassword
        );
        const answer = await reauthenticateWithCredential(auth.currentUser, credential);
        console.log(answer)

        await updatePassword(auth.currentUser, newPassword);
        console.log("password updated")
        setErrors({})
    } catch (e) {
        let error = {}
        error["oldPassword"] = errorMessages[e.code]
        return error
    }
}

const retrieveProfile = (profileIds = []) => {
    return new Promise((resolve, reject) => {
        const callable = httpsCallable(functions, 'invites-create_code');
            //callable with region for data
        callable({ids: profileIds})
            .then(result => {
                resolve(result.data);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    })
}

export {
    auth,
    db,
    functions,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    storage,
    onRegister,
    changePassword,
    retrieveProfile
};