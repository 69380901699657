import React, {useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import NewTemplate from "./NewTemplate";
import CardTemplateSkeleton from "../CardTemplate/CardTemplateSkeleton";
import {t} from '@lingui/macro'
import TemplateSelect from "./TemplateSelect";
import Company_3d from "../../../img/product-image-company-front-2.png"
import Phone_3d from "../../../img/webview-mockup-2.png"

const TemplateContainer = ({createTemplate, cardLoaded, profileLoaded}) => {
    const headers = useMemo(() => [
        {
            title: t`Vorlagen`,
            desc: t`Wählen Sie aus zwischen Karten- und Profilvorlage um diese anzupassen oder neue zu erstellen`,
            path: "/templates"
        },
        {
            title: t`Kartenvorlagen`,
            desc: t`Erstelle eine Kartenvorlage um für deine Mitarbeitenden bumpee Cards zu bestellen.`,
            path: "/templates/card",
            src: Company_3d
        },
        {
            title: t`Profilvorlagen`,
            desc: t`Erstelle eine Profilvorlage um eine einheitliche Erstellung von Online-Profilen für deine Mitarbeitenden zu bekommen.`,
            path: "/templates/profile",
            src: Phone_3d
        }
    ], [])

    const location = useLocation()
    const navigate = useNavigate()

    const [header, setHeader] = useState()

    const newTemplate = (name, isCard) => {
        createTemplate(name, isCard).then(ref => navigate("/templates/" + (isCard ? "card" : "profile") + "/" + ref.id))
    }

    useEffect(() => {
        setHeader(headers.find(head => head.path === location.pathname))
    }, [headers, location])

    return (
        <>
            <header className="header">
                <div>
                    <h1>{header?.title}</h1>
                    <p style={{fontSize: "1rem"}}>{header?.desc}</p>
                </div>
            </header>
            {
                cardLoaded && profileLoaded ?
                    <div className="template_previews" style={{flexWrap: "nowrap"}}>
                        {
                            header?.path === "/templates" ?
                                <>
                                    {
                                        headers.filter(head => head.path !== "/templates")
                                            .map((head, index) => <TemplateSelect title={head.title} description={head.desc} src={head.src} path={head.path} key={index}/>)
                                    }
                                </>
                                :
                                <>
                                    <Outlet/>
                                    <NewTemplate createTemplate={newTemplate}/>
                                </>

                        }
                    </div>
                    :
                    //todo: In die Vorlagen einbetten
                    <CardTemplateSkeleton/>
            }
        </>
    );
};

export default TemplateContainer;