import React, {useEffect, useRef} from 'react';
import SettingWidget from "./SettingWidget";
import Popup from "reactjs-popup";
import AreYouSure from "../Widgets/AreYouSure";
import ChangePassword from "./ChangePassword";
import {t, Trans} from "@lingui/macro";
import PopupLayout from "../Widgets/PopupLayout";
import ChangeCompanyName from "./ChangeCompanyName";
import {useLocation, useNavigate} from "react-router-dom";
import {Briefcase, Lock, LogOut, Package, Smartphone, User} from "react-feather";
import ManagePlan from "./SubscriptionPlans/ManagePlan";

const SettingsContainer = ({mail, logout, waiting, updateName, currentName, plans, activePlanId}) => {
    const logoutRef = useRef()
    const changePasswordRef = useRef()
    const changeNameRef = useRef()
    const managePlanRef = useRef()

    const navigate = useNavigate()

    let query = useQuery();

    useEffect(() => {
        if (query.get("plan") && !waiting) {
            managePlanRef.current.open()
            navigate("/settings")
        }
    }, [navigate, query, waiting])


    const widgets = [
        {
            text: t`Angemeldet mit:` + " " + mail,
            icon: <User/>,
            action: null
        },
        {
            text: t`Plan verwalten`,
            icon: <Package/>,
            action: () => managePlanRef.current.open(),
            id: "subscription-plans"
        },
        {
            text: <Trans>Passwort ändern</Trans>,
            icon: <Lock/>,
            action: () => changePasswordRef.current.open()
        },
        {
            text: <Trans>Firmennamen ändern</Trans>,
            icon: <Briefcase/>,
            action: () => changeNameRef.current.open()
        },
        {
            text: <Trans>App-Einladungen</Trans>,
            icon: <Smartphone/>,
            action: () => navigate("invites"),
            id: "app-inivitation"
        },
        {
            text: <Trans>Abmelden</Trans>,
            icon: <LogOut/>,
            action: () => logoutRef.current.open()
        }
    ]

    const closePlanPopup = () => {
        managePlanRef.current.close()
    }

    return (
        <>
            <header className="header">
                <div>
                    <h1><Trans>Einstellungen</Trans></h1>
                    <p style={{fontSize: "1rem"}}><Trans>Passen Sie Ihre Kontoinformationen an.</Trans></p>
                </div>
            </header>
            <div className="setting-widgets">
                {
                    widgets.map((widget, index) => <SettingWidget text={widget.text} icon={widget.icon}
                                                                  action={widget.action} id={widget.id} key={index}/>)
                }
                <Popup
                    ref={logoutRef}
                    modal
                    contentStyle={{width: "inherit"}}
                >
                    <AreYouSure closePopup={() => logoutRef.current.close()}
                                description={t`Wollen Sie sich wirklich abmelden?`}
                                sure={logout}
                                action={t`Abmelden`}/>
                </Popup>
                <Popup
                    ref={changePasswordRef}
                    modal
                    contentStyle={{width: "inherit"}}
                >
                    <PopupLayout close={() => changePasswordRef.current.close()} title={t`Passwort ändern`}
                                 description={t`Geben Sie zunächst Ihr aktuelles Passwort ein und ändern Sie es daraufhin zu einem neuen.`}
                                 content={<ChangePassword mail={mail}
                                                          close={() => changePasswordRef.current.close()}/>}/>
                </Popup>
                <Popup
                    ref={changeNameRef}
                    modal
                    contentStyle={{width: "inherit"}}
                >
                    <PopupLayout close={() => changeNameRef.current.close()} title={t`Firmennamen ändern`}
                                 description={""}
                                 content={<ChangeCompanyName close={() => changeNameRef.current.close()}
                                                             waiting={waiting} updateCompanyName={updateName}
                                                             serverName={currentName}
                                 />}/>
                </Popup>
                <Popup
                    ref={managePlanRef}
                    modal
                    contentStyle={{width: "inherit"}}
                >
                    <PopupLayout close={closePlanPopup} title={t`Plan verwalten`}
                                    description={<Trans>Ändern Sie Ihren aktuellen bumpee Plan oder verwalten Sie Ihren aktuellen Zahlungsverkehr.</Trans>}
                                    content={<ManagePlan close={closePlanPopup} plans={plans} activePlanId={activePlanId}/>}
                    />
                </Popup>
            </div>
        </>
    );
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export default SettingsContainer;