import React, {useMemo} from 'react';
import {Trans} from "@lingui/macro";
import CardTemplateView from "../Templates/CardTemplate/CardTemplateView";
import {useParams} from "react-router-dom";

const WelcomeCard = ({settings, setWaiting, saveCard, waiting, shopify_client}) => {
    const {templateId} = useParams()

    const templateName = useMemo(() => {
        return settings.find(template => template.id === templateId)?.name
    }, [settings, templateId])

    return (
        <div className="welcome-first-slide">
            <h1><Trans>Gestalten Sie Ihre Karte</Trans></h1>
            <CardTemplateView settings={settings} setWaiting={setWaiting} saveCard={saveCard} templateId={templateId}
                              templateName={templateName} waiting={waiting} shopify_client={shopify_client}
            />
        </div>
    );
};

export default WelcomeCard;