import {Trans} from "@lingui/macro";
import {useMemo} from "react";

const PricingContainer = ({workers, templates}) => {
    const price = useMemo(() => {
        if (workers.length === 0 || templates.length === 0) return ({"total": 0, "amount": 0})
        const amount = workers.length
        let discount = 1;
        if (amount > 100) discount = 0.85
        else if (amount > 50) discount = 0.9
        else if (amount >= 10) discount = 0.95

        //Find the pricing of each workers template and summarize it
        let total = 0
        workers.forEach(worker => {
            const template = templates.find(template => template.id === worker.cardTemplate)
            if (template) total += parseInt(template.pricing ?? 29)
        })

        if (discount === 1) return {"total": total, "amount": amount}
        return {"total": total, "amount": amount, "reduced": total * discount}
    }, [workers, templates])

    return (
        <div className="preis">
            <p className="anzahl">
                <span className="schild"><Trans>Anzahl: </Trans></span>
                <span> {price.amount}</span>
            </p>
            <div className="preisAngaben">
                <p>
                    <span className="schild"><Trans>Preis: </Trans></span>
                    {!price.reduced ?
                        <span> {price.total}€</span>
                        :
                        <>
                            <span className="discount"> {price.reduced}€</span>
                            {" "}
                            <span className="alterPreis">{price.total}€</span>
                        </>
                    }
                </p>
                <p className="preisDetails"></p>
                <p className="preisDetails"><Trans>zzgl. Mwst. - Unverbindliche Preisempfehlung</Trans></p>
            </div>
        </div>
    );
};

export default PricingContainer;
