import React from 'react';
import {Trans} from "@lingui/macro";
import "./termsofservice.css"

const TermsOfService = () => {
    return (
        <>
            <header className="header">
                <div>
                    <h1><Trans>Saas: AGB bumpee</Trans></h1>
                </div>
            </header>
            <div className="termsofservice">
                <h2><Trans>1. Geltungsbereich</Trans></h2>
                <p><Trans>
                    <br/> 1.1<br/>Die Mondiba Solutions UG (haftungsbeschränkt) (im Folgenden: bumpee) bietet auf der
                    Internetseite „b2b.bumpee.de“ (im Folgenden: bumpee) eine web-basierte CRM-Lösung zur Verwaltung,
                    Bearbeitung und Analyse von Mitarbeiter Visitenkarten (im Folgenden: Software) für kleine und
                    mittelgroße
                    Unternehmen, insbesondere zur Verwaltung des digitalen Auftritts von Mitarbeitern, an.
                    <br/> 1.2<br/>Die folgenden allgemeinen Geschäftsbedingungen (im Folgenden: AGB) gelten für alle
                    Nutzungsverträge (im Folgenden „Verträge“ genannt), die zwischen bumpee und Kunden, die keine
                    Verbraucher im
                    Sinne des § 13 BGB oder anderer entsprechender einschlägiger gesetzlicher Bestimmungen sind, über die
                    Plattform „bumpee“ geschlossen werden.
                    <br/> 1.3<br/>Die folgenden AGB gelten nicht für Kaufverträge von Hardwareprodukten, die auf bumpee
                    beworben
                    aber auf „bumpee.de“ (im Folgenden: bumpee-Webshop) erworben werden. Hierfür gelten die im
                    bumpee-Webshop
                    hinterlegten AGB.
                    <br/> 1.4<br/>Abweichende AGB des Kunden finden auf Verträge keine Anwendung, es sei denn, bumpee stimmt
                    ihrer Anwendung ausdrücklich in Schriftform zu.
                </Trans></p>
                <h2><Trans>2. Vertragsschluss zwischen dem Kunden und bumpee, BETA</Trans></h2>
                <p><Trans>
                    <br/> 2.1<br/>Das auf bumpee bereitgestellte Angebot, die dort bezeichnete und beschriebene web-basierte
                    Software zu nutzen, stellt kein verbindliches Angebot von bumpee dar.
                    <br/> 2.2<br/>Die Nutzung der Software von bumpee setzt die Erstellung eines Kunden-Accounts (im
                    Folgenden:
                    Account) voraus. Für die Erstellung des Accounts sind die erforderlichen Daten anzugeben und ein
                    Passwort
                    festzulegen. Durch Bestätigung der Anlegung des Accounts gibt der Kunde zunächst ein verbindliches
                    Angebot
                    zum Abschluss eines Vertrags über die kostenfreie Nutzung der Software für Testzwecke ab. Dieses Angebot
                    kann bumpee mit der Einrichtung und Gewährung des Zugangs zum Account oder dem Versand einer Mitteilung
                    an
                    die angegebene E-Mail-Adresse mit den Zugangsdaten für den eingerichteten Account annehmen.
                    <br/> 2.3<br/>Durch den Abschluss eines Vertrags zur kostenfreien Nutzung gemäß Ziffer 2.2 räumt bumpee
                    dem
                    Kunden das Recht ein, die Software ab Gewährung des Zugangs zur Software bzw. Mitteilung der
                    Zugangsdaten
                    durch bumpee für den Zeitraum der offenen Beta zu nutzen (Betazeitraum). 2 Wochen vor Ablauf des
                    Betazeitraums wird der Kunde, über die von ihm bei Anmeldung angegebenen Kontaktdaten, über diesen
                    informiert. Nach Ablauf des Betazeitraums wird der Account des Kunden gesperrt. Eine automatische
                    Umstellung
                    in einen Vertrag über die kostenpflichtige Nutzung der Software erfolgt nicht.
                    <br/> 2.4<br/>Nach Ablauf des Betazeitraums gemäß Ziffer 2.3 hat der Kunde die Möglichkeit, einen
                    kostenpflichtigen Vertrag über die Nutzung der Software mit bumpee abzuschließen. Dabei kann der Kunde
                    zwischen den angebotenen Software-Versionen für eine festgelegte, maximale Anzahl an Mitarbeitern,
                    wählen.
                    Die nötigen Funktionalitäten um dies zu tun, werden mit Ablauf des Betazeitraums zur Verfügung gestellt.
                    <br/> 2.5<br/>Für die Angebotserstellung und Abschluss eines Vertrags mit monatlicher Abrechnung erfolgt
                    der
                    Vertragsabschluss im Account selbst. Dazu muss der Kunde die entsprechende Version auswählen und seine
                    Auswahl bestätigen. Anschließend sind neben dem Firmennamen und Rechnungsadresse auch Kreditkartendaten
                    zu
                    hinterlegen. Durch Bestätigung und Absendung dieser Angaben schließt der Kunde einen Vertrag über die
                    kostenpflichtige Nutzung der Software mit monatlicher Abrechnung mit bumpee ab.
                    <br/> 2.6<br/>Für den Abschluss eines Vertrags über die kostenpflichtige Nutzung der Software mit
                    jährlicher
                    Abrechnung erstellt das Sales Team von bumpee auf Anfrage ein entsprechendes Angebot in Schrift- oder
                    Textform, welches vom Kunden durch Bestätigung in Textform, Schriftform oder mündlich, spätestens aber
                    durch
                    Zahlung der Rechnung, angenommen wird.
                </Trans></p>
                <h2><Trans>3. Leistungen, Wechsel der Versionen</Trans></h2>
                <p><Trans>
                    <br/> 3.1<br/>bumpee stellt dem Kunden für die Laufzeit eines Vertrags den Zugang zu der auf bumpee
                    angebotenen und vom Kunden ausgewählten Version der Software als Software-as-a-Service (im Folgenden:
                    SaaS)
                    über das Internet zur Verfügung. Der Funktionsumfang der gebuchten Software-Version umfasst im
                    Betazeitraum
                    unteranderem: (i) das Erstellen, Bearbeiten und Verwalten von Mitarbeiterprofilen; (ii) das Erstellen
                    und
                    Kaufen von physischen digitalen Visitenkarten (im Folgenden: Smart Business Cards); Weitere Leistungen
                    (z.B.
                    die (Remote-) Unterstützung bei der initialen Erstellung eines Accounts, fachliche Einrichtung von
                    Schnittstellen) sind nicht Gegenstand eines Vertrags über die (kostenpflichtige) Nutzung der Software.
                    Solche weiteren Leistungen können von bumpee auf Basis eines gesonderten Angebots erbracht werden.
                    <br/> 3.2<br/>Zusätzliche Funktionalitäten, deren Nutzungsumfang nicht beschränkt ist, dürfen nur in
                    angemessenem Umfang genutzt werden („Fair Use“), insbesondere um die Funktionsfähigkeit und
                    Wirtschaftlichkeit der Bereitstellung für alle Kunden zu gewährleisten. Im Zweifel liegt eine
                    unangemessene
                    Nutzung vor, wenn diese – je maximaler Anzahl der Mitarbeiter, die durch eine Version verwaltet werden
                    können – die monatliche Nutzung der zusätzlichen Funktionalität um das Dreifache überschreitet.
                    Unangemessen
                    ist auch eine wesensfremde Nutzung, d.h. wenn die zusätzliche Funktionalität nicht in Bezug auf die
                    Funktionalität der Software genutzt wird. bumpee behält sich vor, bei Verstoß gegen den Fair Use
                    Grundsatz
                    die Verwendung der zusätzlichen Funktionalität durch den Kunden einzuschränken, nachdem bumpee den
                    Kunden
                    hierauf hingewiesen hat. Sind für zusätzliche Funktionalitäten Kontingente vorgesehen, so bleiben diese
                    auch
                    bei einem Wechsel der Software-Version bestehen, unverbrauchte Kontingente können nicht auf den nächsten
                    Abrechnungszeitraum übertragen werden.
                    <br/> 3.3<br/>Der Kunde kann grundsätzlich jederzeit mit Wirkung ab dem Zeitpunkt der Umstellung des
                    Kundenzugangs durch bumpee zwischen den angebotenen Versionen der Software sowie der maximalen Anzahl
                    der
                    Mitarbeiter, die durch eine Version verwaltet werden können, wechseln. Die daraus resultierende
                    Vergütung
                    ergibt sich aus den Ziffern 7.7 und 7.8 dieser Allgemeinen Geschäftsbedingungen.
                    <br/> 3.5<br/>Kunden mit einem Vertrag zur kostenpflichtigen Nutzung der Software mit monatlicher
                    Abrechnung
                    können diese Änderung der Version direkt im Account vornehmen. Kunden mit einem kostenpflichtigen
                    Vertrag
                    zur Nutzung der Software mit jährlicher Abrechnung müssen für die Änderung der Version den Kundensupport
                    von
                    bumpee (info@bumpee.de) kontaktieren. Wechselt der Kunde während eines laufenden Abrechnungszeitraums in
                    eine Version mit größerem Umfang an Features oder in eine Version mit einer größeren Anzahl an zu
                    verwaltenden Mitarbeitern, kann der Kunde ab dem Zeitpunkt der Umstellung des Accounts durch bumpee die
                    zusätzlichen Funktionen der Software nutzen oder eine größere Anzahl Mitarbeiter verwalten. Wechselt der
                    Kunde in eine Version mit niedrigerem Umfang an Features oder mit geringerer Anzahl an zu verwaltenden
                    Mitarbeitern, kann der Kunde ab dem Zeitpunkt der Umstellung des Kundenzugangs durch bumpee nur noch die
                    reduzierten Funktionen der Software oder einer niedrigeren Anzahl Mitarbeiter in Anspruch nehmen.
                </Trans></p>
                <h2><Trans>4. Verfügbarkeit und Reaktionszeit bei Störungen</Trans></h2>
                <p><Trans>
                    <br/> 4.1<br/>bumpee gewährleistet eine 99%-ige Verfügbarkeit der als SaaS zur Verfügung gestellten
                    Software
                    im Jahresmittel. Davon ausgenommen sind Zeiten, in denen der Server aufgrund von technischen sonstigen
                    Problemen, die nicht im Einflussbereich von bumpee liegen (insbesondere höhere Gewalt, Verschulden
                    Dritter)
                    nicht zu erreichen ist. Ebenfalls ausgenommen sind geplante Wartungsarbeiten (bspw. Updates der
                    Software),
                    die entweder außerhalb der üblichen Geschäftszeiten von Montag bis Freitag (unter Berücksichtigung der
                    Feiertage am Standort München) zwischen 9:00 und 18:00 Uhr liegen, oder die gemäß Ziffer 4.2 vorab
                    angekündigt wurden.
                    <br/> 4.2<br/>bumpee ist berechtigt, zu Wartungszwecken und infolge anderer technischer Erfordernisse
                    die
                    Verfügbarkeit der Software zu unterbrechen. Die Wartungsarbeiten werden soweit möglich außerhalb der
                    üblichen Geschäftszeiten von Montag bis Freitag (unter Berücksichtigung der Feiertage am Standort
                    München)
                    zwischen 9:00 und 18:00 Uhr getätigt. Falls eine Wartungsmaßnahme zu einer Unterbrechung der Nutzung der
                    Software von mehr als 30 Minuten innerhalb der üblichen Geschäftszeiten von Montag bis Freitag (unter
                    Berücksichtigung der Feiertage am Standort München) zwischen 9:00 und 18:00 Uhr führen wird, wird bumpee
                    diese Wartungsarbeit per E-Mail ankündigen. Die Ankündigung erfolgt mindestens 24 Stunden vorab. Auf
                    Kundenwunsch hin kann die angekündigte Wartungsarbeit verschoben werden, sofern dies aus technischen und
                    wirtschaftlichen Gründen aus Sicht von bumpee zu vertreten ist.
                    <br/> 4.3<br/>Störungen der Systemverfügbarkeit müssen vom Kunden unverzüglich nach Bekanntwerden
                    gemeldet
                    werden. bumpee wird sich bemühen bei Meldungen von Störungen der Systemverfügbarkeit, die zu einem
                    Totalausfall der Software führen und die innerhalb der Supportzeiten (Montag bis Donnerstag zwischen
                    9:00
                    und 18:00 Uhr und Freitag zwischen 9:00 und 17:00 unter Berücksichtigung der Feiertage am Standort
                    München)
                    eingehen, eine Reaktionszeit für den Beginn der Entstörung von vier Stunden sicherzustellen. Bei
                    leichteren
                    Fehlern, die nicht zu einem Totalausfall der Software führen und während des laufenden Betriebs
                    auftreten,
                    wird bumpee sich bemühen, nicht später als einen Arbeitstag nach dem Eingang der Störmeldung zu
                    reagieren.
                    <br/> 4.4<br/>Bei Störungsmeldungen, die außerhalb der Supportzeiten eingehen, beginnt die Entstörung am
                    folgenden Arbeitstag. Verzögerungen der Entstörung, die vom Kunden zu vertreten sind (z.B. durch
                    Nichtverfügbarkeit eines Ansprechpartners auf Kundenseite oder verspätete Meldung der Störung), werden
                    nicht
                    auf die Entstörungszeit angerechnet.
                </Trans></p>
                <h2><Trans>5. Mitwirkungsleistungen des Kunden</Trans></h2>
                <p><Trans>
                    <br/> 5.1<br/>Die nachfolgenden Mitwirkungsleistungen sind Hauptleistungspflichten des Kunden und nicht
                    alleine als Nebenpflichten oder Obliegenheiten zu klassifizieren.
                    <br/> 5.2<br/>Der Kunde ist dazu verpflichtet, während des Betazeitraums gemäß der Ziffern 2.2 und 2.3
                    die
                    Funktionalitäten und generelle Beschaffenheit der Software zu überprüfen und etwaige Mängel oder
                    sonstige
                    Abweichungen von den Anforderungen an die Beschaffenheit vor Abschluss eines Vertrags über die
                    kostenpflichtige Nutzung der Software gegenüber bumpee anzuzeigen. Auf Mängel oder sonstige Abweichungen
                    von
                    den Anforderungen an die Beschaffenheit, die während des Betazeitraums bereits bekannt oder vorhanden
                    waren,
                    aber nicht vor Abschluss eines Vertrags über die kostenpflichtige Nutzung der Software gegenüber bumpee
                    angezeigt wurden, kann sich der Kunde gegenüber bumpee nicht berufen.
                    <br/> 5.3<br/>Der Kunde ist verpflichtet, einen qualifizierten Ansprechpartner nebst Stellvertreter zur
                    Verfügung zu stellen, der berechtigt ist, alle notwendigen Entscheidungen zu treffen oder unverzüglich
                    herbeizuführen, die zur Erbringung der vertraglich vereinbarten Leistung erforderlich sind. Der Kunde
                    ist
                    verpflichtet, Änderungen des Ansprechpartners (nebst Stellvertreter) unverzüglich mitzuteilen.
                    <br/> 5.4<br/>Für die Inhalte und mit der Software verarbeiteten Daten ist ausschließlich der Kunde
                    verantwortlich. Der Kunde verpflichtet sich hiermit, die Software von bumpee nur vertragsgemäß und im
                    Rahmen
                    der anwendbaren gesetzlichen Bestimmungen zu nutzen und bei der Nutzung keine Rechte Dritter zu
                    verletzen.
                    Der Kunde wird bumpee unverzüglich, möglichst schriftlich, informieren über: (i) den Missbrauch oder den
                    Verdacht des Missbrauchs der vertraglich vereinbarten Leistung; (ii) eine Gefahr oder den Verdacht einer
                    Gefahr für die Einhaltung des Datenschutzes oder der Datensicherheit, die im Rahmen der Erbringung der
                    vertraglich vereinbarten Leistung auftritt; (iii) eine Gefahr oder den Verdacht einer Gefahr für die von
                    bumpee bereitgestellte Leistung, z.B. durch Verlust von Zugangsdaten oder Hacker-Angriff.
                    <br/> 5.5<br/>Der Kunde ist verpflichtet die technischen Voraussetzungen selbst sicherzustellen.
                    <br/>5.5.1<br/>Die Anbindung an das Internet in ausreichend Bandbreite und Latenz liegt im
                    Verantwortungsbereich des Kunden.
                    <br/>5.5.2<br/>Für eine optimale Nutzung der Angebote und Funktionen von bumpee wird der Kunde die
                    Browsertypen Google Chrome oder Mozilla Firefox in ihrer jeweils aktuellen Version anwenden. Zudem
                    müssen in
                    den Einstellungen im verwendeten Browser die Verwendung von Cookies erlaubt sein. Werden diese
                    technischen
                    Voraussetzungen vom Kunden nicht erfüllt, kann es unter Umständen zu Einschränkungen der Nutzbarkeit der
                    Dienste von bumpee kommen. bumpee ist für diese Einschränkungen nicht verantwortlich.
                    <br/>5.5.3<br/>Der Kunde ist dafür verantwortlich, innerhalb der eigenen Organisation und für seine
                    Mitarbeiter dem aktuellen Stand der Technik entsprechende IT Sicherheitsmaßnahmen zu treffen. Dazu
                    gehören
                    unter anderem, aber nicht begrenzt auf, die Installation und regelmäßige Aktualisierung einer gängigen
                    Antivirus Software auf den Laptops, Computern oder sonstigen mobilen Endgeräten der Mitarbeiter des
                    Kunden,
                    die Sicherstellung der Vergabe und regelmäßigen Aktualisierung von sicheren Passwörtern nach Maßgabe des
                    BSI
                    IT Grundschutz oder anderer äquivalenter, anerkannter Sicherheitsstandards für den bumpee Account sowie
                    für
                    die Laptops, Computer oder sonstige mobile Endgeräte der Mitarbeiter bzw. Einsatz entsprechender
                    Mechanismen
                    wie 2-Faktor-Authentifizierung, automatische Inaktivitätssperre, Firewall etc.
                    <br/>5.5.4<br/>Der Kunde ist weiterhin verpflichtet, für die Geheimhaltung der seinen Nutzern
                    zugeordneten
                    Identifikations- und Authentifizierungsdaten sowie von Zugangsdaten für Schnittstellen, das heißt auch
                    beispielsweise das organisatorische und ggf. technische Verbot der Weitergabe von Passwörtern, Sorge zu
                    tragen.
                    <br/>5.5.5<br/>Darüber hinaus hat der Kunde für die Sicherheit der verwendeten Internetverbindung Sorge
                    zu
                    tragen, das heißt insbesondere auch die Nutzung von firmeneigenen statt öffentlichen Virtual Private
                    Networks (VPN) sowie Sicherstellung der Nutzung von VPN-Verbindungen in öffentlichen Netzwerken.
                    <br/> 5.6<br/>Der Kunde ist für die fachliche Einrichtung und Administration des Accounts selbst
                    verantwortlich. Dies gilt unabhängig davon, ob bumpee den Kunden bei der Einrichtung des Accounts, in
                    welcher Form auch immer, unterstützt. Hierzu zählen insbesondere: (i) die fachliche Einrichtung des
                    Accounts, insbesondere Migration von Daten, Konfiguration von Prozessen und Produkten; (ii) die
                    Administration des Accounts, insbesondere das Anlegen von Benutzern und Rollen und Zuweisen von Zugängen
                    zum
                    Account.
                    <br/> 5.7<br/>Der Kunde ist verpflichtet, bumpee über auftretende Leistungsstörungen (Mängel der
                    Leistungen,
                    fehlende Verfügbarkeit) unverzüglich in Textform zu informieren und nachvollziehbare Informationen zu
                    auftretenden Leistungsstörungen zu übermitteln. Der Kunde wird bumpee bei auftretenden
                    Leistungsstörungen in
                    angemessenem Umfang bei der Fehleridentifizierung und -behebung unterstützen. bumpee ist berechtigt, dem
                    Kunden vorübergehend Fehlerumgehungsmöglichkeiten aufzuzeigen und die eigentliche Ursache später durch
                    Anpassung an der bumpee Software zu beseitigen, sofern dies dem Kunden zumutbar ist.

                </Trans></p>
                <h2><Trans>6. Rechteeinräumung</Trans></h2>
                <p><Trans>
                    <br/> 6.1<br/>bumpee räumt dem Kunden ein nicht-ausschließliches, einfaches, nicht übertragbares und
                    zeitlich auf die Laufzeit des Vertrags begrenztes Recht zur Nutzung der gebuchten Software ein.
                </Trans></p>
                <h2><Trans>7. Preise, Zahlungsmethoden und Zahlungsbedingungen</Trans></h2>
                <p><Trans>
                    <br/> 7.1<br/>Es gelten die im Zeitpunkt der Bestellung angegebenen Preise, wie sie auf der
                    Internetseite
                    von bumpee dargestellt werden. Solange keine Preise auf der Internetseite dargestellt werden, gilt
                    weiterhin
                    der kostenlose Betazeitraum. Dargestellte Preise sind monatliche Nettopreise in Euro und verstehen sich
                    zuzüglich der gesetzlichen Umsatzsteuer in der jeweils gültigen gesetzlichen Höhe, sofern anwendbar. Die
                    Höhe der monatlichen Vergütung für die Nutzung der Software kann sich nach den folgenden Faktoren
                    („Bestellparameter“) richten und wird beim Bestellprozess entsprechend angegeben:
                    <br/>7.1.1<br/>der gewählten Paket-Größe, das heißt die maximal zu verwaltende Anzahl der Mitarbeiter
                    des
                    Kunden,
                    <br/>7.1.2<br/>der gewählten Version der Software (Essential, Professional, Enterprise), auch als „Plan“
                    oder „Paket“ bezeichnet,
                    <br/> 7.2<br/>Der Kunde hat die Wahl zwischen monatlicher und jährlicher Abrechnung. Alle Zahlungen sind
                    jeweils mit Rechnungsstellung im Voraus zur Zahlung fällig.
                    <br/> 7.3<br/>Bei monatlicher Abrechnung beginnt der Abrechnungszeitraum mit dem Tag des Abschlusses
                    eines
                    Vertrags zur kostenpflichtigen Nutzung der Software über den Account und endet nach Ablauf eines
                    Mon<br/>ats.<br/>Zahlungen
                    für Verträge über die kostenpflichtige Nutzung der Software mit monatlicher Abrechnung erfolgen per
                    Kreditkarte monatlich im Voraus. Die Kreditkarte wird mit dem Tag der Fälligkeit belas<br/>tet.<br/>Bei
                    Zahlung mit Kreditkarte behält sich bumpee das Recht vor, die Gültigkeit der Karte zu überprüfen, den
                    Verfügungsrahmen für die Debitierung, sowie die Adressangaben zu kontrollieren. bumpee ist bei Vorliegen
                    eines wichtigen Grundes berechtigt, die eingegebene Kreditkarte als Zahlungsmittel abzuleh<br/>nen.<br/>Bei
                    monatlicher Abrechnung wird dem Kunden eine Rechnung von bumpee in elektronischer Form in seinem Profil
                    zum
                    Abruf zur Verfügung gestellt sowie per E-Mail versandt.
                    <br/> 7.4<br/>Bei jährlicher Abrechnung beginnt der Abrechnungszeitraum mit dem Tag der Freischaltung
                    des
                    Accounts und endet nach Ablauf eines Jah<br/>res.<br/>Der Rechnungsbetrag ergibt sich hierbei aus der
                    12-fachen monatlichen Vergütung für die bestellte Software (Ziffer 7.1), abzüglich des auf der
                    Internetseite
                    von bumpee vermerkten Rabatts bei jährlicher Vorauszahlung. bumpee schaltet den Kundenzugang dem mit dem
                    Kunden vereinbarten und auf der Rechnung angegebenen Leistungszeitraum entsprechend zunächst für ein
                    Jahr
                    f<br/>rei.<br/>Zahlungen für Verträge über die kostenpflichtige Nutzung der Software mit jährlicher
                    Abrechnung erfolgen in der Regel per Überweisung jährlich im Vor<br/>aus.<br/>Bei jährlicher Abrechnung
                    wird
                    dem Kunden eine Rechnung über 12 Monate in elektronischer Form per E-Mail zugesandt. Das Zahlungsziel
                    der
                    Überweisung ist zwei Wochen ab Rechnungsdatum.
                    <br/> 7.5<br/>Zusätzlich steht dem Kunden bei monatlicher und jährlicher Abrechnung die Zahlung per
                    Bankeinzug zur Verfügung. Soweit eine Zahlungsweise per Bankeinzug gewählt wird, wenden wir das
                    SEPA-Lastschriftverfahren an. bumpee wird den Kunden vor der Durchführung einer Lastschrift im
                    SEPA-Lastschriftverfahren mit angemessenem zeitlichem Vorlauf darüber informieren, in der Regel zwei
                    Tage
                    vorher. Die Parteien sind sich jedoch einig, dass die Frist für die Vorabinformation der
                    SEPA-Lastschrift
                    auf einen Tag verkürzt wird.
                    <br/> 7.6<br/>Im Fall einer Rücklastschrift (insbesondere mangels erforderlicher Deckung des Kontos,
                    wegen
                    Erlöschen des Kontos, unberechtigten Widerspruchs des Kontoinhabers oder falscher Eingabe der
                    Kontodaten)
                    ermächtigt der Kunde bumpee, die Lastschrift für die jeweils fällige Zahlungsverpflichtung ein weiteres
                    Mal
                    einzureichen. In einem solchen Fall ist der Kunde verpflichtet, die durch die Rücklastschrift
                    entstehenden
                    Kosten zu zahlen. Weitergehende Forderungen sind vorbehalten.
                    <br/> 7.7<br/>Sollte sich bei der monatlichen Abrechnung der Preis wegen Änderung der Bestellparameter
                    (Ziffer 7.1) erhöhen, stellt bumpee den Differenzbetrag zwischen der bereits geleisteten Vorauszahlung
                    und
                    dem geänderten Preis bis zum Ende des Abrechnungsmonats entweder sofort oder mit der folgenden Rechnung
                    für
                    den nächsten Abrechnungsmonat in Rechnung. Sollte sich bei monatlicher Abrechnung der Preis während des
                    Abrechnungszeitraumes wegen Änderung der Bestellparameter (Ziffer 7.1) verringern, so hat der Kunde
                    keinen
                    Anspruch auf (anteilige) Rückerstattung seiner bereits geleisteten Vorauszahlung.
                    <br/> 7.8<br/>Sollte sich bei jährlicher Abrechnung der Preis wegen Änderung der Bestellparameter
                    (Ziffer
                    7.1) erhöhen, stellt bumpee den Differenzbetrag zwischen der bereits geleisteten Vorauszahlung bzw. dem
                    bereits in Rechnung gestellten Betrag und dem Betrag auf Basis des geänderten Preises bis zum Ende der
                    jährlichen Laufzeit zusätzlich in Rechnung (tagesgenaue Abrechnung). Sollte sich bei jährlicher
                    Abrechnung
                    der Preis wegen Änderung der Bestellparameter (Ziffer 7.1) verringern, so hat der Kunde keinen Anspruch
                    auf
                    (anteilige) Rückerstattung seiner bereits geleisteten Vorauszahlung.
                    <br/> 7.9<br/>Im Verzugsfall des Kunden, sofern auch nach Ablauf einer dem Kunden gesetzten Frist von
                    einer
                    Kalenderwoche nach Fälligkeit keine Zahlung geleistet wurde, ist bumpee berechtigt, den Zugang des
                    Kunden
                    zur Software unverzüglich zu sperren. Auf diese Sperrung wird bumpee den Kunden im Vorfeld unter
                    weiterer
                    Fristsetzung von einer Kalenderwoche hinweisen. Der Kunde bleibt in diesem Fall verpflichtet, die
                    vereinbarte Vergütung zuzüglicher etwaiger Verzugszinsen weiter zu bezahlen. Etwaige durch die Sperrung
                    aus
                    diesem Grund verursachte Schäden beim Kunden können nicht gegenüber bumpee geltend gemacht werden.
                    Darüber
                    hinaus hat bumpee kein Recht den Zugang zu der Software zu sperren. Des Weiteren gelten im Verzugsfall
                    die
                    gesetzlichen Bestimmungen der §§ 286, 288 BGB oder anderer entsprechender einschlägiger gesetzlicher
                    Bestimmungen.
                </Trans></p>
                <h2><Trans>8. Vertragsbeginn, Mindestlaufzeit und Kündigung</Trans></h2>
                <p><Trans>
                    <br/> 8.1<br/>Sobald der Kunde seine Zugangsdaten erhalten hat, beginnt die kostenlose Betaphase (siehe
                    Ziffer 2.2 und 2.3). Nach Ablauf dieser Betaphase verlängert sich die Laufzeit nicht automatisch. Der
                    Kunde kann nach Ende der Betaphase entscheiden, ob er einen Vertrag über die kostenpflichtige Nutzung
                    der Software abschließen möchte.
                    <br/> 8.2<br/>Bei Verträgen über die kostenpflichtige Nutzung der Software mit monatlicher Abrechnung
                    gilt eine Mindestlaufzeit von einem Monat. Nach Ablauf der Mindestlaufzeit verlängert sich der Vertrag
                    um Verlängerungsperioden von jeweils einem Monat, sofern nicht der Kunde vor Beginn einer
                    Verlängerungsperiode kündigt.
                    <br/> 8.3<br/>Bei Verträgen über die kostenpflichtige Nutzung der Software mit jährlicher Abrechnung
                    gilt eine Mindestlaufzeit von einem Jahr. Nach Ablauf der Mindestlaufzeit verlängert sich der Vertrag um
                    Verlängerungsperioden von jeweils einem Jahr, sofern nicht der Kunde mit einer Frist von drei Monaten
                    vor Beginn einer Verlängerungsperiode kündigt. Für die Verlängerung der Verträge über die
                    kostenpflichtige Nutzung der Software mit jährlicher Abrechnung wird bumpee dem Kunden spätestens zu
                    Beginn der neuen Verlängerungsperiode eine neue Jahresrechnung zur Überweisung zur Verfügung stellen.
                    <br/> 8.4<br/>bumpee hat das Recht, Verträge über die kostenpflichtige Nutzung der Software mit
                    monatlicher Abrechnung mit einer Frist von zwei Wochen und Verträge über die kostenpflichtige Nutzung
                    der Software mit jährlicher Abrechnung mit einer Frist von drei Monaten zum Ende des jeweiligen
                    Abrechnungszeitraumes zu kündigen.
                    <br/> 8.5<br/>Das Recht beider Vertragspartner zur Kündigung aus wichtigem Grund bleibt unberührt.
                    <br/> 8.6<br/>Die Kündigung hat in Textform zu erfolgen. Mit Wirksamwerden der Kündigung wird der
                    Account des Kunden gesperrt.
                </Trans></p>
                <h2><Trans>9. Haftungsbeschränkung</Trans></h2>
                <p><Trans>
                    <br/> 9.1<br/>Gesetzliche Haftung bei entgeltlicher Leistungserbringung. Bei entgeltlicher
                    Leistungserbringung haftet bumpee gemäß den gesetzlichen Bestimmungen für Schäden aus der Verletzung des
                    Lebens, des Körpers oder der Gesundheit, sowie für sonstige Schäden, die auf einer vorsätzlichen oder
                    grob fahrlässigen Pflichtverletzung sowie Arglist beruhen. Darüber hinaus haftet bumpee bei Verträgen
                    über die kostenpflichtige Nutzung der Software gemäß den gesetzlichen Bestimmungen für Schäden, die von
                    der Haftung nach zwingenden gesetzlichen Vorschriften umfasst werden, wie beispielsweise im Fall der
                    Übernahme von Garantien, arglistigen Verschweigens eines Mangels oder nach dem Produkthaftungsgesetz.
                    Garantien durch bumpee erfolgen nur in Schriftform und sind im Zweifel nur dann als solche auszulegen,
                    wenn sie als „Garantie” bezeichnet werden.
                    <br/> 9.2<br/>Haftungsbeschränkung bei entgeltlicher Leistungserbringung. Im Falle der leichten
                    Fahrlässigkeit haftet bumpee bei entgeltlicher Leistungserbringung nur für Schäden, welche von bumpee
                    verursacht wurden und auf solche wesentlichen Pflichtverletzungen zurückzuführen sind, die die
                    Erreichung des Zwecks dieses Vertrages gefährden, oder auf die Verletzung von Pflichten, deren Erfüllung
                    die ordnungsgemäße Durchführung des Vertrags erst ermöglicht und auf deren Einhaltung der Kunde
                    vertrauen darf (sog. Kardinalspflichtverletzungen). In diesen Fällen ist die Haftung von bumpee auf den
                    vertragstypisch vorhersehbaren Schaden begrenzt. Die Haftung für die leicht fahrlässige Verletzung von
                    Pflichten, die keine Kardinalspflichten sind (siehe Ziffer 9.2 Satz 1), ist ausgeschlossen, außer bumpee
                    haftet kraft Gesetzes zwingend (siehe Ziffer 9.1 Satz 2).
                    <br/> 9.3<br/>Haftung bei unentgeltlicher Leistungserbringung. Bei unentgeltlicher Leistungserbringung
                    (z.B. innerhalb des Betazeitraums) haftet bumpee nur für Schäden, die auf Vorsatz, grober Fahrlässigkeit
                    sowie Arglist beruhen. Diese Haftungsbeschränkung gilt nicht für Schäden aus der Verletzung des Lebens,
                    des Körpers oder der Gesundheit, hierfür haftet bumpee uneingeschränkt.
                    <br/> 9.4<br/>Ansprüche gegen Dritte. Die Haftungsbeschränkungen der Ziffern 9.1 bis 9.3 gelten auch für
                    Ansprüche gegen leitende Angestellte, Mitarbeiter, sonstige Erfüllungsgehilfen oder Unterauftragnehmer
                    von bumpee.
                </Trans></p>
                <h2><Trans>10. Datenschutz und Vertraulichkeit</Trans></h2>
                <p><Trans>
                    <br/>10.1<br/>bumpee erhebt und verwendet die personenbezogenen Daten des Kunden nur im Rahmen der
                    jeweils geltenden gesetzlichen Bestimmungen. Die Vertragspartner schließen hierzu eine Vereinbarung im
                    nach den jeweils geltenden Bestimmungen erforderlichen Umfang ab.
                    <br/>10.2<br/>Keiner der Vertragspartner ist berechtigt, vertrauliche Informationen des jeweils anderen
                    Vertragspartners ohne ausdrückliche Zustimmung (mindestens in Textform) an Dritte zu übermitteln. Dies
                    gilt sowohl für Kunden mit Verträgen über die kostenfreie als auch über die kostenpflichtige Nutzung.
                    Sämtliche Informationen, gleich ob schriftlich fixiert oder mündlich übermittelt, die (i) der Natur der
                    Sache nach als vertraulich oder geheimhaltungsbedürftig gelten oder (ii) die derjenige Vertragspartner,
                    dem die Informationen übermittelt werden, bereits aufgrund der äußeren Umstände der Übermittlung als
                    vertraulich oder geheimhaltungsbedürftig erkennen muss. Unter vertrauliche Informationen fallen
                    insbesondere Produktbeschreibungen und Spezifikationen sowie Preise. Beide Vertragspartner verpflichten
                    sich, vertrauliche Informationen nur für vertraglich vereinbarte Zwecke zu verwenden. Beide
                    Vertragspartner treffen mindestens diejenigen Vorsichtsmaßnahmen, die sie auch im Hinblick auf eigene
                    vertrauliche Informationen treffen. Solche Vorsichtsmaßnahmen müssen wenigstens angemessen sein, um die
                    Weitergabe an unbefugte Dritte zu verhindern. Beide Vertragspartner sind darüber hinaus verpflichtet,
                    die unbefugte Weitergabe oder Nutzung vertraulicher Informationen durch ihre Kunden, Mitarbeiter,
                    Subunternehmer oder gesetzliche Vertreter zu verhindern. Die Vertragspartner werden sich gegenseitig
                    schriftlich darüber informieren, falls es zu missbräuchlicher Nutzung vertraulicher Informationen kommt.
                    Ausgenommen von vorstehender Verpflichtung sind solche Informationen, die (i) dem anderen
                    Vertragspartner bereits vor Übermittlung und ohne bestehende Geheimhaltungsvereinbarung bekannt waren,
                    (ii) von einem Dritten, der keiner vergleichbaren Vertraulichkeitsvereinbarung unterliegt, übermittelt
                    werden, (iii) anderweitig öffentlich bekannt sind, (iv) unabhängig und ohne Nutzung der vertraulichen
                    Informationen entwickelt wurden, (v) zur Veröffentlichung schriftlich freigegeben sind oder (vi)
                    aufgrund einer gerichtlichen oder behördlichen Verfügung übermittelt werden müssen, vorausgesetzt, dass
                    der von der Übermittlung betroffene Vertragspartner rechtzeitig informiert wird, um noch
                    Rechtsschutzmaßnahmen einleiten zu können. Die Verpflichtung zur Vertraulichkeit gilt auch über die
                    Dauer des Vertrags hinaus bis zwölf Monate nach dem wirksamen Beendigungszeitpunkt des Vertrags.
                </Trans></p>
                <h2><Trans>11. Änderungsvorbehalte</Trans></h2>
                <p><Trans>
                    <br/>11.1<br/>bumpee hat das Recht, diese Allgemeinen Geschäftsbedingungen jederzeit abzuändern oder um
                    Regelungen für die Nutzung etwaig neu eingeführter zusätzlicher Leistungen oder Funktionen der Software
                    zu ergänzen. Die Änderungen und Ergänzungen der Allgemeinen Geschäftsbedingungen werden dem Kunden
                    spätestens vier Wochen vor dem geplanten Inkrafttreten per E-Mail an die von ihm angegebene
                    E-Mail-Adresse angekündigt. Die Zustimmung des Kunden zur Änderung der Allgemeinen Geschäftsbedingungen
                    gilt als erteilt, wenn der Kunde der Änderung nicht innerhalb einer Frist von zwei Wochen, beginnend mit
                    dem Tag, der auf die Änderungsankündigung folgt, in Textform (z.B. Brief, Fax, E-Mail) widerspricht.
                    bumpee verpflichtet sich, in der Änderungsankündigung auf die Möglichkeit des Widerspruchs, die Frist
                    für den Widerspruch, das Textformerfordernis sowie die Bedeutung, bzw. die Folgen des Unterlassens eines
                    Widerspruchs gesondert hinzuweisen.
                    <br/>11.2<br/>bumpee behält sich vor, die Software zu ändern oder abweichende Funktionalitäten
                    anzubieten, außer Änderungen und Abweichungen sind für den Kunden nicht zumutbar. Sofern mit der
                    Bereitstellung einer geänderten Version der Software oder einer Änderung von Funktionalitäten der
                    Software eine wesentliche Änderung der durch die Software unterstützten Arbeitsabläufe des Kunden und/
                    oder Beschränkungen in der Verwendbarkeit bisher erzeugter Daten einhergehen, wird bumpee dies dem
                    Kunden spätestens vier Wochen vor dem Wirksamwerden einer solchen Änderung in Textform ankündigen.
                    Widerspricht der Kunde der Änderung nicht in Textform innerhalb einer Frist von zwei Wochen ab Zugang
                    der Änderungsmitteilung, wird die Änderung Vertragsbestandteil. bumpee wird den Kunden bei jeder
                    Ankündigung von Änderungen auf die vorgenannte Frist und die Rechtsfolgen ihres Verstreichens bei
                    Nichtwahrnehmung der Widerspruchsmöglichkeit aufmerksam machen.
                    <br/>11.3<br/>bumpee behält sich darüber hinaus vor, die Software zu ändern oder abweichende
                    Funktionalitäten anzubieten, (i) soweit dies erforderlich ist, um die Übereinstimmung der von bumpee
                    angebotenen Leistungen mit dem auf diese Leistungen anwendbaren Recht herzustellen, insbesondere, wenn
                    sich die Rechtslage ändert; (ii) soweit bumpee damit einer an bumpee gerichteten Gerichts- oder
                    Behördenentscheidung nachkommt; (iii) soweit dies erforderlich ist, um Sicherheitslücken der Software zu
                    beseitigen; (iv) weil sich die Leistungen oder Vertragskonditionen von Drittanbietern wesentlich ändern,
                    oder (v) soweit dies überwiegend vorteilhaft für den Kunden ist.
                    <br/>11.4<br/>bumpee ist berechtigt, die Preise für die kostenpflichtigen vertraglichen Leistungen zum
                    Ausgleich von Personal- und sonstigen Kostensteigerungen jährlich in angemessener Höhe anzupassen.
                    bumpee wird diese Preisanpassungen und den Zeitpunkt der Wirksamkeit der Preisanpassung dem Kunden in
                    Textform bekanntgeben. Die Preisanpassungen gelten nicht für die Zeiträume, für die der Kunde bereits
                    Zahlungen geleistet hat. Beträgt die Preisanhebung mehr als 5% des bisherigen Preises, so kann der Kunde
                    dieser Preiserhöhung mit einer Frist von zwei Wochen ab Mitteilung widersprechen. Eine aus einer
                    Änderung des Umfangs an Features bzw. Anzahl der zu verwaltenden Mitarbeiter resultierende Änderungen
                    des Preises gilt nicht als Preisanpassung im Sinne dieser Ziffer 11.4.
                    <br/>11.5<br/>Widerspricht der Kunde einer Änderung im Sinne dieser Ziffer 11 form- und fristgerecht,
                    wird das Vertragsverhältnis unter den bisherigen Bedingungen fortgesetzt. bumpee behält sich für diesen
                    Fall vor, das Vertragsverhältnis außerordentlich mit einer Frist von einem Monat zu kündigen.
                    <br/>11.6<br/>Änderungen dieser Allgemeinen Geschäftsbedingungen bedürfen der Textform. Dies gilt auch
                    für die Abbedingung der Textform selbst.
                </Trans></p>
                <h2><Trans>12. Schlussbestimmungen</Trans></h2>
                <p><Trans>
                    <br/>12.1<br/>Sind einzelne Bestimmungen der Allgemeinen Geschäftsbedingungen ganz oder teilweise
                    nicht Vertragsbestandteil geworden oder unwirksam, so bleibt der Vertrag im Übrigen wirksam. Soweit
                    die Bestimmungen nicht Vertragsbestandteil geworden oder unwirksam sind, richtet sich der Inhalt des
                    Vertrages nach den gesetzlichen Vorschriften.
                    <br/>12.2<br/>Das zwischen den Vertragspartnern bestehende Vertragsverhältnis unterliegt
                    ausschließlich dem Recht der Bundesrepublik Deutschland unter ausdrücklichem Ausschluss des
                    UN-Kaufrechts. Ausschließlicher Gerichtsstand für sämtliche aus und/ oder in Zusammenhang mit diesem
                    Vertragsverhältnis zwischen bumpee und dem Kunden erwachsenden Streitigkeiten ist, soweit rechtlich
                    zulässig, der Geschäftssitz von bumpee.
                    <br/>12.3<br/>Für den Vertragsschluss stehen dem Kunden eine Fassung der AGB in deutscher und
                    englischer Sprache zur Verfügung, die auf der Internetseite von bumpee abrufbar sind. Maßgeblich für
                    den Vertragsschluss für Kunden aus der DACH-Region – Deutschland, Österreich Schweiz – ist dabei die
                    zum Vertragsschluss gültige deutsche Fassung.
                </Trans></p>
            </div>
        </>
    );
};

export default TermsOfService;