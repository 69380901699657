import React from 'react';
import {Trans, Plural} from "@lingui/macro";
import ClipboardButton from "./ClipboardButton";
import UsedIndicator from "./UsedIndicator";
import {Trash2} from "react-feather";

const CodeRow = ({code, calculateDaysLeft, deleteCode}) => {
    const link = "bumpee.co/addProfile/" + code.id
    return <div className="worker codeView">
        <div className="worker-information">
            <p style={{width: "8rem"}}>
                <Trans><span style={{fontWeight: 500}}>Code:</span> {code.id}</Trans>
            </p>
            <div style={{width: "15rem"}}>
                <ClipboardButton link={link}/>
            </div>
            <p><Plural value={calculateDaysLeft(code.date)} one="Noch ein Tag gültig" other="Noch # Tage gültig"/></p>
            <UsedIndicator used={code.used}/>
        </div>
        <button className="optionButton" style={{
            border: "none",
            background: "white",
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            color: "black",
            cursor: "pointer"
        }} onClick={() => deleteCode(code.ref)}>
            <Trash2/>
        </button>
    </div>
};

export default CodeRow;