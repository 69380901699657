import React, {useEffect, useState} from 'react';
import ReactDropdown from "react-dropdown";
import {i18n} from '@lingui/core'

const languageOptions = [
    {value: "DE", key: "de"},
    {value: "EN", key: "en"},
];

const LanguageToggle = () => {
    const [select, setSelect] = useState((languageOptions.find(opt => opt.key === localStorage.getItem("language")))?.value ??
        navigator.language.split("-")[0].toUpperCase());

    useEffect(() => {
        const key = (languageOptions.find(opt => opt.value === select))?.key
        localStorage.setItem("language", key);
        i18n.activate(key)
    }, [select])

    return (
        <ReactDropdown onChange={(e) => setSelect(e.value)} options={languageOptions.map(opt => opt.value)}
                       value={select}
                       placeholder="Sprache ändern" controlClassName="footerDropControl"
                       menuClassName="footerDropMenu"/>
    );
};

export default LanguageToggle;