import React from 'react';
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
import {Trans} from "@lingui/macro";
import OptionButtons from "../Widgets/OptionButtons";
import {X} from "react-feather";

const CropperPopup = ({image, setProfile, close, crop, setCrop, ratio, saveCrop}) => {
    return (
        <div className="popup">
            <button onClick={close} className="btn whiteButton closePopupButton">
                <X stroke={"#0a0a0a"}/>
            </button>
            <div>
                <h1><Trans>Bild zuschneiden</Trans></h1>
                <p style={{fontSize: "16px"}}><Trans>Nutzen Sie die Ecken um den richtigen Bereich für das Profil
                    auszuschneiden</Trans></p>
            </div>
            <div>
                <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={ratio} circularCrop={ratio === 1}
                           minHeight={100}
                           minWidth={100}>
                    <img className="cropPreview" src={image} alt={"new Upload"}/>
                </ReactCrop>
                <OptionButtons cancel={close} action={() => {
                    saveCrop(crop).then(result => {
                        setProfile(result)
                        close()
                    })
                }}/>
            </div>
        </div>
    );
};

export default CropperPopup;