import React from 'react';
import {t, Trans} from "@lingui/macro";
import EditInviteButton from "./EditInviteButton";
import ReactDropdown from "react-dropdown";
import {Check, Eye, Trash2} from "react-feather";

const EditSettings = ({inviteWorker, id, setSelect, options, select, previewLink, deleteWorker, close}) => {
    const initiateDeleteWorker = () => {
        deleteWorker()
        close()
    }

    return (
        <div className="editSettings">
            <div>
                <label>{t`Vorlage`}:</label>
                <ReactDropdown onChange={(e) => setSelect(e)} options={options} value={select}
                               placeholder={t`Vorlage wechseln`}/>
            </div>
            <EditInviteButton invite={inviteWorker} id={id}/>
            <div>
                <label><Trans>Vorschau</Trans>:</label>
                <a href={previewLink} target="_blank" className="btn greyButton optionButton"><Eye stroke={"#000"}/><Trans>Anzeigen</Trans></a>
            </div>
            <div>
                <label><Trans>Profil löschen</Trans>:</label>
                <button className="btn greyButton optionButton" onClick={initiateDeleteWorker}>
                    <Trash2/>
                    <Trans>Löschen</Trans>
                </button>
            </div>
            <button className="btn optionButton" onClick={() => close()}><Check stroke={"#fff"}/>
                <Trans>Schließen</Trans>
            </button>
        </div>
    );
};

export default EditSettings;