import OrderWorker from "./OrderWorker";
import {ReactComponent as Profile} from "../../img/profile-icon.svg";
import {Trans} from "@lingui/macro";

const Workers = ({workers, onDelete, addRef}) => {
    return (
        <div className="workers">
            <div className="worker" style={{paddingTop: "1rem", background: "none"}}>
                <div className="worker-information">
                    <Profile stroke={"#B1B1B1"} width="86px" style={{marginLeft: "0"}}/>
                    <p style={{width: "12rem", color: "#B1B1B1", marginLeft: "0"}}>
                        <Trans>Name</Trans>
                    </p>
                    <p style={{width: "12rem", color: "#B1B1B1"}}>
                        <Trans>Beschreibung</Trans>
                    </p>
                    <p style={{width: "12rem", color: "#B1B1B1"}}>
                        <Trans>Kartenvorlage</Trans>
                    </p>
                </div>
                <p style={{width: "11rem", color: "#B1B1B1", marginBlock: "auto"}}>
                    <Trans>Entfernen</Trans>
                </p>
            </div>
            {
                workers.length === 0 ?
                    <div className="centerMsg">
                        <p style={{marginBottom: "1rem"}}>
                            <Trans>Fügen Sie zunächst Profile hinzu um für diese Smart Business Cards zu
                                bestellen</Trans>
                        </p>
                        <button className="btn" onClick={() => addRef.current.open()}>
                            <Trans>Profile auswählen</Trans>
                        </button>
                    </div>
                    :
                    workers.map((worker, index) => (
                        <OrderWorker key={index}
                                     worker={worker}
                                     onDelete={onDelete}
                        />
                    ))
            }
        </div>
    );
};

export default Workers;
