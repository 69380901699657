import {companyCard} from "./CompanyGeneration";
import React, {useEffect, useRef, useState} from "react";
import {t, Trans} from "@lingui/macro";
import MaterialChoice from "./CardFormSlider/MaterialChoice";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
//import images
import color_palette from "../../../img/icons8/icons8-paint-palette-96.png";
import image from "../../../img/icons8/icons8-image-96.png";
import tools from "../../../img/icons8/icons8-tools-96.png";
import qrCode from "../../../img/icons8/icons8-qr-code-96.png";
import ColorChoice from "./CardFormSlider/ColorChoice";
import FrontsideChoice from "./CardFormSlider/FrontsideChoice";
import BacksideChoice from "./CardFormSlider/BacksideChoice";

const CardTemplateForm = ({
                              setCard,
                              background,
                              setBackground,
                              textColor,
                              setTextColor,
                              logo,
                              setLogo,
                              setSize,
                              setRound,
                              round,
                              inverted,
                              setInverted,
                              size,
                              setLogoUpdated,
                              errors,
                              setErrors,
                              changeVariant,
                              productOptions,
                              alignment,
                              setAlignment,
                              noLogoBack,
                              setNoLogoBack,
                              noText,
                              setNoText,
                              currentOptions,
                              checkChanges
                          }) => {
    const [crop, setCrop] = useState()

    useEffect(() => {
        const isNoLogo = currentOptions?.find(option => option.name === "Logo").value === productOptions?.find(option => option.name === "Logo").values[0].value
        const isWhiteLabel = currentOptions?.find(option => option.name === "Branding").value === productOptions?.find(option => option.name === "Branding").values[1].value
        checkChanges()

        if (!!currentOptions) {
            companyCard(
                t`Name`,
                t`Beschreibung`,
                size,
                background,
                textColor,
                round,
                logo,
                true,
                inverted,
                alignment,
                isNoLogo,
                noLogoBack,
                noText,
                isWhiteLabel,
                currentOptions?.find(option => option.name === "Material").value
            ).then((card) => {
                setCard(card)
            })
        }
    }, [background, textColor, round, logo, size, inverted, alignment, noLogoBack, currentOptions, noText, setCard, productOptions]);

    const slider = useRef(null)
    const pagination_icons = [tools, color_palette, image, qrCode]

    const material_selection = currentOptions?.find(option => option.name === "Material").value
    if (material_selection?.toLowerCase().includes("wood")) pagination_icons.splice(1, 1)

    const logo_selection = currentOptions?.find(option => option.name === "Logo").value
    const logo_options = productOptions?.find(option => option.name === "Logo").values
    const isNoLogo = !!logo_options ? logo_options[0]?.value === logo_selection : false

    const [currentSlide, setCurrentSlide] = useState(0)

    return (
        <div style={{display: "flex", alignItems: "center", gap: "1rem",}}>
            <Splide options=
                        {{
                            direction: 'ttb',
                            perPage: 1,
                            pagination: false,
                            gap: "1rem",
                            drag: false,
                            height: "500px",
                        }}
                    ref={slider}
                    onMove={(e, newIndex) => {
                        setCurrentSlide(newIndex)
                    }}
                    hasTrack={false}
            >
                <SplideTrack>
                    <SplideSlide>
                        <MaterialChoice
                            productOptions={productOptions?.find(option => option.name === "Material").values}
                            selected={material_selection}
                            changeVariant={changeVariant}
                        />
                    </SplideSlide>
                    {
                        !material_selection?.toLowerCase().includes("wood") &&
                        <SplideSlide>
                            <ColorChoice background={background} setBackground={setBackground} text={textColor}
                                         setText={setTextColor}/>
                        </SplideSlide>
                    }
                    <SplideSlide>
                        <FrontsideChoice setLogo={setLogo} crop={crop} setCrop={setCrop}
                                         setLogoUpdated={setLogoUpdated} errors={errors} setErrors={setErrors}
                                         inverted={inverted} setInverted={setInverted}
                                         round={round} setRound={setRound} size={size} setSize={setSize}
                                         material={material_selection} setAlignment={setAlignment} alignment={alignment}
                                         options={logo_options}
                                         noLogo={isNoLogo}
                                         changeVariant={changeVariant}
                                         noText={noText} setNoText={setNoText}
                        />
                    </SplideSlide>
                    <SplideSlide>
                        <BacksideChoice changeVariant={changeVariant}
                                        options={productOptions?.find(option => option.name === "Branding").values}
                                        whiteLabel={currentOptions?.find(option => option.name === "Branding").value}
                                        noLogo={noLogoBack} setNoLogo={setNoLogoBack}
                                        crop={crop} setCrop={setCrop} size={size} setSize={setSize}
                                        material={material_selection} inverted={inverted} setInverted={setInverted}
                                        round={round} setRound={setRound}
                                        errors={errors} setErrors={setErrors}
                                        setLogoUpdated={setLogoUpdated}
                                        setLogo={setLogo}
                                        noLogoFront={isNoLogo}
                        />
                    </SplideSlide>
                </SplideTrack>

                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev custom-arrows">
                        <span><Trans>Zurück</Trans>{currentSlide !== 0 ? ":" : ""}</span>
                        {
                            currentSlide !== 0 && <img src={pagination_icons[currentSlide - 1]} alt=""/>
                        }
                    </button>
                    <button className="splide__arrow splide__arrow--next custom-arrows next">
                        <span><Trans>Weiter</Trans>{(pagination_icons.length - 1) !== currentSlide ? ":" : ""}</span>
                        {
                            (pagination_icons.length - 1) !== currentSlide &&
                            <img src={pagination_icons[currentSlide + 1]} alt=""/>
                        }
                    </button>
                </div>
            </Splide>
            <div className="custom-pagination">
                {
                    pagination_icons.map((icon, index) => (
                        <button onClick={() => slider.current.go(index)}
                                className={currentSlide === index ? "active-page" : ""}
                                key={index}
                        >
                            <img src={icon} alt=""/>
                        </button>
                    ))
                }
            </div>
        </div>
    );
};

export default CardTemplateForm;
