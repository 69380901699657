import React, {useEffect, useState} from 'react';
import {t, Trans} from "@lingui/macro";
import ReactJoyride from "react-joyride";
import {useNavigate} from "react-router-dom";

const tourSteps = [
    {
        target: "#profile",
        content: <Trans>Verwalten Sie alle Profile Ihrer Mitarbeitenden.</Trans>,
        disableBeacon: true,
    },
    {
        target: "#profileAdd",
        content: <Trans>Fügen Sie weitere Mitarbeitende hinzu, aber halten Sie am besten die Informationen der Personen
            bereit.</Trans>
    },
    {
        target: "#templates",
        content: <Trans>Verwalten Sie hier Ihre Karten und Profilvorlagen. Sie können auch weitere hinzufügen für
            verschiedene Teams oder Gruppen Ihres Unternehmens.</Trans>
    },
    {
        target: "#ordering",
        content: <Trans>Nachdem Sie Profile für Ihre Mitarbeiter erstellt haben, können Sie Ihre digitalen Visitenkarten hier bestellen.</Trans>
    },
    {
        target: "#orderingAdd",
        content: <Trans>Über dieses Fenster fügen Sie bereits erstellte Profile zu Ihrer Kartenbestellung hinzu.</Trans>
    },
    {
        target: "#settings",
        content: <Trans>Hier finden Sie Einstellungsmöglichkeiten, um Ihr Passwort zu ändern oder Ihre
            Firmeninformationen anzupassen.</Trans>
    },
    {
        target: "#app-inivitation",
        content: <Trans>Fügen Sie App-Einladungen für Ihre Mitarbeiter hinzu um Ihnen den Zugang auf Ihr Firmenprofil am
            Smartphone zu ermöglichen.</Trans>
    },
    {
        target: "#subscription-plans",
        content: <Trans>
            Hier können Sie Ihren Abonnement-Plan verwalten. Sie können auch ein Upgrade auf ein höheres Abonnement
            durchführen.
        </Trans>
    }
];

const OnBoardingTour = ({waiting}) => {
    const [run, setRun] = useState(localStorage.getItem("tourCompleted") !== "true");
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("tourCompleted") !== "true")
            setRun(!waiting);
    }, [waiting])

    const handleJoyrideCallback = data => {
        const {action, index, type} = data;

        if (action === "close" || action === "skip" || action === "reset") {
            setRun(false);
            localStorage.setItem("tourCompleted", true);
        }

        if (type === 'step:before' && index === 0) {
            navigate("/");
        }

        if (type === 'tooltip' && index === 2) {
            navigate("/templates");
        }

        if (type === 'tooltip' && index === 3) {
            navigate("/ordering");
        }

        if (type === 'tooltip' && index === 5) {
            navigate("/settings");
        }
    }

    return (
        <ReactJoyride steps={tourSteps}
                      continuous={true}
                      showProgress={true}
                      styles={{
                          options: {
                              arrowColor: "#fff",
                              primaryColor: '#078080'
                          }
                      }}
                      showSkipButton={true}
                      run={run}
                      callback={handleJoyrideCallback}
                      disableScrolling={true}
                      disableOverlayClose={true}
                      locale={{
                          back: t`Zurück`, close: t`Schließen`, last: t`Ende`, next: t`Weiter`, skip: t`Überspringen`
                      }}
        />
    );
};

export default OnBoardingTour;