import CropperPopup from "../NewWorker/CropperPopup";
import {useEffect, useRef, useState} from "react";
import Popup from "reactjs-popup";
import smartcrop from "smartcrop";
import UploadButtons from "./UploadButtons";

const InputUpload = ({text, description, error, setError, setUpload, crop, setCrop, ratio, setLogoUpdated}) => {
    const [inputImage, setInputImage] = useState()
    const cropRef = useRef()

    const imageUpload = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader()
        reader.onloadend = async () => {
            const res = {
                unit: '%', // Can be 'px' or '%'
                x: 5,
                y: 5,
                width: 90,
                height: 90
            }
            setCrop(res)
            let result = reader.result
            if (ratio === 16 / 9)
                result = await uploadExpandedImage(result)

            if (setError) setError({})
            setUpload(result)
            setInputImage(result)
            setLogoUpdated && setLogoUpdated(true)
            cropRef.current.open()
        }
        reader.readAsDataURL(file)
    }

    const createSmartCrop = () => {
        return new Promise(resolve => {
            let image = new Image()
            image.onload = () => smartcrop.crop(image, {
                width: 200,
                height: 200,
                minScale: 0.5
            }).then(res => {
                const top = res.topCrop
                top.unit = "%"
                top.x = top.x / image.width * 100
                top.y = top.y / image.height * 100
                top.width = top.width / image.width * 100
                top.height = top.height / image.height * 100
                resolve(top)
            })
            image.src = inputImage
        })
    }

    const uploadExpandedImage = (uploadImage) => {
        return new Promise(resolve => {
            const image = new Image()
            image.onload = () => {
                const canvas = document.createElement("canvas")
                let ctx = canvas.getContext("2d")
                ctx.fillStyle = "white";
                let width = image.width, height = image.height

                if (width / height > ratio) {
                    canvas.height = width / ratio
                    canvas.width = width
                    ctx.drawImage(image, 0, canvas.height / 2 - height / 2)
                } else {
                    canvas.height = height
                    canvas.width = height * ratio
                    ctx.drawImage(image, canvas.width / 2 - width / 2, 0)
                }

                resolve(canvas.toDataURL("image/png"))
            }

            image.src = uploadImage
        })
    }

    const saveCrop = (crop) => {
        if (crop.width === 0 || crop.height === 0) return inputImage
        return new Promise(resolve => {
            const showcase = document.getElementsByClassName("cropPreview")

            let profile = new Image()
            profile.onload = () => {
                const canvas = document.createElement('canvas');
                let ctx = canvas.getContext("2d")
                if (crop.unit === "%") {
                    canvas.width = profile.width * crop.width / 100
                    canvas.height = profile.height * crop.height / 100
                    ctx.drawImage(
                        profile,
                        profile.width * crop.x / 100,
                        profile.height * crop.y / 100,
                        profile.width * crop.width / 100,
                        profile.height * crop.height / 100,
                        0,
                        0,
                        canvas.width,
                        canvas.height)
                } else {
                    const scaleX = profile.width / showcase[0].width
                    const scaleY = profile.height / showcase[0].height
                    canvas.width = crop.width * scaleX;
                    canvas.height = crop.height * scaleY;
                    ctx.drawImage(profile, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, canvas.width, canvas.height)
                }

                resolve(canvas.toDataURL("jpg"))
            }

            profile.src = inputImage
        })
    }

    useEffect(() => {
        if (inputImage && ratio === 1) {
            createSmartCrop().then(res => {
                setCrop(res)
                saveCrop(res).then(canvas => setUpload(canvas))
            })
        }
    }, [inputImage])

    return (
        <div className="form-control">
            <label id={"labelFor" + text} htmlFor={text}>{description}</label>
            <div className="file-input">
                <input id={text}
                       className="file"
                       type="file"
                       accept="image/*"
                       onChange={imageUpload}
                />
                <div className="picture-upload-container">
                    <UploadButtons text={text} inputImage={inputImage} open={() => cropRef.current.open()}/>
                    <Popup
                        modal
                        contentStyle={{maxWidth: "80vw", width: "inherit", minWidth: "600px"}}
                        ref={cropRef}
                    >
                        <CropperPopup image={inputImage} setProfile={setUpload} close={() => cropRef.current.close()}
                                      crop={crop} setCrop={setCrop} ratio={ratio} saveCrop={saveCrop}/>
                    </Popup>
                </div>
            </div>
            <span style={{color: "red"}}>{error}</span>
        </div>
    );
};

export default InputUpload;
