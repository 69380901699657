import React from 'react';
import {Trans} from "@lingui/macro";
import {Link} from "react-router-dom";

const TemplateSelect = ({src, description, title, path}) => {
    return (
        <Link to={path}>
            <div className="template-select-container">
                <img alt={src} src={src} />
                <h2>{title}</h2>
                <p>{description}</p>
                <div className="btn"><Trans>Auswählen</Trans></div>
            </div>
        </Link>
    );
};

export default TemplateSelect;