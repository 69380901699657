import React from 'react';

const SettingWidget = ({text, icon, action, id}) => {
    return (
        <button className="setting-widget" onClick={action} style={action ? {cursor: "pointer"} : {cursor: "initial"}} id={id}>
            <div className="setting-widget-info">
                {icon}
                <p>{text}</p>
            </div>
        </button>
    );
};

export default SettingWidget;