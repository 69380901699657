import {useEffect, useRef, useState} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Overview from "./components/OrderOverview/Overview";
import Client from "shopify-buy"
import {auth, db, logout, onRegister, storage} from './firebase';
import TemplateContainer from "./components/Templates/Container/TemplateContainer";
import ProfileOverview from "./components/ProfileOverview/ProfileOverview";
import Login from "./components/Login/Login";
import SidebarLayout from "./components/Sidebar/SidebarLayout"
import {
    collection,
    query,
    doc,
    updateDoc,
    deleteDoc,
    onSnapshot,
    getDoc,
    where,
    writeBatch,
    addDoc
} from "firebase/firestore"
import CardSettings from "./entities/CardSettings";
import {useAuthState} from "react-firebase-hooks/auth";
import Employee from "./entities/Employee";
import ProfileSettings from "./entities/ProfileSettings";
import account_types from "./components/Templates/ProfileTemplate/account_types.json"
import ProfileTemplateContainer from "./components/Templates/ProfileTemplate/ProfileTemplateContainer";
import CardTemplatePreview from "./components/Templates/Container/CardTemplatePreview";
import ProfilePreviewTemplate from "./components/Templates/Container/ProfilePreviewTemplate";
import NewWorker from "./components/NewWorker/NewWorker";
import {getDownloadURL, uploadBytes, ref, deleteObject} from "firebase/storage";
import {v4 as uuidv4} from "uuid";
import ProfileSelection from "./components/DeprecatedOrdering/ProfileSelection";
import SettingsContainer from "./components/Settings/SettingsContainer";
import CardTemplateContainer from "./components/Templates/CardTemplate/CardTemplateContainer";
import TermsOfService from "./components/TermsOfService/TermsOfService";
import WelcomePages from "./components/WelcomePages/WelcomePages";
import WelcomeSheet from "./components/WelcomePages/WelcomeSheet";
import WelcomeProfile from "./components/WelcomePages/WelcomeProfile";
import WelcomeNewWorker from "./components/WelcomePages/WelcomeNewWorker";
import WelcomeCard from "./components/WelcomePages/WelcomeCard";
import WelcomeFinish from "./components/WelcomePages/WelcomeFinish";
import WelcomeInfoSlides from "./components/WelcomePages/WelcomeInfoSlides";
import InvitationOverview from "./components/Settings/AppInvitation/InvitationOverview";
import InviteCode from "./entities/InviteCode";
import onBuy from "./functions/ShopifyCheckout";
import 'react-loading-skeleton/dist/skeleton.css'
import subscriptionState from "./hooks/SubscriptionState";
import Team from "./entities/Team";

const client = Client.buildClient({
    domain: "slashme.myshopify.com",
    storefrontAccessToken: "52219de5d3073eaf8ace7bb1537bedeb"
})

const isMobil = () => {
    let check = false;
    (function (a) {
        // eslint-disable-next-line
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
}

const sorted = account_types.sort((a, b) => {
    return b.ranking - a.ranking
})

const importAll = (r) => {
    return r.keys().map(r);
}

const images = importAll(require.context('./img/AccountIcons', false, /\.(svg)$/));

const App = () => {
    const [newOrderWorkers, setNewOrderWorkers] = useState(JSON.parse(localStorage.getItem("newOrderStorage")) || [])
    const [employees, setEmployees] = useState([])

    const [waiting, setWaiting] = useState(true)
    const [cardTemplates, setCardTemplates] = useState([])

    const [accountSettings, setAccountSettings] = useState([])

    const [selected, setSelected] = useState([])

    const [companyName, setCompanyName] = useState("")

    //Invite codes used to invite new employees to the app
    const [inviteCodes, setInviteCodes] = useState([])

    const [user, loading] = useAuthState(auth);

    const [checkout, setCheckout] = useState('')
    const [team, setTeam] = useState(null)
    const [plans, activePlanName] = subscriptionState(team)

    const employeesLoaded = useRef(false)
    const cardTemplatesLoaded = useRef(false)
    const accountTemplatesLoaded = useRef(false)

    const subscribers = useRef([])

    const [firstLogin, setFirstLogin] = useState(false)

    useEffect(() => {
        if (employeesLoaded.current && accountTemplatesLoaded.current && cardTemplatesLoaded.current) setWaiting(false)
    }, [employeesLoaded.current, accountTemplatesLoaded.current, cardTemplatesLoaded.current])

    const logoutUser = () => {
        setEmployees([])
        setCardTemplates([])
        setAccountSettings([])
        setNewOrderWorkers([])
        localStorage.setItem("newOrderStorage", JSON.stringify([]))
        setSelected([])

        subscribers.current.forEach(subscriber => subscriber());
        logout()
    }

    const createCheckout = async () => {
        await onBuy(newOrderWorkers, client, cardTemplates, setCheckout)
    }

    useEffect(() => {
        setWaiting(true)
        const setUserRef = async () => {
            let team

            try {
                const userRef = doc(db, "users", user.uid)
                const snapshot = await getDoc(userRef)
                team = snapshot.data().team
            } catch {
                setFirstLogin(true)
                await onRegister()
                const userRef = doc(db, "users", user.uid)
                const snapshot = await getDoc(userRef)


                team = snapshot.data().team
            }

            return team
        }

        if (!user) return

        setUserRef().then(teamId => {
            const team_query = doc(db, "teams", teamId)

            const teamSnap = onSnapshot(team_query, (doc) => {
                if (!doc.exists()) {
                    console.log('No such document!');
                } else {
                    setTeam(new Team(doc.data(), doc.id, doc.ref))
                }
            })

            subscribers.current.push(teamSnap)

            const card_query = query(collection(db, 'teams/' + teamId + '/card_temp'))

            const cardSettSnap = onSnapshot(card_query, (querySnapshot) => {
                setCardTemplates(querySnapshot.docs.map(doc => {
                    return new CardSettings(doc.data(), doc.id, doc.ref)
                }))
                cardTemplatesLoaded.current = true
            })
            subscribers.current.push(cardSettSnap)

            const profile_query = query(collection(db, "teams/" + teamId + "/prof_temp"))
            const profSettSnap = onSnapshot(profile_query, snapshot => {
                setAccountSettings(snapshot.docs.map(doc => new ProfileSettings(doc.data(), doc.id, doc.ref)))
                accountTemplatesLoaded.current = true
            })
            subscribers.current.push(profSettSnap)

            const employee_query = query(collection(db, "cards"), where("team", "==", teamId))
            const employeeSnap = onSnapshot(employee_query, (querySnapshot) => {
                setEmployees(querySnapshot.docs.map(doc => {
                    return new Employee(doc.data(), doc.id, doc.ref)
                }))

                employeesLoaded.current = true
            })

            subscribers.current.push(employeeSnap)

            const docRef = doc(db, 'teams', teamId);

            const companySnap = onSnapshot(docRef, (querySnapshot) => {
                setCompanyName(querySnapshot.data().name)
            })
            subscribers.current.push(companySnap)

            const thirtyDaysAgo = new Date()
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

            const invite_query = query(collection(db, "profile_invites"), where("team", "==", teamId), where("date", ">", thirtyDaysAgo))
            const inviteSnap = onSnapshot(invite_query, (querySnapshot) => {
                setInviteCodes(querySnapshot.docs.map(doc => {
                    return new InviteCode(doc.data(), doc.id, doc.ref)
                }))
            })
            subscribers.current.push(inviteSnap)

            if (employeesLoaded.current && accountTemplatesLoaded.current && cardTemplatesLoaded.current) setWaiting(false)
        })
    }, [user])

    const updateCompanyName = (name) => {
        setWaiting(true)
        updateDoc(team.ref, {
            name: name
        }).then(() => {
            setWaiting(false)
        })
    }

    // Delete OrderWorker
    const deleteWorkers = async (employee) => {
        setWaiting(true)
        const batch = writeBatch(db)

        if (employee) {
            try {
                if (employee.profile) {
                    const desertRef = await ref(storage, employee.profile)
                    await deleteObject(desertRef)
                }
            } catch (e) {
                console.log(e.message)
            } finally {
                batch.delete(employee.ref)
            }
        } else {
            for (const profile of selected) {
                try {
                    if (profile.profile) {
                        const desertRef = await ref(storage, profile.profile)
                        await deleteObject(desertRef)
                    }
                } catch (e) {
                    console.log(e.message)
                } finally {
                    batch.delete(profile.ref)
                }
            }
        }

        await batch.commit()
        setWaiting(false)
    }

    const saveToCard = async (map, id) => {
        setWaiting(true)
        const setting = cardTemplates.find(setting => id === setting.id)
        await updateDoc(setting.ref, map)
        setWaiting(false)
    }

    const saveProfileSettings = async (map, id) => {
        setWaiting(true)
        const setting = accountSettings.find(setting => id === setting.id)
        await updateDoc(setting.ref, map)
        setWaiting(false)
        return true
    }

    const saveNewWorkers = async (maps, id) => {
        setWaiting(true)
        const batch = writeBatch(db)
        for (const newWorker of maps) {
            const image_id = uuidv4()
            const storageRef = ref(storage, "profile/" + image_id)
            const compressedProfile = await Employee.compressImage(Employee.dataURLtoFile(newWorker.profile, "logo.png"))
            const snapshot = await uploadBytes(storageRef, compressedProfile)
            const imageUrl = await getDownloadURL(snapshot.ref)

            let setting = accountSettings.find(setting => id === setting.id)
            const worker = Employee.toMap(setting.webLogo, setting.background, setting.color, imageUrl, newWorker.description, newWorker.name, [newWorker.id], team.id ?? "", newWorker.accounts, id)
            const newProfile = doc(collection(db, "cards"))
            batch.set((newProfile), worker)
        }

        await batch.commit()
        setWaiting(false)
    }

    const addSelectedWorkers = (workers, template) => {
        const newEntries = workers.filter(worker => (
            !newOrderWorkers.some(orderWorker => orderWorker.id === worker.id)
        ))

        newEntries.forEach(entry => {
            entry.cardTemplate = template.id
            entry.templateName = template.name
        })

        localStorage.setItem("newOrderStorage", JSON.stringify([...newOrderWorkers, ...newEntries]))
        setNewOrderWorkers([...newOrderWorkers, ...newEntries])
    }

    const createTemplate = async (name, isCard, isWelcome) => {
        setWaiting(true)

        if (isWelcome && (isCard ? cardTemplates.length > 0 : accountSettings.length > 0)) {
            console.log("Welcome Template already exists")
            setWaiting(false)
            return isCard ? cardTemplates[0] : accountSettings[0]
        }

        if (!name) name = "Standard"
        let newTemplate

        if (isCard) {
            if (cardTemplates.some(temp => temp.name === name)) name = name + 1
            newTemplate = await addDoc(collection(db, 'teams/' + team.id + '/card_temp'), {
                "background": "#000000",
                "round": false,
                "inverted": false,
                "size": "Medium",
                "text_color": "#ffffff",
                "alignment": "right aligned",
                "no_logo_back": true,
                "no_text": false,
                "name": name
            })
        } else {
            if (accountSettings.some(temp => temp.name === name)) name = name + 1
            newTemplate = await addDoc(collection(db, 'teams/' + team.id + '/prof_temp'), {
                "array": [],
                "color": "4278190080",
                "links": [],
                "name": name
            })
        }
        setWaiting(false)
        return newTemplate
    }

    const deleteProfileTemplate = async (id) => {
        if (employees.some(employee => employee.template === id)) return false
        setWaiting(true)


        const deletingTemplate = accountSettings.find(setting => setting.id === id)

        if (deletingTemplate.background)
            try {
                await deleteObject(ref(storage, deletingTemplate.background))
            } finally {

            }

        await deleteDoc(doc(db, 'teams/' + team.id + '/prof_temp', deletingTemplate.id))

        setWaiting(false)
        return true
    }

    const deleteCardTemplate = async (id) => {
        setWaiting(true)
        const deletingTemplate = cardTemplates.find(setting => setting.id === id)

        if (deletingTemplate.profile) {
            try {
                await deleteObject(ref(storage, deletingTemplate.profile))
            } finally {

            }
        }

        await deleteDoc(doc(db, 'teams/' + team.id + '/card_temp', deletingTemplate.id))
        setWaiting(false)
        return true
    }

    return (
        isMobil() ?
            <div className="centerMsg">
                <p style={{marginBottom: "1rem"}}>Diese Website unterstützt leider noch keine Mobile-Ansicht. Bitte
                    versuchen Sie es an einem Desktop erneut.</p>
                <a className="btn" href="https://www.bumpee.co/">Zurück zum Shop</a>
            </div>
            :
            <Router>
                <Routes>
                    <Route element={<SidebarLayout waiting={waiting}
                                                   firstLogin={firstLogin}
                                                   companyName={companyName}
                                                   activePlan={activePlanName}
                    />}>
                        <Route path="/templates" element={
                            <TemplateContainer createTemplate={createTemplate}
                                               cardLoaded={cardTemplatesLoaded.current}
                                               profileLoaded={accountTemplatesLoaded.current}
                            />
                        }>
                            <Route path="/templates/card"
                                   element={
                                       cardTemplates.map((setting, index) =>

                                           <CardTemplatePreview setting={setting}
                                                                key={index}
                                                                selectionMode={false}
                                           />
                                       )
                                   }
                            />
                            <Route path="/templates/profile"
                                   element={accountSettings.map((setting, index) =>
                                       <ProfilePreviewTemplate
                                           setting={setting} key={index} images={images}
                                       />)} selection={false}
                            />
                        </Route>
                        <Route path="/templates/card/:templateId" element={
                            <CardTemplateContainer settings={cardTemplates}
                                                   saveCard={saveToCard}
                                                   setWaiting={setWaiting}
                                                   deleteCardTemplate={deleteCardTemplate}
                                                   waiting={waiting}
                                                   shopify_client={client}
                            />
                        }/>
                        <Route path="/templates/profile/:templateId" element={
                            <ProfileTemplateContainer onSave={saveProfileSettings} settings={accountSettings}
                                                      sorted={sorted}
                                                      images={images}
                                                      deleteProfileTemplate={deleteProfileTemplate}
                                                      activePlan={team?.plan}
                            />
                        }/>
                        <Route path="/ordering"
                               element={
                                   <Overview
                                       onBuy={createCheckout}
                                       checkout={checkout}
                                       setCheckout={setCheckout}
                                       cardSettings={cardTemplates}
                                       cardWorkers={newOrderWorkers}
                                       setCardWorkers={setNewOrderWorkers}
                                       createTemplate={createTemplate}
                                   />
                               }
                        />
                        <Route path="/ordering/:templateId" element={
                            <ProfileSelection employees={employees} addOrderWorkers={addSelectedWorkers}
                                              settings={cardTemplates}
                                              profilesLoaded={employeesLoaded.current}
                            />
                        }/>
                        <Route path="/settings" element={
                            <SettingsContainer mail={user && user.email} logout={logoutUser} waiting={waiting}
                                               currentName={companyName} updateName={updateCompanyName}
                                               plans={plans} activePlanId={team?.plan}
                            />
                        }/>
                        <Route path="/settings/invites" element={
                            <InvitationOverview workers={employees} invites={inviteCodes} waiting={waiting}
                                                setWaiting={setWaiting}/>
                        }/>
                        <Route path="/" element={
                            <ProfileOverview workers={employees}
                                             deleteWorker={deleteWorkers}
                                             selected={selected}
                                             setSelected={setSelected}
                                             sorted={sorted}
                                             accountSettings={accountSettings}
                                             images={images}
                                             setLoading={setWaiting}
                                             createTemplate={createTemplate}
                                             dataLoaded={employeesLoaded.current}
                                             invites={inviteCodes}
                            />
                        }
                        />
                        <Route path="/newprofiles/:templateId" element={
                            <NewWorker
                                settings={accountSettings}
                                sorted={sorted}
                                images={images}
                                saveNewWorkers={saveNewWorkers}
                                form={true}
                                waiting={waiting}
                            />
                        }/>
                        <Route path="/terms-of-service" element={
                            <TermsOfService/>
                        }/>
                    </Route>
                    <Route element={<WelcomePages companyName={companyName}/>}>
                        <Route path="/welcome" element={
                            <WelcomeSheet updateCompanyName={updateCompanyName}
                                          waiting={waiting} name={companyName}
                                          setFirstLogin={setFirstLogin}
                            />
                        }/>
                        <Route path="/welcome/card/:templateId" element={
                            <WelcomeCard settings={cardTemplates} saveCard={saveToCard} setWaiting={setWaiting}
                                         waiting={waiting} shopify_client={client}/>
                        }
                        />
                        <Route path="/welcome/profile/:templateId" element={
                            <WelcomeProfile onSave={saveProfileSettings} setWaiting={setWaiting}
                                            settings={accountSettings} images={images} sorted={sorted}
                            />
                        }/>
                        <Route path="/welcome/newProfile/:templateId" element={
                            <WelcomeNewWorker settings={accountSettings} sorted={sorted} images={images}
                                              saveNewWorkers={saveNewWorkers}
                                              createTemplate={createTemplate}
                                              workers={employees}
                                              setWaiting={setWaiting}
                                              waiting={waiting}
                            />
                        }/>
                        <Route path="/welcome/info/:type" element={
                            <WelcomeInfoSlides createTemplate={createTemplate}/>
                        }/>
                        <Route path="/welcome/finish" element={
                            <WelcomeFinish firstLogin={firstLogin.current}/>
                        }/>
                    </Route>
                    <Route path="/login" element={
                        <Login user={user} loading={loading}/>
                    }/>
                </Routes>
            </Router>
    );
}

export default App;
