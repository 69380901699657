import React from 'react';
import {AlignLeft, AlignCenter, AlignRight} from "react-feather"
import {Trans} from "@lingui/macro";
import InputCheck from "../../../Widgets/InputCheck";

const AlignmentSelector = ({alignment, setAlignment, noText, setNoText}) => {
    const options = [
        {
            name: "left aligned",
            icon: <AlignLeft/>
        },
        {
            name: "centered",
            icon: <AlignCenter/>
        },
        {
            name: "right aligned",
            icon: <AlignRight/>
        }
    ]

    return (
        <div className="form-container">
            <div>
                <label><Trans>Textausrichtung</Trans></label>
                <div className="alignment-selector">
                    {
                        options.map(option =>
                            <button key={option.name}
                                    type="button"
                                    className={`${alignment === option.name && "active"}`}
                                    onClick={() => setAlignment(option.name)}
                            >
                                {option.icon}
                            </button>
                        )
                    }
                    <InputCheck description={<Trans>Kein Text auf der Karte?</Trans>}
                                checked={noText}
                                onChange={() => setNoText(prev => !prev)}
                    />
                </div>
            </div>
        </div>
    );
};

export default AlignmentSelector;