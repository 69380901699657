const onBuy = async (employees, client, cardTemplates, setCheckout) => {
    const lineItemsToAdd = []

    for (const worker of employees) {
        const template = cardTemplates.find(temp => worker.cardTemplate === temp.id)
        const properties = createProperties(worker.name, worker.description, template, worker.id)

        lineItemsToAdd.push({
            quantity: 1,
            variantId: template.variantId ?? "gid://shopify/ProductVariant/44239474327816",
            customAttributes: properties
        })
    }

    client.checkout.create().then((checkout) => {
        let discountCode = "";

        if (employees.length > 100) discountCode = "VOLUME15"
        else if (employees.length > 50) discountCode = "VOLUME10"
        else if (employees.length >= 10) discountCode = "VOLUME5"
        client.checkout.addDiscount(checkout.id, discountCode).then(() => {
            client.checkout.addLineItems(checkout.id, lineItemsToAdd).then(() => {
                setCheckout(checkout.webUrl)
            })
        })
    })
}

const createProperties = (name, desc, template, id) => {
    const cardSettings = createCardSettings(template)
    const colorSettings = createColorSettings(template)
    const textSettings = createTextSettings(name, desc, template)

    const properties = [
        {
            "key": "Logo",
            "value": template.logo
        },
        {
            key: "Colors",
            value: colorSettings
        },
        {
            key: "Text",
            value: textSettings
        },
        {
            key: "Profile ID",
            value: id
        }
    ]

    if (cardSettings) properties.push({ key: "Card Settings", value: cardSettings })

    return properties

}

const createCardSettings = (template) => {
    let settings = ""
    if (template.noLogo) {
        if (template.noLogoBack || !template.whiteLabel) return null
        else settings = "Logo position: Back"
    } else {
        if (template.noLogoBack || !template.whiteLabel) settings = "Logo position: Front"
        else settings = "Logo position: Front and back"
    }

    if (!template.noLogo) settings += " | Size: " + template.size
    if (template.round) settings += " | Rounded corners"
    if (template.material.toLowerCase().includes("wood") && template.inverted) settings += " | Inverted colors"

    return settings
}

const createColorSettings = (template) => {
    return template.background + " | " + template.textColor
}

const createTextSettings = (name, desc, template) => {
    let settings;
    if (template.noText) {
        settings = "No text | " + template.alignment
    } else {
        settings = name + " | " + desc + " | " + template.alignment
    }

    return settings
}

export default onBuy