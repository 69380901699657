import React from 'react';

const PreviewIcon = ({color, account, icon}) => {
    return (
        <li className="account-item" style={{backgroundColor: color}} key={account.id}>
            <img alt={account.name} src={icon}/>
        </li>
    );
};

export default PreviewIcon;