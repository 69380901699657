import React, {useState} from 'react';
import {
    openCustomerPortal,
    subscribeToPlan
} from "../../../functions/StripePlans";
import "./subscriptionPlans.css"
import {Trans} from "@lingui/macro";
import Switch from "react-switch";
import LoadableButton from "../../Widgets/LoadableButton";
import Skeleton from "react-loading-skeleton";
const basePlan = {
    name: "Base-Plan",
    price: 0,
    features: [
        "Benutzerdefinierte Profile",
        "Individuelle Kartenvorlagen",
        "Individuelle Profilvorlagen",
    ],
    prices: [
        {
            pricePerMonth: "0 €",
            interval: "month"
        }
    ]
}

const ManagePlan = ({close, activePlanId, plans}) => {
    const [yearly, setYearly] = useState(true)

    return (
        <div style={{width: "100%"}}>
            <div className="billing_interval">
                <p><Trans>Sie sparen über 15% wenn Sie jährlich bezahlen</Trans></p>
                <div>
                    <p><Trans>Jährlich bezahlen</Trans></p>
                    <Switch checked={yearly}
                            onChange={() => setYearly(prevState => !prevState)}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={48}
                            onColor="#078080"
                            offColor="#efefef"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    />
                </div>
            </div>
            <div className="plans-container">
                {
                    plans ?
                        <>
                            <Plan plan={basePlan} key={"initial"} active={activePlanId === "free" || !activePlanId}/>
                            {
                                plans.map((plan) => {
                                    return (
                                        <Plan plan={plan} yearly={yearly} key={plan.id} active={activePlanId === plan.id}/>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            <Skeleton count={2} containerClassName={"plan-skeleton"} style={{borderRadius: "20px"}} />
                        </>

                }
            </div>
            <button className="btn greyButton" style={{margin: "auto"}} onClick={close}>
                <Trans>Schließen</Trans>
            </button>
        </div>
    );
};

const Plan = ({plan, yearly, active}) => {
    const [waiting, setWaiting] = useState(false)

    const isBasic = !plan.id

    const subscribe = async () => {
        if (active) {
            setWaiting(true)
            await openCustomerPortal()
            return
        }
        setWaiting(true)
        const price = plan.prices?.filter((price) => yearly ? price.interval === "year" : price.interval === "month")[0]
        subscribeToPlan(price.id)
    }

    return (
        <div className={`plan ${active ? "active" : ""}`}>
            <p className="selected-label"><Trans>Ausgewählt</Trans></p>
            <h2>{plan.name}</h2>
            <div className="pricing">
                {
                    plan.prices?.filter((price) => yearly ? price.interval === "year" : price.interval === "month")
                        .map((price) => (
                            <PlanPrice price={price} yearly={yearly} key={price.id}/>
                        ))
                }
            </div>
            <button className="btn" disabled={isBasic} onClick={subscribe}>
                <LoadableButton waiting={waiting}
                                prompt={active ? <Trans>Abo verwalten</Trans> :
                                    isBasic ?
                                        <Trans>Nicht aktiv</Trans>
                                        :
                                        <Trans>Abonnieren</Trans>}/>
            </button>
            <ul className="features-table">
                {
                    plan.features?.map((feature) => (
                        <li key={feature}>{feature}</li>
                    ))
                }
            </ul>
        </div>
    )
}

const PlanPrice = ({price, yearly}) => {
    return <>
        <div>
            <span>{price.pricePerMonth}</span>
            <Trans>/ Monat</Trans>
        </div>
        {
            yearly && <p>(<Trans>Einmalige jährliche Zahlung über {price.price}</Trans>)</p>
        }
    </>
}

export default ManagePlan;