import React, {useRef, useState} from 'react';
import InputUpload from "../../Widgets/InputUpload";
import {t, Trans} from "@lingui/macro";
import {Compass, Info, Smartphone, Star, Users} from "react-feather";
import Popup from "reactjs-popup";
import PopupFrame from "../../Widgets/PopupFrame";
import OptionButtons from "../../Widgets/OptionButtons";
import PhonePreview from "./PhonePreview";
import YourLogo from "../../../img/cardGenerationAssets/YourlogoInverted.png"
import BulletPointBenefit from "../../WelcomePages/BulletPointBenefit";

const CustomLogoUpload = ({activePlan, save, waiting, setLogo, setLogoUpdated}) => {
    const [crop, setCrop] = useState();

    const goToProPlanPop = useRef()

    const activateProPlan = () => {
        save(true)
    }

    const isAvailable = !!activePlan && activePlan !== "free"

    return (
        <div className="addCustomLogoContainer">
            {
                isAvailable ?
                    <>
                        <button className="containerTopButton left" style={{left: 0, right: "inherit"}}>
                            <Star/>
                        </button>
                        <InputUpload text={t`Firmenlogo`}
                                     crop={crop}
                                     setCrop={setCrop}
                                     description={t`Eigenes Logo hochladen`}
                                     setUpload={setLogo}
                                     setLogoUpdated={setLogoUpdated}
                        />
                    </>
                    :
                    <div className="advertPlan">
                        <p>{t`Nur mit Pro-Plan verfügbar`}:</p>
                        <div>
                            <button className="btn whiteButton dynamic-button"
                                    onClick={() => goToProPlanPop.current.open()}>
                                <Star/>
                                <span className="dynamic-text"
                                      data-dynamic-before={t`Ihr eigenes Branding`}
                                      data-dynamic-after={t`Mehr erfahren`}>
                                </span>
                            </button>
                            <Popup
                                trigger={<Info/>}
                                position="bottom center"
                                on="hover"
                                mouseEnterDelay={100}
                                contentStyle={{
                                    padding: "1rem",
                                    fontSize: "0.8rem",
                                    maxWidth: "300px",
                                    textAlign: "center"
                                }}
                                arrow={false}
                            >
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "1rem"
                                }}>
                                    <Trans>Ersetzen Sie das bumpee Branding auf den Online-Profilen Ihrer Mitarbeitenden
                                        mit
                                        Ihrem eigenen Logo.</Trans>
                                    <button className="btn" onClick={() => goToProPlanPop.current.open()}>
                                        {t`Mehr erfahren`}
                                    </button>
                                </div>
                            </Popup>
                        </div>
                        <Popup
                            ref={goToProPlanPop}
                            modal
                            contentStyle={{width: "inherit"}}
                        >
                            <PopupFrame title={t`Ihr eigenes Branding`}
                                        close={() => goToProPlanPop.current.close()}
                                        description={t`Ersetzen Sie das bumpee Branding auf den Online-Profilen Ihrer
                                        Mitarbeitenden mit Ihrem eigenen Logo.`}
                                        content={
                                            <ToProPlanPopup close={() => goToProPlanPop.current.close()}
                                                            activateProPlan={activateProPlan}
                                                            waiting={waiting}
                                            />
                                        }
                            />
                        </Popup>
                    </div>
            }
        </div>
    );
};

const ToProPlanPopup = ({close, activateProPlan, waiting}) => {

    const bulletPoints = [
        {
            icon: <Smartphone/>,
            title: t`Ihr eigenes Logo auf Ihrem Online-Profil`
        },
        {
            icon: <Users/>,
            title: t`Auf allen Online-Profilen Ihrer Mitarbeitenden`
        },
        {
            icon: <Compass/>,
            title: t`Bringen Sie Ihre gesamte Corporate Identity`
        }
    ]

    return (
        <>
            <div className="branding-preview">
                <div className="bullet-point-container">
                    {
                        bulletPoints.map((point, index) =>
                            <BulletPointBenefit key={index}
                                                icon={point.icon}
                                                text={point.title}
                            />
                        )
                    }
                </div>
                <PhonePreview template={true}
                              customLogo={YourLogo}
                              isCustomLogoPreview={true}
                />
            </div>
            <OptionButtons action={activateProPlan}
                           cancel={close}
                           savePrompt={t`Zur Planauswahl`}
                           waiting={waiting}
            />
            <p style={{color: "#0a0a0a66"}}>{t`Ihre Änderungen an der Vorlage werden gespeichert`}</p>
        </>
    )
}

export default CustomLogoUpload;