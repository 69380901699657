import React, {useState} from 'react';
import {Trans} from "@lingui/macro";
import {Clipboard} from "react-feather";

const ClipboardButton = ({link}) => {
    const [copied, setCopied] = useState(false)

    //button to add link to clipboard
    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link).then(() => setCopied(true))
    }

    return (
        <button onClick={() => copyToClipboard(link)} className="copyButton">
            {
                !copied ?
                    <>
                        <p>{link}</p>
                        <Clipboard/>
                    </>
                    :
                    <p style={{fontSize: 14}}><Trans>Copied!</Trans></p>
            }
        </button>
    );
};

export default ClipboardButton;