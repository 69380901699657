import React, {useEffect, useState} from 'react';
import "./ProfileTemplate.css"
import TemplatesHeader from "../TemplatesHeader";
import {Trans} from "@lingui/macro";
import ProfileTemplate from "./ProfileTemplate";
import {useNavigate, useParams} from "react-router-dom";

const ProfileTemplateContainer = ({onSave, settings, sorted, images, deleteProfileTemplate, activePlan}) => {
    const {templateId} = useParams()
    const navigate = useNavigate()

    const [templateName, setTemplateName] = useState("")

    useEffect(() => {
        setTemplateName(settings.find(template => template.id === templateId)?.name)
    }, [settings, templateId])

    const whenSaved = (map, id, goToPlan = false) => {
        onSave(map, id).then(result => {
            if (result)
                if (goToPlan)
                    navigate("/settings?plan=true")
                else
                    navigate("/templates/profile")
        })
    }

    const deleteTemplate = () => {
        deleteProfileTemplate(templateId).then((res) => {
            if (res) navigate("/templates/profile")
        })
    }

    return (
        <>
            <TemplatesHeader title={<Trans>Profilvorlage</Trans>}
                             desc={<Trans>Gestalten Sie das Profildesign für Ihre Mitarbeiter:innen. Achten Sie auf die
                                 Unterscheidung zwischen Master-Accounts, die für alle Mitarbeiter:innen gleich sind und
                                 individuelle Accounts.</Trans>}
                             templateName={templateName}
                             setTemplateName={setTemplateName}
                             deleteTemplate={deleteTemplate}

            />
            <ProfileTemplate onSave={whenSaved} settings={settings} sorted={sorted} images={images}
                             templateId={templateId} name={templateName} isSettings={true}
                             activePlan={activePlan}
            />
        </>
    );
};

export default ProfileTemplateContainer;