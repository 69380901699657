import {useEffect, useMemo, useState} from 'react';
import {startStripePlanListener} from "../functions/StripePlans";

const useSubscriptionState = (team) => {
    const [plans, setPlans] = useState(null);

    useEffect(() => {
        startStripePlanListener(setPlans)
    }, [])

    const activePlanName = useMemo(() => {
        const name = plans?.find(plan => plan.id === team?.plan)?.name
        if (name) {
            return name
        } else {
            return "Base-Plan"
        }
    }, [plans, team?.plan])

    return [plans, activePlanName]
};

export default useSubscriptionState;