import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import {en, de} from 'make-plural/plurals'
import { messages as enMessages } from './locales/en/messages'
import { messages as csMessages } from './locales/de/messages'

i18n.loadLocaleData({
    en: { plurals: en },
    de: { plurals: de },
})

i18n.load({
    en: enMessages,
    de: csMessages,
})
i18n.activate(localStorage.getItem("language") ?? "de")

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
);