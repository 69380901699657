import {t, Trans} from "@lingui/macro";
import WorkerTemplate from "./WorkerTemplate";

const OrderWorker = ({worker, onDelete}) => {
    return (
        <WorkerTemplate
            name={worker.name}
            image={worker.profile}
            inlineContent={
                <>
                    <p style={{width: "12rem"}}>{worker.description ? worker.description : t`Keine Beschreibung`}</p>
                    <p style={{width: "12rem"}}>
                        {worker.templateName}
                    </p>
                </>
            }
            buttons={<div className="rowSelector" style={{padding: "1rem"}}>
                <button onClick={() => onDelete(worker.id)} className="btn whiteButton"
                        style={{color: "#E84855"}}>
                    <Trans>Entfernen</Trans>
                </button>
            </div>}
        />
    );
};

export default OrderWorker;
