import QRCode from "../../../img/cardGenerationAssets/qrcode.png";
import whiteRec from "../../../img/cardGenerationAssets/whiteRec.svg"
import yourLogo from "../../../img/cardGenerationAssets/Yourlogo.png";
import wood from "../../../img/cardGenerationAssets/woodBackgroundWeb.png";
import bumpee from "../../../img/cardGenerationAssets/bumpeeLogo.png";
import nfc from "../../../img/cardGenerationAssets/nfc.png";

let woodBackground;
export const companyCard = async (name, desc, numSize, primary, secondary, roundCorners, image, settings, inverted = false, alignment = "right aligned", noLogoFront = false, noLogoBack = false, noText = false, whiteLabel = false, material = "plastic") => {
    let size

    switch (numSize) {
        case "Small":
            size = 600
            break
        case "Large":
            size = 1500
            break
        default:
            size = 1002
            break
    }

    size /= 2;

    const logo = await loadImage(image ?? yourLogo);

    if (material?.toLowerCase().includes("wood")) {
        primary = undefined
        secondary = undefined
    }

    if (!alignment) alignment = "right aligned";

    if (settings) {
        return Promise.all([
            frontCard(name, desc, size, primary, secondary, roundCorners, inverted, alignment, noLogoFront, noText, logo),
            backCard(primary, secondary, roundCorners, inverted, whiteLabel, noLogoBack, logo)
        ])
    } else return await frontCard(name, desc, size, primary, secondary, roundCorners, inverted, alignment, noLogoFront, noText, logo);
}

const frontCard = async (name,
                         desc,
                         size,
                         primary,
                         secondary = "#442F21",
                         roundCorners,
                         inverted = false,
                         alignment,
                         noLogo,
                         noText,
                         image) => {
    //create canvas
    const canvas = document.createElement('canvas');
    canvas.width = 2022;
    canvas.height = 1275;
    const ctx = canvas.getContext('2d')
    ctx.globalAlpha = 1;
    if (primary) {
        ctx.fillStyle = primary;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    } else {
        ctx.drawImage(woodBackground ?? await getWoodBackground(), 0, 0, 2022, 1275);
    }

    const nfc_element = await loadSVG(nfc, secondary);
    ctx.drawImage(nfc_element, 3576 / 2, 208 / 2, 190 / 2, 260 / 2);

    ctx.fillStyle = secondary;
    let yTextPos = 0;
    if (!noLogo) {
        if (!primary) image = await alterImage(image, inverted);
        const format = defineLogoSize(image, size, noText);
        yTextPos = format.yTextPosition;
        drawLogo(ctx, image, size, roundCorners, alignment, format.width, format.height, yTextPos);
    }

    if (!noText) {
        drawText(ctx, name, desc, alignment, yTextPos);
    }

    return canvas.toDataURL('png');
}

const backCard = async (primary, secondary = "#442F21", roundCorners, inverted = false, whiteLabel, noLogo, logo) => {
    const canvas = document.createElement('canvas');
    canvas.width = 4044 / 2;
    canvas.height = 2550 / 2;
    const ctx = canvas.getContext('2d');
    ctx.globalAlpha = 1;

    if (primary) {
        ctx.fillStyle = primary;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    } else {
        ctx.drawImage(woodBackground ?? await getWoodBackground(), 0, 0, 4044 / 2, 2550 / 2);
    }

    ctx.fillStyle = secondary;

    if (!whiteLabel) {
        //OG Font Size 249px
        ctx.font = "600 125px 'Poppins', serif";
        ctx.textAlign = "left";
        let xPosText = 1383;
        if (!primary) xPosText = 1263;
        ctx.fillText('Upgrade your', xPosText / 2, 1766 / 2);
        ctx.fillText('networking', xPosText / 2, 2066 / 2)
    }

    const images = await Promise.all([
        loadSVG(bumpee, secondary),
        loadImage(whiteRec),
        loadSVG(QRCode, primary ? "#000000" : secondary)
    ])

    let qrCodePositionX = 237;
    let qrCodePositionY = 1356;

    if (whiteLabel) {
        qrCodePositionY = 2550 / 2 - 1002 / 2;
        if (noLogo) {
            qrCodePositionX = 4044 / 2 - 1002 / 2;
        } else {
            const drawingLogo = primary ? logo : await alterImage(logo, inverted);
            const format = defineLogoSize(drawingLogo, 1500, undefined, true);
            qrCodePositionX = format.xTextPosition;

            const xPos = format.xTextPosition + 1002 + 200;
            const yPos = 2550 / 2 - format.height / 2;
            if (roundCorners) {
                ctx.save();
                roundedImage(ctx, xPos / 2, yPos / 2, format.width / 2, format.height / 2);
                ctx.clip();
                ctx.drawImage(drawingLogo, xPos / 2, yPos / 2, format.width / 2, format.height / 2);
                ctx.restore();
            } else {
                ctx.drawImage(drawingLogo, xPos / 2, yPos / 2, format.width / 2, format.height / 2);
            }
        }
    }

    if (!whiteLabel) ctx.drawImage(images[0], 2806 / 2, 209 / 2, 1000 / 2, 284 / 2);
    if (primary) ctx.drawImage(images[1], qrCodePositionX / 2, qrCodePositionY / 2, 1002 / 2, 1002 / 2);
    ctx.drawImage(images[2], (qrCodePositionX + 120) / 2, (qrCodePositionY + 120) / 2, 762 / 2, 762 / 2);

    return canvas.toDataURL('png');
}

const getWoodBackground = () => {
    return new Promise(resolve => {
        const woodImage = new Image();
        woodImage.crossOrigin = "anonymous";
        woodImage.src = wood;
        woodImage.onload = () => {
            woodBackground = woodImage;
            resolve(woodImage);
        }
    })
}

function roundedImage(ctx, x, y, width, height) {
    const radius = width * 0.1;

    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
}

const defineLogoSize = (logo, size, noText, forBack = false) => {
    let width = logo.width;
    let height = logo.height;
    if (height < width) {
        height = (height * size) / width;
        width = size;
    } else {
        width = (width * size) / height;
        height = size;
    }

    if (forBack) {
        if (height > 1002) {
            width = (width * 1002) / height;
            height = 1002;
        }

        const wholeWidth = 1002 + 200 + width;
        const offset = (4044 - wholeWidth) / 2;

        return {width, height, "xTextPosition": offset}
    } else {
        let wholeHeight = height;
        wholeHeight = wholeHeight * 2;
        if (!noText) wholeHeight += 226 + 195;
        const offset = (2550 - wholeHeight) / 2;

        return {width, height, "yTextPosition": offset + height * 2}
    }
}

const loadImage = (image) => {
    return new Promise((resolve) => {
        const loadedImage = new Image();
        loadedImage.crossOrigin = "anonymous";
        loadedImage.src = image;
        loadedImage.onload = () => {
            resolve(loadedImage)
        }
    })
}

const drawLogo = (ctx, logo, size, roundCorners, alignment, width, height, yTextPosition) => {
    let yPos = 209;
    let xPos = 218;
    if (alignment === "centered") {
        yPos = yTextPosition - height * 2;
        xPos = 2022 - width;
    }

    if (roundCorners) {
        ctx.save();
        roundedImage(ctx, xPos / 2, yPos / 2, width, height);
        ctx.clip();
        ctx.drawImage(logo, xPos / 2, yPos / 2, width, height);
        ctx.restore();
    } else {
        ctx.drawImage(logo, xPos / 2, yPos / 2, width, height);
    }
}

const drawText = (ctx, name, desc, alignment, yTextPosition) => {
    let xPos = 3766;
    let yPos = 2112;

    switch (alignment) {
        case "left aligned":
        case "linksbündig":
            ctx.textAlign = "left";
            xPos = 218;
            break;
        case "centered":
        case "zentriert":
            ctx.textAlign = "center";
            xPos = 2022;
            if (yTextPosition > 0) {
                yPos = yTextPosition + 226 + 195 / 2;
            } else {
                yPos = 1275;
                if (!desc) yPos += 226 / 2;
            }

            break;
        case "right aligned":
        case "rechtsbündig":
        default:
            ctx.textAlign = "right";
            break;
    }

    ctx.font = "600 113px 'Poppins', sans-serif";
    adjustFontSize(name, ctx, 1750, 113);
    ctx.fillText(name, xPos / 2, yPos / 2);
    ctx.font = "65px 'Poppins', sans-serif";
    ctx.fillText(desc, xPos / 2, (yPos + 195) / 2);
}

const adjustFontSize = (str, ctx, width, current) => {
    let size = ctx.measureText(str).width;
    const desired_width = width;
    let nameSize = current;

    while (size > desired_width) {
        nameSize -= 1
        ctx.font = "600 " + nameSize + "px 'Poppins', sans-serif";
        size = ctx.measureText(str).width
    }
}

const loadSVG = (svg, color) => {
    return new Promise(resolve => {
        const rgb = hexToRgb(color);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = svg;
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < imgData.data.length; i += 4) {
                const sum = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
                if (sum === 765) {
                    imgData.data[i + 3] = 0;
                }
                if (imgData.data[i + 3] > 0) {
                    imgData.data[i] = rgb[0];
                    imgData.data[i + 1] = rgb[1];
                    imgData.data[i + 2] = rgb[2];
                }
            }

            ctx.putImageData(imgData, 0, 0);

            resolve(canvas);
        }
    })
}

const hexToRgb = (hex) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return [r, g, b];
};


const alterImage = (uploadedLogo, inverted) => {
    return new Promise(resolve => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = uploadedLogo.width;
        canvas.height = uploadedLogo.height;
        ctx.drawImage(uploadedLogo, 0, 0);
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let lowestColour = inverted ? 0 : 765;
        for (let i = 0; i < imgData.data.length; i += 4) {
            if (imgData.data[i + 3] === 0) continue;
            const count = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
            if (inverted) {
                if (count > lowestColour) lowestColour = count;
                continue;
            }

            if (count < lowestColour) lowestColour = count;
        }

        for (let i = 0; i < imgData.data.length; i += 4) {
            let count = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
            let opacity = 255;

            if (inverted) {
                if (imgData.data[i + 3] !== 0 && count < 765 - 100) opacity = 0;
            } else {
                if (imgData.data[i + 3] === 0) opacity = 0;
                if (count > 765 - 100 && lowestColour < 765 - 100) opacity = 0;
            }

            //68,47,33
            imgData.data[i] = 68;
            imgData.data[i + 1] = 47;
            imgData.data[i + 2] = 33;
            imgData.data[i + 3] = opacity;
        }
        ctx.putImageData(imgData, 0, 0);
        const grey_logo = new Image();
        grey_logo.crossOrigin = "anonymous";
        grey_logo.src = canvas.toDataURL('image/png');
        grey_logo.onload = () => {
            canvas.remove();
            resolve(grey_logo);
        }
    })
}