import React from 'react';
import avatar from "../../img/avatar.png";
import {Trans} from "@lingui/macro";
import {Check, Edit, Eye} from "react-feather";

const ProfileRowDisplay = ({worker, selectionMode, addSelection, selected, editEmployee, preview}) => {
    return (
        <div className="profile-container profile-row-container">
            <div className="profile-information profile-row-information">
                {
                    selectionMode || preview ?
                        <button onClick={() => addSelection(worker)}
                                className={"selection-ball" + (selected ? " selected" : "")}
                        >
                            <Check/>
                        </button> : <></>
                }
                <img className="skeleton" src={worker.profile ?? avatar} alt={"Profile Picture of " + worker.name}/>
                <div>
                    <h2>{worker.name}</h2>
                    <p style={{minHeight: "21px"}}>{worker.description}</p>
                </div>
            </div>
            <div className="profile-buttons">
                <a className={"btn whiteButton"} target="_blank" rel="noreferrer"
                   href={"https://bumpee.de/" + worker.tag[0]}>
                    <Eye stroke={"#000"}/>
                    <Trans>Vorschau</Trans>
                </a>
                {
                    selectionMode || preview ?
                        <button className={"btn" + (!selected ? " selectBtn": "")}
                                onClick={() => addSelection(worker)}
                        >
                            {
                                !selected ?
                                    <Trans>Auswählen</Trans>
                                    :
                                    <Trans>Ausgewählt</Trans>
                            }
                        </button>
                        :
                        <button className="btn"
                                onClick={() => {
                                    editEmployee(worker.id)
                                }}
                        >
                            <Edit stroke={"#fff"}/>
                            <Trans>Bearbeiten</Trans>
                        </button>
                }
            </div>
        </div>
    );
};

export default ProfileRowDisplay;