import {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./sidebar.css"
import {ReactComponent as Bumpee} from "../../img/bumpee-logo.svg"
import {auth} from "../../firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import LanguageToggle from "./LanguageToggle";
import {Trans} from "@lingui/macro";
import {ReactComponent as CardIcon} from "../../img/card.svg";
import UpdateField from "../Widgets/UpdateField";
import OnBoardingTour from "./OnBoardingTour";
import {Layers, Settings, ShoppingCart, User, Users} from "react-feather";
import CurrentPlanButton from "./CurrentPlanButton";

const sidebarNavItems = [
    {
        display: <Trans>Profile</Trans>,
        icon: <Users/>,
        to: '/',
        section: '',
        id: "profile",
    },
    {
        display: <Trans>Vorlagen</Trans>,
        icon: <Layers/>,
        to: '/templates',
        section: 'templates',
        id: "templates",
        submodules: [
            {
                display: <Trans>Karte</Trans>,
                icon: <CardIcon/>,
                to: "/templates/card",
                section: "card",
                id: "card",
            },
            {
                display: <Trans>Profil</Trans>,
                icon: <User/>,
                to: "/templates/profile",
                section: "profile"
            }
        ]
    },
    {
        display: <Trans>Bestellen</Trans>,
        icon: <ShoppingCart/>,
        to: '/ordering',
        section: 'ordering',
        id: "ordering",
    },
    {
        display: <Trans>Einstellungen</Trans>,
        icon: <Settings/>,
        to: "/settings",
        section: "settings",
        id: "settings",
    }
]

const SidebarItem = ({item, index, activeIndex, submodule}) => {
    return <Link to={item.to}
                 style={submodule ? {marginLeft: "2rem"} : {}}
    >
        <div className={`sidebar__menu__item ${activeIndex === index ? "active" : ""}`} id={item.id}>
            <div className="sidebar__menu__item__icon">
                {item.icon}
            </div>
            <div className="sidebar__menu__item__text">
                {item.display}
            </div>
        </div>
    </Link>;
}

const SubSideItems = ({location, submodule}) => {
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const curPath = location.pathname.split('/');
        if (curPath.length > 2) {
            const activeItem = submodule.findIndex(item => item.section === curPath[2]);
            setActiveIndex(activeItem);
        } else setActiveIndex(-1)
    }, [location, submodule]);

    return (
        submodule.map((sub, index) => {
            return <SidebarItem key={"subElement" + index} item={sub} activeIndex={activeIndex} index={index}
                                submodule={true}/>
        })
    )
}

const Sidebar = ({waiting, firstLogin, companyName, activePlan}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return
        if (!user && location.pathname !== "/terms-of-service") {
            return navigate("/login");
        }
    }, [user, loading, waiting, navigate, firstLogin]);
    // change active index
    useEffect(() => {
        const curPath = location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location.pathname]);

    useEffect(() => {
        if (firstLogin) navigate("/welcome")
    }, [firstLogin])

    return (
        <div className="sidebar">
            <OnBoardingTour waiting={waiting}/>
            <div className="title_sidebar">
                <div className="sidebar__logo">
                    <div className="beta_btn">Beta</div>
                    <Bumpee style={{width: "150px"}} fill={"#fffffe"}/>
                </div>
                <CurrentPlanButton activePlan={activePlan} />
                <p>{companyName}</p>
                <UpdateField waiting={waiting}/>
            </div>
            <div className="sidebar__menu">
                {
                    sidebarNavItems.map((item, index) => {
                        if (item.submodules) {
                            return <>
                                <SidebarItem key={"parent" + item.section + index} item={item} activeIndex={activeIndex}
                                             index={index}
                                             submodule={false}/>
                                {
                                    index === activeIndex &&
                                    <SubSideItems key={"sub" + item.section + index} submodule={item.submodules}
                                                  location={location}/>
                                }
                            </>
                        } else {
                            return <SidebarItem key={"item" + item.section + index} item={item}
                                                activeIndex={activeIndex}
                                                index={index}
                                                submodule={false}/>
                        }
                    })
                }
            </div>
            <div className="footer">
                <LanguageToggle/>
                <div style={{display: "flex", gap: "0.5rem", justifyContent: "center", flexWrap: "wrap"}}>
                    <Link to={"/terms-of-service"}><Trans>AGBs</Trans></Link>
                    <a href="https://www.bumpee.de/policies/legal-notice" target="_blank"
                       rel="noreferrer"><Trans>Impressum</Trans></a>
                </div>
                <p>© 2022, bumpee®</p>
            </div>
        </div>
    );
};

export default Sidebar;