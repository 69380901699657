import React, {useState, useEffect} from 'react';
import ProfileDisplay from "../ProfileOverview/ProfileDisplay";
import {Link, useNavigate, useParams} from "react-router-dom";
import SkeletonOverview from "../ProfileOverview/SkeletonOverview";
import {t, Trans} from "@lingui/macro";

const ProfileSelection = ({
                              employees,
                              addOrderWorkers,
                              settings,
                              profilesLoaded
                          }) => {

    const [selection, setSelection] = useState([])
    const navigate = useNavigate()
    const {templateId} = useParams()
    const [template, setTemplate] = useState()

    useEffect(() => {
        if (settings && settings.length > 0) {
            const setting = settings.find(setting => templateId === setting.id)
            if (!setting) {
                navigate("/")
                return
            }

            setTemplate(setting)
        }
    }, [settings])

    const addSelection = (worker) => {
        setSelection(prevState => {
            if (prevState.some(selection => selection.id === worker.id)) {
                return prevState.filter(selection => selection.id !== worker.id)
            }

            return [...prevState, worker]
        })
    }

    const addSelected = () => {
        if (selection.length > 0) addOrderWorkers(selection, template)
        navigate("/ordering")
    }

    const selectAll = () => {
        if (selection.length === employees.length) setSelection([])
        else setSelection(employees)
    }

    return (
        <>
            <header className="header">
                <div>
                    <h1><Trans>Profile auswählen</Trans></h1>
                    {
                        template && <p style={{fontSize: "1rem", fontWeight: 500}}>
                            {t`Vorlage: ` + template.name}
                        </p>
                    }
                    <p style={{fontSize: "1rem"}}><Trans>Wählen Sie die Profile aus, für die Sie eine Smart Business
                        Card mit der
                        ausgewählten Vorlage bestellen möchten.</Trans></p>
                </div>
            </header>
            <div className="profiles">
                {
                    profilesLoaded ?
                        employees.length > 0 ?
                            employees.map(worker => (
                                <ProfileDisplay worker={worker}
                                                addSelection={addSelection}
                                                selected={selection.some(selection => selection.id === worker.id)}
                                                key={worker.id}
                                                preview={true}
                                />
                            ))
                            :
                            <div className="centerMsg" style={{marginInline: "auto"}}>
                                <p style={{marginBottom: "1rem"}}><Trans>Erstellen Sie zunächst Profile für Ihre
                                    Mitarbeiter:innen</Trans></p>
                                <Link to="/">
                                    <button className="btn"><Trans>Zur Profilübersicht</Trans></button>
                                </Link>
                            </div>
                        :
                        <SkeletonOverview/>
                }
            </div>

            <div className="selector-options">
                {
                    selection.length > 0 ?
                        <p style={{marginBlock: "auto"}}>{selection.length} <Trans>Profile ausgewählt</Trans></p>
                        :
                        <></>
                }
                <button className="btn whiteButton second-level-btn" onClick={selectAll}>
                    {
                        selection.length !== employees.length ?
                            <Trans>Alle auswählen</Trans>
                            :
                            <Trans>Zurücksetzen</Trans>
                    }
                </button>
                {/*<Link to="/overview">*/}
                {/*    <button className="btn" style={{backgroundColor: "#078080"}}>*/}
                {/*        {selected.length > 0 ? selected.length : ""} {selected.length === 1 ? "Karte" : "Karten"} bestellen*/}
                {/*    </button>*/}
                {/*</Link>*/}
                <button className="btn"
                        onClick={addSelected}
                >
                    {
                        selection.length > 0 ? <Trans>Zum Warenkorb hinzufügen</Trans> :
                            <Trans>Zum Warenkorb zurück</Trans>
                    }
                </button>
            </div>
        </>
    );
};

export default ProfileSelection;