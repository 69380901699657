import React from 'react';
import {Link} from "react-router-dom";
import PreviewIcon from "../ProfileTemplate/PreviewIcon";
import avatar from "../../../img/avatar.png";
import {ReactComponent as Logo} from "../../../img/bumpee-logo.svg";
import {Trans} from "@lingui/macro";
import {Edit} from "react-feather";

const ProfilePreviewTemplate = ({setting, images, selection}) => {
    const bigButtons = setting.accounts
        .filter(account => account.type === 1)
    return (
        <div className="card_template_preview">
            <p style={{marginBottom: "0.5rem", fontSize: "18px"}}>{setting.name ?? 404}</p>
            <div className="preview_container preview_profile_thumbnail">
                <div className="brandidentity">
                    <div className="logo">
                        {
                            setting.webLogo ?
                                <img alt="logo preview" className={"logo-company"} src={setting.webLogo}/>
                                :
                                <Logo fill={setting.color}/>
                        }
                    </div>
                </div>
                {
                    setting.background && <div className="profile-back">
                        <img alt="background preview"
                             className="back-image"
                             src={setting.background}/>
                    </div>
                }
                <div className="profile-avatar" style={!setting.background ? {height: "fit-content"} : {}}>
                    <img alt="profile preview" className="profile-image round"
                         style={!setting.background ? {transform: "none"} : {}}
                         src={avatar}/>
                </div>
                <div className="user">
                    <div className="username">
                        <h1 style={{color: setting.color}}>
                            <Trans>Mitarbeitername</Trans>
                        </h1>
                    </div>
                    <div className="userinfo">
                        <h2 style={{color: setting.color}}>
                            <Trans>Beschreibung</Trans>
                        </h2>
                    </div>
                </div>
                <ul className="account-list">
                    {setting.accounts.map(account => (
                        account.type === 0 &&
                        <PreviewIcon color={setting.color} account={account} key={account.id}
                                     icon={images.find((image) => image.includes(account.img.split(".png")[0]))}/>
                    ))}
                </ul>
                <div className="additional-accounts" style={{paddingBottom: 0, marginBottom: 0}}>
                    {
                        (!setting.background && bigButtons.length <= 2) || bigButtons.length <= 1
                            ?
                            bigButtons.map(account =>
                                <a key={account.id} className="bigButton" href={account.info.urlValue}
                                   target="_blank"
                                   style={{backgroundColor: setting.color, minHeight: "45px"}}
                                   rel="noreferrer"
                                >
                                    {account.info.descValue !== "" ? account.info.descValue : "Beschreibung"}
                                </a>
                            )
                            :
                            <div className="bigButton"
                                 style={{backgroundColor: setting.color, minHeight: "45px"}}
                            >
                                +{bigButtons.length} Links
                            </div>
                    }
                </div>
            </div>
            <Link to={(selection ? "/newprofiles/" : "/templates/profile/") + setting.id}>
                <button className="btn"
                >
                    {
                        selection ?
                            <Trans>Auswählen</Trans>
                            :
                            <>
                                <Edit stroke={"#ffffff"}/>
                                <Trans>Bearbeiten</Trans>
                            </>
                    }
                </button>
            </Link>
        </div>
    )
};

export default ProfilePreviewTemplate;