import Workers from "./Workers";
import 'reactjs-popup/dist/index.css';
import PopupOrder from "../DeprecatedOrdering/PopupOrder";
import PricingContainer from "./PricingContainer";
import "./overview.css"
import React, {useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Popup from "reactjs-popup";
import CardTemplatePreview from "../Templates/Container/CardTemplatePreview";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import NewTemplate from "../Templates/Container/NewTemplate";
import {Trans} from "@lingui/macro";
import {PlusCircle, X} from "react-feather";

const Overview = ({
                      onBuy,
                      checkout,
                      setCheckout,
                      cardSettings,
                      cardWorkers,
                      setCardWorkers,
                      createTemplate
                  }) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const deleteWorker = (id) => {
        const newArray = cardWorkers.filter((worker) => worker.id !== id)
        setCardWorkers(newArray)
        localStorage.setItem("newOrderStorage", JSON.stringify(newArray))
    }

    const newTemplate = (name) => {
        createTemplate(name, true).then(ref => navigate("/templates/card/" + ref.id))
    }

    const addProfileRef = useRef()

    return (
        <div>
            <header className="header">
                <div>
                    <h1><Trans>Bestellen</Trans></h1>
                    <p style={{fontSize: "1rem"}}><Trans>Kaufen Sie Smart Business Cards für die ausgewählten Account</Trans></p>
                </div>
                <div className="options-navigation">
                    <button className="btn" onClick={() => addProfileRef.current.open()} id={"orderingAdd"}>
                        <PlusCircle stroke={"#fff"}/>
                        <Trans>Profile hinzufügen</Trans>
                    </button>
                </div>
            </header>

            <Popup
                nested
                contentStyle={{maxWidth: "80vw", width: "inherit", minWidth: "600px"}}
                ref={addProfileRef}
            >
                <div className="popup">
                    <button onClick={() => addProfileRef.current.close()} className="btn greyButton closePopupButton">
                        <X stroke={"#0a0a0a"}/>
                    </button>
                    <div>
                        <h1><Trans>Kartenvorlage</Trans></h1>
                        <p><Trans>Wählen Sie die passende Kartenvorlage für die Profile aus.</Trans></p>
                    </div>
                    <div className="select_template">
                        {
                            cardSettings.length > 0 ?
                                <Splide
                                    options={{
                                        rewind: true,
                                        pagination: true
                                    }}
                                >
                                    {
                                        cardSettings.map((setting, index) =>
                                            <SplideSlide key={setting.id} style={{paddingBottom: "2rem"}}>
                                                <CardTemplatePreview setting={setting} key={index} selectionMode={true}/>
                                            </SplideSlide>
                                        )
                                    }
                                </Splide>
                                :
                                <NewTemplate createTemplate={newTemplate} isCard={true} isPopup={true}/>
                        }

                    </div>
                    <Link to="/templates/card" style={{marginBottom: "0"}}>
                        <button className="btn whiteButton" style={{marginInline: "auto"}}><Trans>Kartenvorlagen bearbeiten</Trans></button>
                    </Link>
                </div>
            </Popup>
            <Workers
                workers={cardWorkers}
                onDelete={deleteWorker}
                addRef={addProfileRef}
            />
            {cardWorkers[0] &&
                <div className="selector-options">
                    <PricingContainer workers={cardWorkers} templates={cardSettings}/>
                    <PopupOrder
                        onBuy={onBuy}
                        checkout={checkout}
                        setCheckout={setCheckout}
                        workers={cardWorkers}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </div>
            }
        </div>
    );
};

export default Overview;