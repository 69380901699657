import React from 'react';
import "./Switch.css"

const Switch = ({checked, handleToggle}) => {
    return (
        <>
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={handleToggle}/>
                <span className="slider roundToggle"></span>
            </label>
        </>
    );
};

export default Switch;