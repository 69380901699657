import GoogleMapsInput from "../Widgets/GoogleMapsInput";
import React, {useRef} from "react";

const Account = ({account, onChange, type, icon}) => {
    //const valueRef = useRef(account.info.urlValue)

    const updateURLValue = (val) => {
        if (!val) return ""
        const prefix = type.prefix
        return prefix && val.substring(0, 4) !== "http" && val.substring(0, prefix.length) !== prefix ? prefix + val : val
    }

    return (
        <div
            className="account-form-container form-control"
            style={{marginBlock: "10px"}}>
            <div className="account-item">
                <img src={icon} alt="Account type icon"/>
            </div>
            {
                account.name !== "Address" ?
                    <input type="text"
                           placeholder={type.hint}
                           value={account.info.urlValue}
                           //ref={valueRef}
                           onChange={(e) => {
                               account.info.urlValue = updateURLValue(e.target.value)
                               onChange(account)
                           }}
                    />
                    :
                    <GoogleMapsInput updateAccount={onChange} account={account}/>
            }
        </div>
    );
};

export default Account;
