import React, {useRef, useState} from 'react';
import NewWorker from "../NewWorker/NewWorker";
import 'react-dropdown/style.css';
import AreYouSure from "../Widgets/AreYouSure";
import Popup from "reactjs-popup";
import {t, Trans} from "@lingui/macro";
import EditSettings from "./EditSettings";
import PopupFrame from "../Widgets/PopupFrame";
import {Settings, X} from "react-feather";

const EditPopUp = ({
                       editWorker,
                       setEditWorker,
                       accountSettings,
                       sorted,
                       images,
                       updateEmployee,
                       deleteEditWorker,
                       closePopup,
                       inviteWorker
                   }) => {
    const [select, setSelect] = useState(accountSettings.find(setting => setting.id === editWorker.template)?.name)

    const options = accountSettings
        .filter(setting => setting.name)
        .map(setting => setting.name)

    // useEffect(() => {
    //     const template = accountSettings.find(setting => setting.name === select.value)
    //     const updatedWorker = editWorker
    //     if (template) {
    //         updatedWorker.template = template.id
    //         updatedWorker.color = template.color
    //         updatedWorker.background = template.background
    //     }
    //     setEditWorker(updatedWorker)
    // }, [select])
    const deleteAccountRef = useRef()
    const editSettingsRef = useRef()

    return (
        <div className="popup editPopup" style={{paddingBlock: 0}}>
            <Popup
                ref={deleteAccountRef}
                modal
                contentStyle={{width: "inherit"}}
            >
                <AreYouSure closePopup={() => deleteAccountRef.current.close()}
                            description={t`Das Löschen eines Profils lässt sich nicht rückgängig machen.`}
                            sure={async () => await deleteEditWorker(editWorker)}
                            action={t`Löschen`}/>
            </Popup>
            <button onClick={closePopup} className="btn greyButton closePopupButton">
                <X stroke={"#0a0a0a"}/>
            </button>
            <h1><Trans>Profil bearbeiten</Trans></h1>
            <p>
                <Trans>Wenn man die Vorlage eines Profils wechselst, werden auch alle vorlagenspezifischen Accounts
                    entfernt. Falls Sie nicht zufrieden mit den Änderungen sind, einfach Abbrechen wählen.</Trans>
            </p>
            <Popup
                trigger={
                    <button className="btn greyButton optionButton" style={{
                        position: "absolute",
                        right: "1rem",
                        top: "1rem"
                    }}>
                        <Settings/>
                        <Trans>Einstellungen</Trans>
                    </button>
                }
                modal
                ref={editSettingsRef}
            >
                <PopupFrame content={
                    <EditSettings inviteWorker={inviteWorker} id={editWorker.id} setSelect={setSelect}
                                  options={options} select={select}
                                  deleteWorker={() => deleteAccountRef.current.open()}
                                  close={() => editSettingsRef.current.close()}
                                  previewLink={"https://bumpee.de/" + editWorker.tag[0]}
                    />
                }
                            close={() => editSettingsRef.current.close()}
                            title={t`Profileinstellungen`}
                            description={t`Hier können Sie die Einstellungen des Profils ändern.`}
                />
            </Popup>
            <NewWorker
                settings={accountSettings}
                editWorker={editWorker}
                setEditWorker={setEditWorker}
                select={select}
                sorted={sorted}
                images={images}
                form={false}
                updateEmployee={updateEmployee}
                deleteWorker={() => closePopup()}
            />
        </div>
    );
};

export default EditPopUp;