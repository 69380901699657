import React from 'react';
import AccountForm from "./AccountForm";

const SortingAccount = ({account, icons, sorted, onDelete, updateAccount, errors}) => {
    return (
        <li>
            <AccountForm account={account}
                         icon={icons.find((image) => image.includes(account.img.split(".png")[0]))}
                         key={account.id}
                         type={sorted.find(type => (
                                 type.name.includes(account.name)
                             )
                         )}
                         onDelete={onDelete}
                         updateAccount={updateAccount}
                         error={errors[account.id]}
            />
        </li>
    );
};

export default SortingAccount;