import React, {useMemo, useState} from 'react';
import WorkerTemplate from "../../OrderOverview/WorkerTemplate";
import {Plural, Trans} from "@lingui/macro";

import CodeRow from "./CodeRow";
import UsedIndicator from "./UsedIndicator";

const InvitationWorker = ({worker, deleteCode}) => {
    const [showCodes, setShowCodes] = useState(false)

    const calculateDaysLeft = (date) => {
        const mostRecentDate = new Date(date.seconds * 1000)
        return 30 - Math.ceil((new Date() - mostRecentDate) / (1000 * 60 * 60 * 24))
    }

    const days = useMemo(() => {
        if (worker.codes?.length > 0) {
            const mostRecent = worker.codes.reduce((a, b) => a.seconds < b.seconds ? a : b)
            //convert the mostRecent seconds value to a Date()
            return calculateDaysLeft(mostRecent.date)
        }
        return 0
    }, [worker.codes])

    return (
        <div>
            <WorkerTemplate
                image={worker.image}
                name={worker.name}
                buttons={
                    <div className="rowSelector" style={{paddingBlock: "1rem", gap: "3rem"}}>
                        {
                            !worker.user ?
                                <p>
                                    <Plural value={days}
                                            one="Noch ein Tag gültig"
                                            other="Noch # Tage gültig"
                                    />
                                </p>
                                :
                                <p>
                                    {
                                        worker.connectedOnDate &&
                                        <Trans>
                                            Verbunden am {worker.connectedOnDate?.toDate().toLocaleDateString()}
                                        </Trans>
                                    }
                                </p>
                        }
                        <UsedIndicator used={worker.user}/>
                        {
                            !worker.user &&
                            <button className="btn whiteButton" onClick={() => setShowCodes(prevState => !prevState)}>
                                {!showCodes ? <Trans>Codes anzeigen</Trans> : <Trans>Ausblenden</Trans>}
                            </button>
                        }
                    </div>
                }
                collapsibleContent={
                    showCodes &&
                    worker.codes?.map(code =>
                        <CodeRow key={code.id} code={code} calculateDaysLeft={calculateDaysLeft}
                                 deleteCode={deleteCode}
                        />
                    )
                }
            />
        </div>
    );
};

export default InvitationWorker;