import { Outlet } from 'react-router-dom';
import Sidebar from "./Sidebar";

const SidebarLayout = ({waiting, firstLogin, companyName, activePlan}) => (
    <>
        <Sidebar waiting={waiting} firstLogin={firstLogin} companyName={companyName} activePlan={activePlan} />
        <div className="container">
            <Outlet />
        </div>
    </>
);

export default SidebarLayout;