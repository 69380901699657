import React, {useState} from 'react';
import {changePassword} from "../../firebase";
import {t, Trans} from "@lingui/macro";
import LoadableButton from "../Widgets/LoadableButton";

const InputPassword = ({placeholder, label, name, error, value, setValue}) => {
    return (
        <div className="form-control">
            <label>{label}</label>
            <input type="password"
                   name={name}
                   placeholder={placeholder}
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
            />
            <p style={{color: "red"}}>{error}</p>
        </div>
    )
}

const ChangePassword = ({mail, close}) => {
    const [waiting, setWaiting] = useState(false)

    const submit = (e) => {
        e.preventDefault()
        setWaiting(true)
        let error = {}

        if (!oldPsw) error["oldPassword"] = t`Bitte ausfüllen.`

        if (!newPsw) error["newPassword"] = t`Bitte ausfüllen.`

        if (newPsw !== newPswAccept) {
            error["newPassword"] = t`Passwort stimmt nicht überein.`
        }

        if (Object.keys(error).length > 0) {
            setErrors(error)
            return
        }

        changePassword(oldPsw, newPsw, setErrors).then((error) => {
            if (error) {
                setErrors(error)
                setWaiting(false)
            } else {
                setWaiting(false)
                close()
            }
        })
    }

    const [oldPsw, setOldPsw] = useState("")
    const [newPsw, setNewPsw] = useState("")
    const [newPswAccept, setNewPswAccept] = useState("")

    const [errors, setErrors] = useState([])


    return (
        <form onSubmit={submit}>
            <input type="hidden" name="username" value={mail}/>
            <InputPassword value={oldPsw} setValue={setOldPsw} placeholder={t`Bisheriges Passwort`}
                           label={t`Bisheriges Passwort`} error={errors["oldPassword"]}/>
            <InputPassword value={newPsw} setValue={setNewPsw} placeholder={t`Neues Passwort`} name="New password"
                           label={t`Neues Passwort`} error={errors["newPassword"]}/>
            <InputPassword value={newPswAccept} setValue={setNewPswAccept}
                           placeholder={t`Neues Passwort bestätigen`} name="New password"
                           label={t`Neues Passwort bestätigen`}/>

            <div className="options-navigation" style={{flexShrink: 0}}>
                <button className="btn greyButton" style={{height: "40px", marginLeft: "1rem"}} type="button"
                        onClick={close}>
                    <Trans>Abbrechen</Trans>
                </button>
                <button className="btn optionButton" type="submit">
                    <LoadableButton waiting={waiting} prompt={
                            <Trans>Aktualisieren</Trans>
                    }/>
                </button>
            </div>
        </form>
    );
};

export default ChangePassword;