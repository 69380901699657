import {auth, db, functions} from "../firebase";
import {collection, onSnapshot, query, where, orderBy, getDocs, addDoc} from "firebase/firestore";
import SubscriptionPlan from "../entities/SubscriptionPlan";
import Customer from "../entities/Customer";
import {httpsCallable} from "firebase/functions";

const startStripePlanListener = (setPlans) => {
    const products_query = query(collection(db, "products"), where("active", "==", true));
    onSnapshot(products_query, (querySnapshot) => {
        const plans = querySnapshot.docs.map(async (doc) => {
            const plan = new SubscriptionPlan(doc.data(), doc.id)

            const price_query = query(collection(doc.ref, "prices"), orderBy("unit_amount"));
            const priceSnapshot = await getDocs(price_query);
            priceSnapshot.forEach((priceDoc) => {
                plan.addPricing(priceDoc.data(), priceDoc.id)
            })

            return plan
        });

        Promise.all(plans).then((plans) => {
            setPlans(plans)
        })
    });
}

const subscribeToPlan = (priceId) => {
    addDoc(collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`), {
        price: priceId,
        allow_promotion_codes: true,
        success_url: window.location.origin + "/settings?plan=success",
        cancel_url: window.location.origin + "/settings?plan=cancelled",
    }).then((docRef) => {
        onSnapshot(docRef, (snap) => {
            const {sessionId, url} = snap.data();
            if (sessionId) {
                window.open(url, "_top")
            }
        })
    })
}

const getCustomersSubscription = (setCustomer) => {
    const customer_query = query(collection(db, "customers", auth.currentUser.uid, "subscriptions"), where("status", "in", ["trialing", "active"]));
    onSnapshot(customer_query, (snapshot) => {
        if (snapshot.docs.length === 0) {
            setCustomer(null)
        } else {
            setCustomer(new Customer(snapshot.docs[0].data()))
        }
    })
}

const openCustomerPortal = async () => {
    const functionsRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink")
    const {data} = await functionsRef({returnUrl: (window.location.origin + "/settings?plan=portal")})
    window.location.assign(data.url)
}

export {
    startStripePlanListener,
    subscribeToPlan,
    getCustomersSubscription,
    openCustomerPortal
}