class CardSettings {
    constructor(map, id, ref) {
        this.id = id
        this.ref = ref
        this.logo = map.logo
        this.round = map.round
        this.inverted = map.inverted ?? false
        this.size = map.size
        this.background = map.background
        this.textColor = map.text_color
        this.alignment = map.alignment
        this.noLogo = map.no_logo
        this.noLogoBack = map.no_logo_back ?? true
        this.noText = map.no_text ?? false
        this.whiteLabel = map.white_label
        this.material = map.material ?? "Recyclable Plastic"
        this.variantId = map.variant_id
        this.pricing = map.pricing
        this.name = map.name ?? "Standard"
    }

    toMap() {
        const map = {
            "round": this.round,
            "inverted": this.inverted,
            "size": this.size,
            "background": this.background,
            "text_color": this.textColor,
            "alignment": this.alignment,
            "no_logo": this.noLogo,
            "no_logo_back": this.noLogoBack,
            "no_text": this.noText,
            "white_label": this.whiteLabel,
            "material": this.material,
            "variant_id": this.variantId,
            "pricing": this.pricing,
            "name": this.name
        };

        if (this.logo) map.logo = this.logo

        return map
    }
}

export default CardSettings;