import React, {useMemo} from 'react';
import {Trans} from "@lingui/macro";

const PreviewTable = ({profiles}) => {
    const previewTable = useMemo(() => {
        if (profiles.length < 5) {
            return profiles
        } else {
            return profiles.slice(0, 5)
        }
    }, [profiles]);

    return (
        <table>
            <tr>
                <th><Trans>Name</Trans></th>
                <th><Trans>Code</Trans></th>
                <th><Trans>Einladungslink</Trans></th>
            </tr>
            {
                previewTable.map(profile =>
                    <tr key={profile.code}>
                        <td>{profile.name}</td>
                        <td>{profile.code}</td>
                        <td>{profile.link}</td>
                    </tr>
                )
            }
            {
                profiles.length > 5 &&
                <tr>
                    <td>...</td>
                    <td>...</td>
                    <td>...</td>
                </tr>
            }
        </table>
    );
};

export default PreviewTable;