import React, {useMemo} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Trans} from "@lingui/macro";
import LoadableButton from "../Widgets/LoadableButton";
import BulletPointBenefit from "./BulletPointBenefit";
import {AtSign, Compass, Layers, User} from "react-feather";

const slides = [
    {
        type: "profile",
        title: <Trans>Beginnen wir mit Ihrem Online-Profildesign</Trans>,
        description: "",
        content: [
            {
                icon: <Layers/>,
                text: <Trans>Gestalten Sie eine Vorlage für alle Online-Profile</Trans>,
                title: <Trans>Die Vorlage für Ihr Team</Trans>
            },
            {
                icon: <AtSign/>,
                text: <Trans>Fügen Sie Accounts hinzu die auf allen Profilen vorhanden sein sollen</Trans>,
                title: <Trans>Mit den wichtigsten Informationen</Trans>
            },
            {
                icon: <User/>,
                text: <Trans>Mit der fertigen Vorlage, können Sie schnell und einfach Profile für Ihre
                    Mitarbeitenden erstellen</Trans>,
                title: <Trans>Für die schnelle Profilerstellung</Trans>
            }
        ]
    },
    {
        type: "card",
        title: <Trans>Ihre Smart Business Card</Trans>,
        description: <Trans>Zum tadellosen Kontaktaustausch</Trans>,
        content: [
            {
                icon: <Layers/>,
                text: <Trans>Mit eigenem Logo und freier Farbwahl</Trans>,
                title: <Trans>Ihre Company Edition</Trans>
            },
            {
                icon: <Compass/>,
                text: <Trans>Gestalten Sie Ihre Smart Business Card nach ihren Richtlinien</Trans>,
                title: <Trans>Mit Ihrer Corporate Identity</Trans>
            },
            {
                icon: <User/>,
                title: <Trans>Für Ihre Online-Profile</Trans>,
                text: <Trans>Wir nehmen die nötigen Informationen für Ihre Karten automatisch aus den bereits erstellten
                    Profilen</Trans>
            }
        ]
    }
]
const WelcomeInfoSlides = ({waiting, createTemplate}) => {
    const {type} = useParams()
    const slide = useMemo(() => slides.find(slide => slide.type === type), [type])

    const navigate = useNavigate();

    const proceedToEditor = async () => {
        const templateRef = await createTemplate("", type !== "profile", true)
        navigate("/welcome/" + (type === "profile" ? "profile/" : "card/") + templateRef.id)
    }

    return (
        <div className="welcome-first-slide">
            <div>
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
            </div>
            <div className="welcome-bullet-points">
                {slide.content.map((bullet, index) => <BulletPointBenefit key={index} icon={bullet.icon}
                                                                          text={bullet.text}
                                                                          title={bullet.title}
                />)}
                <button type="submit" className="btn" onClick={proceedToEditor}
                        style={{width: "fit-content", marginInline: "auto"}}>
                    <LoadableButton waiting={waiting} prompt={<Trans>Weiter</Trans>}/>
                </button>
            </div>
        </div>
    );
};

export default WelcomeInfoSlides;