import React from 'react';
import {Trans} from "@lingui/macro";
import AlignmentSelector from "./AlignmentSelector";
import {Trash2} from "react-feather";
import LogoOptions from "./LogoOptions";

const FrontsideChoice = ({
                             setLogo,
                             errors,
                             setErrors,
                             crop,
                             setCrop,
                             round,
                             setRound,
                             inverted,
                             setInverted,
                             size,
                             setSize,
                             material,
                             setLogoUpdated,
                             alignment,
                             setAlignment,
                             noLogo,
                             noText,
                             setNoText,
                             options,
                             changeVariant
                         }) => {
    const priceDifference = new Intl.NumberFormat('de-DE',
        {style: 'currency', currency: 'EUR', maximumSignificantDigits: 2})
        .format(!!options ? parseInt(options[1]?.price) - parseInt(options[0]?.price) : 0)

    const addLogo = (state) => {
        changeVariant("Logo", state ? options[1].value : options[0].value)
    }

    return (
        <div className="frontside-form">
            <div>
                {
                    noLogo &&
                    <div className="useLogoPrompt">
                        <h2><Trans>Wollen Sie Ihr Logo auf Ihren Karten haben?</Trans></h2>
                        <button type="button" className="btn" onClick={() => addLogo(true)}>
                            <Trans>Logo hinzufügen</Trans>{!!priceDifference && ` (+${priceDifference})`}
                        </button>
                    </div>
                }
                <LogoOptions crop={crop} setCrop={setCrop} round={round} setRound={setRound} inverted={inverted}
                             setInverted={setInverted} size={size} setSize={setSize} material={material}
                             setLogoUpdated={setLogoUpdated} setLogo={setLogo} errors={errors} setErrors={setErrors}
                />
                <button type="button" className="containerTopButton right" onClick={() => addLogo(false)}>
                    <Trash2/>
                </button>
            </div>
            <AlignmentSelector alignment={alignment} setAlignment={setAlignment} noText={noText} setNoText={setNoText}/>
        </div>
    );
};

export default FrontsideChoice;