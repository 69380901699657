import React from 'react';
import {Link} from "react-router-dom";

const CurrentPlanButton = ({activePlan}) => {
    return (
        <Link className="btn whiteButton plan-button" to={"/settings?plan=open"}>
            {activePlan}
        </Link>
    );
};

export default CurrentPlanButton;