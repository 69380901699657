import React from 'react';

const WorkerTemplate = ({image, name, inlineContent, buttons, collapsibleContent}) => {
    return (
        <div className="workerTemplate">
            <div className="worker">
                <div className="worker-information">
                    {
                        <div className="profile-avatar avatar-inline">
                            <img alt="Profile" className="profile-image round profile-inline" src={image}/>
                        </div>
                    }
                    <p style={{width: "12rem"}}>
                        {name}
                    </p>
                    {inlineContent}
                </div>
                {buttons}
            </div>
            {collapsibleContent}
        </div>
    );
};

export default WorkerTemplate;