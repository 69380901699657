import React from 'react';
import "./RadioSelector.css"

const RadioSelector = ({selected, setSelected, options, id, nonTextOptions}) => {
    return (
        <ul className={"filter-switch radio-container" + (nonTextOptions ? " filter-display-container" : "")}>
            {
                options.map((option, index) => {
                    if (!nonTextOptions) option = option.props.id
                    const selectedValue = selected === index
                    return <li className={"filter-switch-item radio-item" + (selectedValue ? " selected" : "")} key={index}>
                        <input type="radio" id={nonTextOptions ? index : option + id} className="sr-only" checked={selectedValue}
                               onChange={() => setSelected(index)}/>
                        <label htmlFor={nonTextOptions ? index : option + id}
                               className={"radio-label radioSelectField" + (selectedValue ? " selected" : "")}
                        >
                            {option}
                        </label>
                    </li>
                })
            }
        </ul>
    );
};

export default RadioSelector;