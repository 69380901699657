import React, {useEffect, useState} from 'react';
import InputText from "../Widgets/InputText";
import {t, Trans} from "@lingui/macro";
import "./welcomePages.css"
import {useNavigate} from "react-router-dom";
import LoadableButton from "../Widgets/LoadableButton";

const WelcomeSheet = ({updateCompanyName, waiting, name, setFirstLogin}) => {
    const [companyName, setCompanyName] = useState(name ?? "")
    const [error, setError] = useState("")

    useEffect(() => {
        setFirstLogin(false)
        setCompanyName(name)
    }, [name])

    useEffect(() => {
        if (companyName) setError("")
    }, [companyName])

    const navigate = useNavigate();

    const submitCompany = async (e) => {
        e.preventDefault()

        if (!companyName) {
            setError(t`Bitte ausfüllen.`)
            return
        }

        await updateCompanyName(companyName)
        navigate("/welcome/info/profile")
    }

    return (
        <form className="welcome-first-slide" onSubmit={submitCompany}>
            <div>
                <h1>{t`Willkommen` + (companyName ? ", " + companyName.trim() : "") + "!"}</h1>
                <p><Trans>Nennen Sie uns zunächst Ihren Firmennamen.</Trans></p>
            </div>

            <div style={{width: "400px", margin: "auto"}}>
                <InputText value={companyName} onChange={(e) => setCompanyName(e.target.value)} name="Company name"
                           label={t`Firmenname`} placeholder={t`Ihre Firma`}
                           error={error}
                />
            </div>
            <button type="submit" className="btn">
                <LoadableButton waiting={waiting} prompt={t`Speichern & Weiter`}/>
            </button>
        </form>
    );
};

export default WelcomeSheet;