import React from 'react';
import {Link} from "react-router-dom";

const BackButton = ({button}) => {
    const goBack = (e) => {
        e.preventDefault()
        window.history.back()
    }

    return (
        <Link to={"#"} onClick={goBack}>
            {button}
        </Link>
    );
};

export default BackButton;