import React, {useEffect, useState} from 'react';
import {ReactComponent as Bumpee} from "../../img/bumpee-logo.svg";
import GoogleButton from 'react-google-button'
import InputText from "../Widgets/InputText";
import iphones from "../../img/profile-options-iphone.png"

import {
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    signInWithGoogle
} from "../../firebase";
import {Link, useNavigate} from "react-router-dom"
import {TailSpin} from "react-loader-spinner";
import {t, Trans} from "@lingui/macro";

const Login = ({user, loading}) => {
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordValidation, setPasswordValidation] = useState("")
    const navigate = useNavigate();
    const [login, setLogin] = useState(false)
    const [passwordForgotten, setPasswordForgotten] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loadingProgress, setLoadingProgress] = useState(false)

    useEffect(() => {
        if (loading) {
            return;
        }

        if (user) {
            navigate("/")
        }
    }, [user, loading, navigate])

    useEffect(() => {
        setLoadingProgress(false)
    }, [errorMessage])

    const onEmailSubmit = (e) => {
        e.preventDefault()

        if (passwordForgotten) {
            sendPasswordReset(mail, setErrorMessage)
            return
        }

        setLoadingProgress(true)

        if (login)
            logInWithEmailAndPassword(mail, password, setErrorMessage)
        else {
            if (passwordValidation !== password) {
                setErrorMessage(t`Passwort stimmt nicht überein.`);
                return;
            }

            registerWithEmailAndPassword(mail, password, setErrorMessage)
        }
    }

    useEffect(() => {
        setErrorMessage("")
    }, [passwordForgotten, login])

    return (
        <div className="login-container">
            {/*<Popup ref={registerPop}*/}
            {/*       contentStyle={{maxWidth: "800px"}}*/}
            {/*       modal*/}
            {/*>*/}
            {/*    <RegisterLoad/>*/}
            {/*</Popup>*/}
            <div className="login-info-container">
                <div>
                    <img src={iphones} alt="Profile Preview"/>
                    <div className="login-description">
                        <h1>Upgrade your networking</h1>
                        <p><Trans>Erstellen und verwalten Sie Bumpee-Profile für Ihre Mitarbeiter.</Trans></p>
                    </div>
                </div>
            </div>
            <div className="loginField-container">
                <div className="login-sheet">
                    <Bumpee style={{width: "200px"}} fill={"#0a0a0a"}/>
                    <div className="signIn-container">
                        <div className="sso">
                            <p>Single Sign-On</p>
                            <GoogleButton type="dark" onClick={() => signInWithGoogle(setErrorMessage)}
                                          style={{marginInline: "auto"}}/>
                        </div>
                        <form className="email-login" onSubmit={onEmailSubmit}>
                            <InputText placeholder="E-Mail" value={mail} onChange={(e) => setMail(e.target.value)}
                                       label={"E-Mail"} type="email" error={errorMessage}
                                       name="username"
                            />
                            {
                                !passwordForgotten ?
                                    <>
                                        <InputText placeholder={t`Passwort`} value={password}
                                                   onChange={(e) => setPassword(e.target.value)} label={t`Passwort`}
                                                   type="password"
                                                   name={"password"}
                                        />
                                        {
                                            !login ?
                                                <InputText placeholder={t`Passwort wiederholen`}
                                                           value={passwordValidation}
                                                           onChange={(e) => setPasswordValidation(e.target.value)}
                                                           label={t`Passwort wiederholen`}
                                                           type="password"
                                                           name="password"
                                                />
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                            <div>
                                {
                                    login ?
                                        <button type="button" className="register"
                                                onClick={() => {
                                                    setPasswordForgotten(prevState => !prevState)
                                                    setLogin(true)
                                                }}
                                                style={{display: "block", marginBottom: "1rem", marginInline: "auto"}}
                                        >
                                            {!passwordForgotten ? <Trans>Passwort vergessen?</Trans> :
                                                <Trans>Zurück zum Login</Trans>}
                                        </button>
                                        :
                                        <></>
                                }
                                <button type="submit"
                                        className="btn"
                                        style={{marginInline: "auto"}}
                                        name="login"
                                >
                                    {
                                        loadingProgress ?
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                verticalAlign: "center"
                                            }}>
                                                <TailSpin color={"#ffffff"}
                                                          height={20}
                                                          width={20}
                                                          ariaLabel={"Login-Loading-indicator"}
                                                          wrapperStyle={{
                                                              display: "initial",
                                                              paddingLeft: "4px",
                                                              height: "20px"
                                                          }}
                                                />
                                            </div>
                                            :
                                            passwordForgotten ? <Trans>Passwort zurücksetzen</Trans> :
                                                login ? <Trans>Anmelden</Trans> : <Trans>Registrieren</Trans>
                                    }
                                </button>
                            </div>
                        </form>
                        <button className="register"
                                onClick={() => setLogin(prevState => !prevState)}>{login ?
                            <Trans>Neu hier? - Registrieren</Trans>
                            :
                            <Trans>Bereits Kunde? - Anmelden</Trans>
                        }</button>
                        <p style={{color: "#a7a7a7", marginTop: "1rem"}}>
                            <Trans>Wenn Sie sich anmelden, stimmen Sie den <Link to={"/terms-of-service"} style={{textDecoration: "underline"}}>Allgemeinen Nutzungsbedingungen</Link> zu.</Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;