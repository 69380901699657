import {useEffect, useRef, useState} from 'react';
import avatar from "../../img/avatar.png";
import {Link, useNavigate, useParams} from "react-router-dom";
import Popup from "reactjs-popup";
import {t, Trans} from "@lingui/macro";
import NewWorkerContainer from "./NewWorkerContainer";
import OptionButtons from "../Widgets/OptionButtons";
import {Check, X} from "react-feather";

const NewWorker = ({
                       settings,
                       editWorker,
                       setEditWorker,
                       select,
                       sorted,
                       images,
                       saveNewWorkers,
                       updateEmployee,
                       form,
                       deleteWorker,
                       waiting
                   }) => {
    const [errors, setErrors] = useState([])
    //Out root new Workers to intialize Edit Mode
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [profile, setProfile] = useState(avatar)
    const [accounts, setAccounts] = useState([])
    const [color, setColor] = useState("#0a0a0a")
    const [background, setBackground] = useState()
    const [webLogo, setWebLogo] = useState()

    const [newWorkers, setNewWorkers] = useState([])

    const [editId, setEditId] = useState()

    const {templateId} = useParams()
    const navigate = useNavigate()

    const savePopup = useRef()

    useEffect(() => {
        if (editWorker) {
            const template = settings.find(set => set.id === editWorker.template)
            let setting = JSON.parse(JSON.stringify(template))
            const editTemplate = settings.find(setting => setting.name === select.value)
            if (editTemplate && editTemplate.id !== editWorker.template) {
                setting = editTemplate
                const updatedWorker = editWorker
                if (setting) {
                    updatedWorker.template = setting.id
                    updatedWorker.color = setting.color
                    updatedWorker.background = setting.background
                    updatedWorker.webLogo = setting.webLogo
                }
                setEditWorker(updatedWorker)
            }

            const allAccounts = setting?.accounts
                .map(account => {
                    const acc = editWorker.accounts.find(acc => acc.id === account.id)
                    return acc ?? account
                })
                .concat(editWorker.accounts
                    .filter(account => !setting?.accounts.some(acc => acc.id === account.id))
                )

            setName(editWorker.name)
            setDescription(editWorker.description)
            setProfile(editWorker.profile)
            setAccounts(allAccounts)
            setColor(setting.color)
            setBackground(setting.background)
            setWebLogo(setting.webLogo)
        }
    }, [select])

    const deleteNewWorker = () => {
        if (!form) {
            deleteWorker()
        } else {
            const newArray = newWorkers.filter((worker) => worker.id !== editId)
            setNewWorkers(newArray)
            setEditId()
        }
        clearInput()
    }

    const clearInput = () => {
        setName('')
        setDescription('')
        setErrors({})
        setProfile(avatar)
        if (settings.length > 0) {
            const setting = settings.find(setting => templateId === setting.id)
            setAccounts(JSON.parse(JSON.stringify(setting.accounts)))
        }
    }

    const addNewWorker = () => {
        setEditId()
        clearInput()
    }

    const onSubmit = (e) => {
        e.preventDefault()
        let fehler = {}

        //Name
        if (!name) {
            fehler["name"] = t`Bitte ausfüllen`;
        }

        if (Object.keys(fehler).length === 0) {
            if (form) {
                addNewWorker()
            } else {
                updateEmployee({name, description, profile, accounts}).then(clearInput)
            }
        } else {
            setErrors(fehler)
        }
    }

    const saveWorkers = () => {
        saveNewWorkers(newWorkers, templateId).then(() => {
            navigate("/")
            setNewWorkers([])
        })
    }

    return (
        <>
            {
                form ?
                    <header className="header">
                        <div>
                            <h1><Trans>Profile anlegen</Trans></h1>
                            <p style={{fontSize: "1rem"}}><Trans>Füllen Sie die Felder aus und verwenden Sie "Profil
                                hinzufügen",
                                um ein Profil hinzuzufügen.</Trans></p>
                        </div>
                        <div className="options-navigation">
                            <Link to="/">
                                <button className="btn greyButton"
                                        onClick={() => {
                                        }}
                                >
                                    <X stroke={"#0a0a0a"}/>
                                    <Trans>Abbrechen</Trans>
                                </button>
                            </Link>
                            <button className="btn"
                                    onClick={() => {
                                        if (newWorkers.length > 0) savePopup.current.open()
                                    }}
                                    disabled={newWorkers.length === 0}
                            >
                                <Check stroke={newWorkers.length !== 0 ? "#ffffff" : "#ccc"}/>
                                <Trans>Abschließen</Trans>
                            </button>
                        </div>
                    </header>
                    :
                    <></>
            }

            <Popup ref={savePopup}
                   contentStyle={{maxWidth: "800px"}}
                   modal
            >
                <div className="popup">
                    <button onClick={() => savePopup.current.close()} className="btn greyButton closePopupButton">
                        <X stroke={"#0a0a0a"}/>
                    </button>
                    <div>
                        <h1><Trans>Sind Sie sich sicher?</Trans></h1>
                        <p style={{fontSize: "16px"}}><Trans>Vorgang abschließen und die neuen Profile
                            speichern.</Trans></p>
                    </div>

                    <div className="profile_row_preview">
                        {
                            newWorkers
                                .filter((worker, index) => index < 4)
                                .map(worker => (
                                    <img className="profile-image round profile-inline"
                                         src={worker.profile} alt={worker.name}
                                         style={worker.id === editId ? {border: "2px solid black"} : {}}
                                    />
                                ))
                        }
                        {
                            newWorkers.length > 4 &&
                            <div className="profile-image round profile-inline profile-text">
                                <p style={{margin: "auto", color: "#B2B2B1"}}>{"+" + (newWorkers.length - 4)}</p>
                            </div>
                        }
                    </div>

                    <OptionButtons waiting={waiting} action={saveWorkers} cancel={() => savePopup.current.close()}/>
                </div>
            </Popup>

            <NewWorkerContainer settings={settings} newWorkers={newWorkers} onSubmit={onSubmit} setName={setName}
                                name={name} setDescription={setDescription}
                                description={description} color={color}
                                background={background} accounts={accounts} setAccounts={setAccounts}
                                deleteNewWorker={deleteNewWorker} editId={editId} editWorker={editWorker} form={form}
                                setEditId={setEditId} profile={profile} setProfile={setProfile} errors={errors}
                                images={images} templateId={templateId} sorted={sorted}
                                setErrors={setErrors} setNewWorkers={setNewWorkers} setColor={setColor}
                                setBackground={setBackground}
                                setWebLogo={setWebLogo} webLogo={webLogo}
                                waiting={waiting}
            />
        </>
    );
};

export default NewWorker;
