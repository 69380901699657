import React from 'react';
import {ReactComponent as Bumpee} from "../../img/bumpee-logo.svg";
import {Link, useLocation} from "react-router-dom";
import {Trans} from "@lingui/macro";
import {Outlet} from 'react-router-dom';
import BackButton from "../Widgets/BackButton";
import LanguageToggle from "../Sidebar/LanguageToggle";
import {ChevronLeft, ChevronRight, X} from "react-feather";

const WelcomePages = ({companyName}) => {
    const location = useLocation()

    return (
        <div className="welcome-container">
            <div className="welcome-header">
                {
                    location.pathname !== "/welcome" &&
                    <BackButton button={
                        <button className="btn greyButton welcome-back-button optionButton">
                            <ChevronLeft/>
                            <Trans>Zurück</Trans>
                        </button>
                    }
                    />
                }
                <div>
                    <Bumpee style={{width: "150px"}} fill={"#000000"}/>
                    {
                        companyName && <>
                            <X/>
                            <Link to={"/welcome"} style={{color: "initial"}}>
                                <h2>{companyName}</h2>
                            </Link>
                        </>
                    }
                </div>
                {
                    location.pathname !== "/welcome" &&
                    <Link to={"/"}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <p><Trans>Einführung überspringen</Trans></p>
                            <ChevronRight/>
                        </div>
                    </Link>
                }
            </div>
            <div className="welcome-main-side">
                <Outlet/>
            </div>
            <div className="welcome-footer">
                <p><Trans>Sprache:</Trans></p>
                <LanguageToggle/>
            </div>
        </div>
    );
};

export default WelcomePages;