import React from 'react';
import {TailSpin} from "react-loader-spinner";
import {Trans} from "@lingui/macro";

const UpdateField = ({waiting}) => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            verticalAlign: "center",
            color: "white",
            padding: "1rem",
            height: "20px"
        }}>
            {
                waiting ?
                    <>
                        <TailSpin color={"#ffffff"}
                                  height={20}
                                  width={20}
                                  ariaLabel={"Login-Loading-indicator"}
                                  wrapperStyle={{display: "initial", paddingRight: "4px", height: "20px"}}
                        />
                        <p><Trans>Aktualisieren</Trans></p>
                    </>
                    :
                    <></>
            }
        </div>
    );
};

export default UpdateField;