import React, {useEffect, useRef} from 'react';
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {GeoPoint} from "firebase/firestore"

const GoogleMapsInput = ({updateAccount, account}) => {
    const inputField = useRef()

    useEffect(() => {
        if (!account.info.descValue || account.info.descValue === "Address") inputField.current.value = ""
        else inputField.current.value = account.info.descValue
    }, [account.info.descValue])

    return (
        <ReactGoogleAutocomplete
            apiKey={"AIzaSyCfXg2rv0-tXZiKxqd-VXvgQ9uRy_tjrTU"}
            onPlaceSelected={(place) => {
                const updatedAccount = JSON.parse(JSON.stringify(account))

                updatedAccount.info = {
                    "urlValue": place.url,
                    "descValue": place.formatted_address,
                    "location": new GeoPoint(place.geometry.location.lat(), place.geometry.location.lng())
                }

                updateAccount(updatedAccount)
            }}
            options={{
                types: ('route', "business"),
                fields: ["formatted_address", "url", "geometry"]
            }}
            ref={inputField}
        />
    );
};

export default GoogleMapsInput;