import React, {useEffect, useState} from 'react';
import {t, Trans} from "@lingui/macro";
import NewWorkerContainer from "../NewWorker/NewWorkerContainer";
import avatar from "../../img/avatar.png";
import {useNavigate, useParams} from "react-router-dom";
import {deleteObject, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../firebase";
import {v4 as uuidv4} from "uuid";
import Employee from "../../entities/Employee";
import {updateDoc} from "firebase/firestore";

const WelcomeNewWorker = ({settings, images, sorted, saveNewWorkers, createTemplate, workers, setWaiting, waiting}) => {
    const [newWorkers, setNewWorkers] = useState([])
    const [errors, setErrors] = useState([])
    //Out root new Workers to intialize Edit Mode
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [profile, setProfile] = useState(avatar)
    const [accounts, setAccounts] = useState([])
    const [color, setColor] = useState("#0a0a0a")
    const [background, setBackground] = useState()
    const [webLogo, setWebLogo] = useState()
    const [editId, setEditId] = useState(null)

    const {templateId} = useParams()
    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        let fehler = {}

        //Name
        if (!name) {
            fehler["name"] = t`Bitte ausfüllen`;
        }

        if (Object.keys(fehler).length === 0) {
            if (workers.length === 0) {
                await saveNewWorkers(newWorkers, templateId)
            } else {
                await updateEmployee({name, description, profile, accounts})
            }

            navigate("/welcome/info/card")
        } else {
            setErrors(fehler)
        }
    }

    const updateEmployee = async (employee) => {
        setWaiting(true)
        const editWorker = workers[0]
        let imageURL = editWorker.profile
        if (imageURL !== employee.profile) {
            try {
                if (imageURL) {
                    const desertRef = ref(storage, imageURL)
                    await deleteObject(desertRef)
                }
            } catch (e) {
                console.log(e.message)
            } finally {
                const image_id = uuidv4()
                const storageRef = ref(storage, "profile/" + image_id)
                const compressedProfile = await Employee.compressImage(Employee.dataURLtoFile(employee.profile, "logo.png"))
                const snapshot = await uploadBytes(storageRef, compressedProfile)

                imageURL = await getDownloadURL(snapshot.ref)
            }
        }

        await updateDoc(editWorker.ref, Employee.toMap(editWorker.webLogo, editWorker.background, editWorker.color, imageURL, employee.description, employee.name, editWorker.tag, editWorker.team, employee.accounts, editWorker.template))
        setWaiting(false)
    }

    useEffect(() => {
        if (workers.length > 0) {
            const newWorker = workers[0]
            const template = settings.find(set => set.id === newWorker.template)
            let setting = JSON.parse(JSON.stringify(template))

            const allAccounts = setting?.accounts
                .map(account => {
                    const acc = newWorker.accounts.find(acc => acc.id === account.id)
                    return acc ?? account
                })
                .concat(newWorker.accounts
                    .filter(account => !setting?.accounts.some(acc => acc.id === account.id))
                )

            setName(newWorker.name)
            setDescription(newWorker.description)
            setProfile(newWorker.profile)
            setAccounts(allAccounts)
            setEditId(newWorker.id)
        }
    }, [workers])

    return (
        <div className="welcome-first-slide">
            <div>
                <h1><Trans>Fügen Sie nun Ihre Informationen hinzu</Trans></h1>
                <p><Trans>Als Beispiel beginnen wir mit Ihrem Profil</Trans></p>
            </div>
            <NewWorkerContainer
                settings={settings} images={images} sorted={sorted} form={false}
                newWorkers={newWorkers} setNewWorkers={setNewWorkers}
                setName={setName} name={name} setDescription={setDescription} description={description}
                profile={profile} setProfile={setProfile} accounts={accounts} setAccounts={setAccounts} errors={errors}
                setErrors={setErrors} color={color} setColor={setColor} background={background}
                setBackground={setBackground}
                webLogo={webLogo} setWebLogo={setWebLogo}
                templateId={templateId}
                onSubmit={onSubmit}
                editId={editId} setEditId={setEditId}
                waiting={waiting}
            />
        </div>
    );
};

export default WelcomeNewWorker;