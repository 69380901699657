import account_types from "../components/Templates/ProfileTemplate/account_types.json"
import {GeoPoint, Timestamp, updateDoc} from "firebase/firestore"
import Compressor from "compressorjs";

class Employee {
    constructor(map, id, ref) {
        this.id = id
        this.ref = ref
        this.name = map.name
        this.background = map.covUrl
        this.color = Employee.radixToHex(map.design.color)
        this.webLogo = map.webLogo
        this.description = map.info
        this.profile = map.imgUrl
        this.tag = map.tag

        const smalls = map.array.map(account => Employee.transformToAccount(account, 0))
        this.accounts = [...smalls, ...map.links.map(link => Employee.transformToAccount(link, 1))]
        this.user = map.user
        this.connectedOnDate = map.connectedOnDate
        this.team = map.team
        this.template = map.template
        this.creationDate = map.creationDate

        if (map.links.some(link => !link.type)) {
            updateDoc(this.ref, Employee.toMap(this.webLogo, this.background, this.color, this.profile, this.description, this.name, this.tag, this.team, this.accounts, this.template)).then(() => {
                console.log("Updated employee for app format " + this.name)
            })
        }
    }

    static toMap(webLogo, background, color, profile, description, name, tag, team, accounts, templateId) {
        const employee = {
            "covDis": false,
            "design": {
                "color": Employee.hexToRadix(color)
            },
            "imgUrl": profile,
            "info": description.trim(),
            "name": name.trim(),
            "tag": tag,
            "team": team,
            "theme": 99,
            "array": [...accounts
                .filter(account => account.type === 0 && account.info.urlValue)
                .map(account => Employee.transformToCards(account, 0))
            ],
            "links": [...accounts
                .filter(account => account.type === 1 && account.info.urlValue)
                .map(account => Employee.transformToCards(account, 1))
            ],
            "updated": Timestamp.now(),
            "template": templateId,
        }

        if (!!background) employee.covUrl = background
        if (!!webLogo) employee.webLogo = webLogo

        return employee
    }

    static dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    static compressImage = (blob) => {
        return new Promise(resolve => {
            new Compressor(blob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
                convertTypes: 'image/png,image/webp',
                convertSize: 1000000,

                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(result) {
                    resolve(result)
                },
                error(err) {
                    console.log(err.message);
                }
            })
        })
    }

    static transformToAccount(account, type) {
        account.name = !!account.name ? account.name : !!account.location ? "Address" : "Link"

        switch (type) {
            case 0:
                return {
                    "id": account.id,
                    "name": account.name,
                    "type": 0,
                    "info": {
                        "urlValue": account.data,
                        "descValue": ""
                    },
                    "img": account.icon,
                    "master": account.master ?? false
                }
            case 1:
                let info
                if (account.name === "Address") {
                    info = {
                        "urlValue": account.url,
                        "descValue": account.title,
                        "location": {
                            "latitude": account.location?.latitude ??  0,
                            "longitude": account.location?.longitude ??  0
                        }
                    }
                } else {
                    info = account.name === "E-Mail" ?
                        {"urlValue": account.url.substring(7), "descValue": account.title}
                        :
                        account.name === "Phone" ?
                            {"urlValue": account.url.substring(4), "descValue": account.title}
                            :
                            {"urlValue": account.url, "descValue": account.title}
                }

                return {
                    "id": account.id,
                    "name": account.name,
                    "type": 1,
                    "info": info,
                    "master": account.master ?? false,
                    "img": account_types.find(type => type.name === account.name).icon + ".png"
                }
            default:
                return;
        }
    }

    static transformToCards(account, type, isTemplate = false) {
        switch (type) {
            case 0:
                return {
                    "id": account.id,
                    "icon": account.img,
                    "data": account.info.urlValue,
                    "type": account.name === "Phone" ?
                        "phone"
                        :
                        account.name === "E-Mail" ? "email" : "link",
                    "name": account.name,
                    "master": account.master
                }
            case 1:
                let basicBig = {
                    "id": account.id,
                    "master": account.master,
                    "name": account.name,
                    "title": account.info.descValue ?? "Beschreibung",
                    "url": account.info.urlValue,
                    "type": "link"
                }

                if (account.name === "E-Mail") {
                    basicBig.url = "mailto:" + account.info.urlValue
                    basicBig.type = "email"
                }

                if (account.name === "Phone") {
                    basicBig.url = "tel:" + account.info.urlValue.replaceAll(/\s+/g, "").replaceAll("-", "");
                    basicBig.type = "phone"
                }

                if (account.name === "Address") {
                    if (account.master || !isTemplate)
                        basicBig.location = new GeoPoint(account.info.location.latitude, account.info.location.longitude)
                    basicBig.type = "map"
                }

                return basicBig
            default:
                return
        }
    }

    static hexToRadix(hex) {
        return parseInt("ff" + hex.substring(1), 16)
    }

    static radixToHex(radix) {
        return "#" + ((radix << 8) >>> 8).toString(16).padStart(6, "0");
    }
}

export default Employee;