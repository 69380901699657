import React from 'react';
import {Splide, SplideSlide} from "@splidejs/react-splide";
// Default theme
import '@splidejs/react-splide/css';
import {UserPlus} from "react-feather";

const ProfileRow = ({workers, editWorker, editId, addNewWorker}) => {
    return (
        <Splide options={{
            perMove: '1',
            perPage: "5",
            pagination: false,
            padding: "2.5rem"
        }}
        >
            {
                workers.map(worker => {
                    return <SplideSlide key={worker.id}>
                        <div>
                            <button onClick={() => editWorker(worker.id)}
                                    style={{border: "none", background: "none", cursor: "pointer"}}>
                                <img className="profile-image round profile-inline"
                                     src={worker.profile} alt={worker.name}
                                     style={worker.id === editId ? {border: "2px solid black"} : {}}
                                />
                            </button>
                        </div>
                    </SplideSlide>
                })
            }
            <SplideSlide>
                <div className="profile-image round profile-inline profile-text" style={{background: "#ececec", cursor: "pointer"}} onClick={addNewWorker}>
                    <UserPlus stroke={"#B2B2B1"} style={{margin: "auto"}}/>
                </div>
            </SplideSlide>
        </Splide>
    );
};

export default ProfileRow;