import React, {useEffect, useState} from 'react';
import ProfileEditor from "./ProfileEditor";
import {Trans} from "@lingui/macro";
import PhonePreview from "./PhonePreview";
import {useNavigate} from "react-router-dom";
import BackButton from "../../Widgets/BackButton";
import LoadableButton from "../../Widgets/LoadableButton";
import {Check, X} from "react-feather";
import ProfileSettings from "../../../entities/ProfileSettings";

const ProfileTemplate = ({settings, onSave, images, sorted, templateId, name, isSettings, activePlan}) => {
    const [background, setBackground] = useState("");
    const [color, setColor] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [backUpdated, setBackUpdated] = useState(false);
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [customLogoUpdated, setCustomLogoUpdated] = useState(false);
    const [customLogoUrl, setCustomLogoUrl] = useState("");
    const [customLogo, setCustomLogo] = useState("");

    const navigate = useNavigate()
    const [errors, setErrors] = useState([])
    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        if (settings.length > 0 && !background) {
            const setting = settings.find(setting => templateId === setting.id)
            if (!setting) {
                navigate("/templates/profile")
                return
            }
            setBackground(setting.background)
            setBackgroundUrl(setting.background)
            setCustomLogo(setting.webLogo)
            setCustomLogoUrl(setting.webLogo)
            setColor(setting.color)
            setAccounts(setting.accounts)
        }
    }, [background, navigate, settings, templateId])

    const saveProfiletemplate = async (goToPlan = false) => {
        setWaiting(true)
        let fehler = {}

        accounts.forEach(account => {
            if (!account.info.urlValue && account.master) fehler[account.id] =
                <Trans>Bei Masteraccounts muss das Feld ausgefüllt werden.</Trans>
        })

        if (Object.keys(fehler).length === 0) {
            const backUrl = await ProfileSettings.uploadImages(backgroundUrl, backUpdated, background, setBackgroundUrl, setBackUpdated)
            const logoUrl = await ProfileSettings.uploadImages(customLogoUrl, customLogoUpdated, customLogo, setCustomLogoUrl, setCustomLogoUpdated)

            accounts.forEach(account => {
                if (account.type === 1 && !account.info.descValue) account.info.descValue = account.name
            })

            let setting = settings.find(setting => templateId === setting.id)

            setting.background = backUrl
            setting.webLogo = logoUrl
            setting.accounts = accounts
            setting.color = color
            setting.name = settings.some(setting => setting.id !== templateId && setting.name === name) ? name + "_1" : name

            onSave(setting.toMap(), setting.id, goToPlan)
        } else {
            setErrors(fehler)
            setWaiting(false)
        }
    }

    return (
        <>
            <div className="newWorker" style={{justifyContent: "center"}}>
                <div className="editor_container" style={{maxHeight: "60vh"}}>
                    <ProfileEditor setBackground={setBackground}
                                   setLogo={setCustomLogo}
                                   setColor={setColor}
                                   color={color}
                                   sorted={sorted}
                                   images={images}
                                   accounts={accounts}
                                   setAccounts={setAccounts}
                                   setBackgroundUpdated={setBackUpdated}
                                   setLogoUpdated={setCustomLogoUpdated}
                                   errors={errors}
                                   activePlan={activePlan}
                                   waiting={waiting}
                                   save={saveProfiletemplate}
                    />
                </div>
                <div className="editor_preview template_editing_preview">
                    <PhonePreview accountTypes={accounts}
                                  background={background}
                                  color={color}
                                  template={true}
                                  images={images}
                                  customLogo={customLogo}

                    />
                </div>
            </div>
            <div className="options-navigation">
                {
                    isSettings ?
                        <>
                            <BackButton button={
                                <button className="btn greyButton" style={{height: "40px", marginLeft: "1rem"}}>
                                    <X stroke={"#0a0a0a"}/>
                                    <Trans>Abbrechen</Trans>
                                </button>
                            }/>
                            <button className="btn" onClick={() => saveProfiletemplate()}>
                                <LoadableButton waiting={waiting} prompt={
                                    <>
                                        <Check stroke={"#fffffe"}/>
                                        <Trans>Speichern</Trans>
                                    </>
                                }/>
                            </button>
                        </>
                        :
                        <button className="btn" onClick={() => saveProfiletemplate()}>
                            <LoadableButton waiting={waiting} prompt={<Trans>Speichern & Weiter</Trans>}/>
                        </button>
                }

            </div>
        </>

    );
};

export default ProfileTemplate;