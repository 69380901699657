import React, {useState} from 'react';
import InputText from "../Widgets/InputText";
import {t} from "@lingui/macro";
import LoadableButton from "../Widgets/LoadableButton";

const ChangeCompanyName = ({serverName, waiting, close, updateCompanyName}) => {
    const [companyName, setCompanyName] = useState(serverName ?? "")
    const [error, setError] = useState("")

    const submit = async (e) => {
        e.preventDefault()

        if (!companyName) {
            setError(t`Bitte ausfüllen.`)
            return
        }

        await updateCompanyName(companyName)
        close()
    }

    return (
        <form onSubmit={submit}>
            <div style={{width: "400px", margin: "auto"}}>
                <InputText value={companyName} onChange={(e) => setCompanyName(e.target.value)} name="Company name"
                           label={t`Firmenname`} placeholder={t`Ihre Firma`}
                           error={error}
                />
            </div>
            <button type="submit" className="btn" style={{marginInline: "auto"}}>
                <LoadableButton waiting={waiting} prompt={t`Speichern`}/>
            </button>
        </form>
    );
};

export default ChangeCompanyName;