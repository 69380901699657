import React, {useRef, useState} from 'react';
import {ReactComponent as Select} from "../../img/select.svg";
import "./profileOverview.css"
import {Link, useNavigate} from "react-router-dom";
import Popup from "reactjs-popup";
import ProfilePreviewTemplate from "../Templates/Container/ProfilePreviewTemplate";
import {deleteObject, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../firebase";
import {v4 as uuidv4} from "uuid";
import Employee from "../../entities/Employee";
import {updateDoc} from "firebase/firestore";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import EditPopUp from "./EditPopUp";
import NewTemplate from "../Templates/Container/NewTemplate";
import AreYouSure from "../Widgets/AreYouSure";
import {t, Trans} from "@lingui/macro";
import FilterOptions from "./FilterOptions";
import EmployeePagination from "./EmployeePagination";
import {UserPlus, X} from "react-feather";

const ProfileOverview = ({
                             workers,
                             deleteWorker,
                             selected,
                             setSelected,
                             sorted,
                             accountSettings,
                             images,
                             setLoading,
                             createTemplate,
                             dataLoaded,
                             invites
                         }) => {
    const [selectionMode, setSelectionMode] = useState(false)
    const editRef = useRef()
    const newProfilesRef = useRef()
    const navigate = useNavigate()

    const [filteredList, setFilteredList] = useState(workers)
    const [displayStyle, setDisplayStyle] = useState(0)

    //Out root new Workers to intialize Edit Mode
    const [editWorker, setEditWorker] = useState("")

    const deleteAccountRef = useRef()

    const addSelection = (worker) => {
        setSelected(prevState => {
            if (prevState.some(selection => selection.id === worker.id)) {
                return prevState.filter(selection => selection.id !== worker.id)
            }

            return [...prevState, worker]
        })
    }

    const deleteSelcted = () => {
        deleteAccountRef.current.open()
    }

    const selectAll = () => {
        selected.length !== workers.length ?
            setSelected(workers)
            :
            setSelected([])
    }

    const editEmployee = (id) => {
        setEditWorker(workers.find(worker => worker.id === id))
        editRef.current.open()
    }

    const updateEmployee = async (map) => {
        setLoading(true)
        let imageURL = editWorker.profile
        if (imageURL !== map.profile) {
            try {
                if (imageURL) {
                    const desertRef = ref(storage, imageURL)
                    await deleteObject(desertRef)
                }
            } catch (e) {
                console.log(e.message)
            } finally {
                const image_id = uuidv4()
                const storageRef = ref(storage, "profile/" + image_id)
                const compressedProfile = await Employee.compressImage(Employee.dataURLtoFile(map.profile, "logo.png"))
                const snapshot = await uploadBytes(storageRef, compressedProfile)

                imageURL = await getDownloadURL(snapshot.ref)
            }
        }

        await updateDoc(editWorker.ref, Employee.toMap(editWorker.webLogo, editWorker.background, editWorker.color, imageURL, map.description, map.name, editWorker.tag, editWorker.team, map.accounts, editWorker.template))
        editRef.current.close()
        setEditWorker("")
        setLoading(false)
    }


    const deleteEditWorker = async (worker) => {
        await deleteWorker(worker)
        editRef.current.close()
    }

    const newTemplate = (name, isCard) => {
        createTemplate(name, false).then(ref => navigate("/templates/profile/" + ref.id))
    }

    const sortingOptions = [
        {
            value: "name",
            label: t`Nachname`
        },
        {
            value: "recent",
            label: t`Zuletzt hinzugefügt`
        },
        // {
        //     value: "template",
        //     label: t`Vorlage`
        // }
    ]

    return (
        <>
            <header className="header">
                <div>
                    <h1><Trans>Profile verwalten</Trans></h1>
                    <p style={{fontSize: "1rem"}}><Trans>Verwalten Sie die Profile Ihrer Mitarbeiter:innen</Trans></p>
                </div>
                <div className="options-navigation">
                    <button className="btn greyButton"
                            onClick={() => setSelectionMode(prevState => {
                                if (prevState) setSelected([])
                                return !prevState
                            })}
                    >
                        {
                            selectionMode ? <Trans>Auswahl abbrechen</Trans> :
                                <>
                                    <Select stroke={"#0a0a0a"}/>
                                    <Trans>Auswählen</Trans>
                                </>
                        }
                    </button>
                    <Popup
                        trigger={
                            <button className="btn" id={"profileAdd"}>
                                <UserPlus stroke={"#fff"}/>
                                <Trans>Profile erstellen</Trans>
                            </button>
                        }
                        ref={newProfilesRef}
                        contentStyle={{width: "inherit", minWidth: "600px"}}
                        modal
                        closeOnDocumentClick={false}
                    >
                        <div className="popup template_select_popup">
                            <button onClick={() => newProfilesRef.current.close()}
                                    className="btn greyButton closePopupButton">
                                <X stroke={"#0a0a0a"}/>
                            </button>
                            <div>
                                <h1><Trans>Profilvorlagen</Trans></h1>
                                <p><Trans>Wählen Sie die passende Profilvorlage für die neuen Profile aus.</Trans></p>
                            </div>
                            <div className="select_template">
                                {
                                    accountSettings?.length > 0 ?
                                        <Splide
                                            options={{
                                                rewind: true,
                                                pagination: true
                                            }}
                                        >
                                            {
                                                accountSettings.map((setting, index) => (
                                                    <SplideSlide key={setting.id} style={{paddingBottom: "2rem"}}>
                                                        <ProfilePreviewTemplate setting={setting} key={index}
                                                                                images={images}
                                                                                selection={true}/>
                                                    </SplideSlide>
                                                ))}
                                        </Splide>
                                        :
                                        <NewTemplate createTemplate={newTemplate} isPopup={true}/>
                                }

                            </div>
                            <Link to="/templates/profile" style={{marginBottom: "0"}}>
                                <button className="btn greyButton" style={{margin: "0 auto"}}><Trans>Profilvorlagen bearbeiten</Trans></button>
                            </Link>
                        </div>
                    </Popup>
                </div>
            </header>
            <FilterOptions setFilter={setFilteredList} workers={workers} displayStyle={displayStyle}
                           setDisplayStyle={setDisplayStyle}
                           sortingOptions={sortingOptions}
            />
            <EmployeePagination filteredList={filteredList} editEmployee={editEmployee}
                                displayStyle={displayStyle} selected={selected}
                                selectionMode={selectionMode} addSelection={addSelection}
                                dataLoaded={dataLoaded} workersEmpty={workers?.length > 0}
                                openNewProfiles={() => newProfilesRef.current.open()}
            />
            <Popup ref={editRef}
                   contentStyle={{width: "90%", height: "90%"}}
                   nested
            >
                <EditPopUp editWorker={editWorker} accountSettings={accountSettings}
                           deleteEditWorker={deleteEditWorker} images={images} sorted={sorted}
                           updateEmployee={updateEmployee}
                           setEditWorker={setEditWorker}
                           closePopup={() => editRef.current.close()}
                           inviteWorker={invites.find(invite => invite.profile === editWorker.id)}
                />
            </Popup>
            {
                selectionMode ?
                    <div className="selector-options">
                        <button className="btn whiteButton" onClick={selectAll}>
                            {
                                selected.length !== workers.length ?
                                    <Trans>Alle auswählen</Trans>
                                    :
                                    <Trans>Zurücksetzen</Trans>
                            }
                        </button>
                        <Link to="/templates">
                            <button className="btn">
                                <Trans>Vorlagen anpassen</Trans>
                            </button>
                        </Link>
                        {/*<Link to="/overview">*/}
                        {/*    <button className="btn" style={{backgroundColor: "#078080"}}>*/}
                        {/*        {selected.length > 0 ? selected.length : ""} {selected.length === 1 ? "Karte" : "Karten"} bestellen*/}
                        {/*    </button>*/}
                        {/*</Link>*/}
                        <button className="btn"
                                style={selected.length > 0 ? {backgroundColor: "#e84855", border: "none"} : {
                                    backgroundColor: "white",
                                    cursor: "initial",
                                    border: "rgb(204, 204, 204)",
                                    color: "rgb(204, 204, 204)"
                                }}
                                onClick={deleteSelcted}
                        >
                            <Trans>Profile löschen</Trans>
                        </button>
                        <Popup
                            ref={deleteAccountRef}
                            modal
                            contentStyle={{width: "inherit"}}
                        >
                            <AreYouSure closePopup={() => deleteAccountRef.current.close()}
                                        description={t`Das Löschen von Profilen lässt sich nicht rückgängig machen.`}
                                        sure={() => deleteWorker().then(() => {
                                            deleteAccountRef.current.close()
                                            setSelected([])
                                        })}
                                        action={t`Profile löschen`}/>
                        </Popup>
                    </div>
                    :
                    <></>
            }
        </>
    );
};

export default ProfileOverview;