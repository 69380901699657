import React, {useState} from 'react';
import {t, Trans} from "@lingui/macro";
import {Check, Trash2} from "react-feather";

const TemplateSettings = ({templateName, setTemplateName, close, deleteTemplate}) => {
    console.log(templateName)
    const [name, setName] = useState(templateName);

    const initiateDeleteTemplate = () => {
        deleteTemplate()
        close()
    }

    return (
        <div className="editSettings">
            <div>
                <label>{t`Name`}: {templateName}</label>
                <div className="input-check">
                    <input type="text" placeholder={t`Vorlagenname`}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                    />
                    {
                        name !== templateName && !!name &&
                        <button className={"selection-ball selected"}
                                onClick={() => setTemplateName(name)}
                        >
                            <Check/>
                        </button>
                    }
                </div>
            </div>
            <div>
                <label><Trans>Vorlage löschen</Trans></label>
                <button className="btn greyButton optionButton" onClick={initiateDeleteTemplate}>
                    <Trash2/>
                    <Trans>Löschen</Trans>
                </button>
            </div>
            <button className="btn optionButton" onClick={() => close()}><Check stroke={"#fff"}/>
                <Trans>Schließen</Trans>
            </button>
        </div>
    );
};

export default TemplateSettings;