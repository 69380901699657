import React, {useMemo} from 'react';
import ProfileTemplate from "../Templates/ProfileTemplate/ProfileTemplate";
import {Trans} from "@lingui/macro";
import {useNavigate, useParams} from "react-router-dom";

const WelcomeProfile = ({onSave, settings, sorted, images}) => {
    const navigate = useNavigate()
    const {templateId} = useParams()

    const whenSaved = (map, id) => {
        onSave(map, id).then(result => {
            if (result) navigate("/welcome/newProfile/" + templateId)
        })
    }

    const templateName = useMemo(() => {
        return settings.find(template => template.id === templateId)?.name
    }, [settings, templateId])

    return (
        <div className="welcome-first-slide">
            <div>
                <h1><Trans>Erstellen Sie ein Online-Profil Design</Trans></h1>
                <p><Trans>Es dient als Vorlage für Ihr Online-Profil und das Ihrer Mitarbeitenden.</Trans></p>
            </div>
            <ProfileTemplate onSave={whenSaved} settings={settings} sorted={sorted} images={images}
                             templateId={templateId}
                             name={templateName}
                             activePlan={"notAvailable"}
            />
        </div>
    );
};

export default WelcomeProfile;