import React from 'react';
import Skeleton from "react-loading-skeleton";

const SkeletonOverview = () => {
    return (
        [...Array(10),].map((_, index) => (
            <Skeleton key={index} height={294} style={{borderRadius: "20px"}}/>
        ))
    )
};

export default SkeletonOverview;