import React, {useEffect, useState} from 'react';
import {t, Trans} from "@lingui/macro";

import RadioSelector from "../Widgets/RadioSelector";
import ReactDropdown from "react-dropdown";
import {Grid, List, Search, X} from "react-feather";

const displayOptions = [<Grid/>, <List/>]

const FilterOptions = ({setFilter, workers, displayStyle, setDisplayStyle, sortingOptions}) => {
    const [query, setQuery] = useState("")

    const [sortBy, setSortBy] = useState(sortingOptions.find(opt => opt.value === "name"))

    useEffect(() => {
        let filtered = workers.filter(profile => {
            const q = query.toLowerCase()
            const name = profile.name.toLowerCase()
            return name.includes(q)
        })

        filtered = filtered.sort((a, b) => {
            switch (sortBy.value) {
                case "name":
                    const nameA = a.name.toUpperCase().split(" "); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase().split(" "); // ignore upper and lowercase
                    if (nameA[nameA.length - 1] < nameB[nameB.length - 1]) {
                        return -1;
                    }
                    if (nameA[nameA.length - 1] > nameB[nameB.length - 1]) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                case "recent":
                    //sort by most recent date of creation, put objects without creation date at the end
                    if (!a.creationDate) return 1
                    if (!b.creationDate) return -1
                    return b.creationDate.seconds - a.creationDate.seconds
                case "inviteRecent":
                    //sort by most recent date of invitation
                    if (!a.codes || !b.codes) return 0

                    const mostRecentA = a.codes.reduce((a, b) => a.seconds < b.seconds ? a : b)
                    const mostRecentB = b.codes.reduce((a, b) => a.seconds < b.seconds ? a : b)
                    return mostRecentB.seconds - mostRecentA.seconds
                default:
                    return 0
            }
        })

        //See if sortingOptions includes inviteRecent
        if (sortingOptions.find(opt => opt.value === "inviteRecent")) {
            filtered = filtered.sort((a, b) => {
                if (a.user && b.user) return 0
                if (a.user) return 1
                if (b.user) return -1
                return 0
            })
        }

        setFilter(filtered)
    }, [query, setFilter, sortBy, workers])

    return (
        <div className="filter-options">
            <div className={"filter-search"}>
                <Search stroke={"#a7a7a7"} width={16}/>
                <input type="text"
                       placeholder={t`Suche`}
                       value={query}
                       onChange={(e) => setQuery(e.target.value)}
                       className="filter-search-field"
                />
                {
                    query && <button onClick={() => setQuery("")} style={{cursor: "pointer"}}>
                        <X stroke={"#a7a7a7"} width={12}/>
                    </button>
                }
            </div>
            <div className="filter-toggles">
                <div>
                    <p style={{fontSize: "1rem"}}><Trans>Sortieren nach:</Trans></p>
                    <ReactDropdown options={sortingOptions} onChange={(e) => setSortBy(e)}
                                   controlClassName="sortDropControl footerDropControl"
                                   menuClassName="sortDropMenu"
                                   value={sortBy}
                    />
                </div>
                {
                    setDisplayStyle && <div>
                        <p style={{fontSize: "1rem"}}><Trans>Darstellung:</Trans></p>
                        <RadioSelector setSelected={setDisplayStyle} selected={displayStyle} options={displayOptions} id={0}
                                       nonTextOptions={true}/>
                    </div>
                }
            </div>
        </div>
    );
};

export default FilterOptions;