import React, {useState} from 'react';
import ClipboardButton from "../Settings/AppInvitation/ClipboardButton";
import {Trans} from "@lingui/macro";
import LoadableButton from "../Widgets/LoadableButton";
import {retrieveProfile} from "../../firebase";

const EditInviteButton = ({invite, id}) => {
    const [waiting, setWaiting] = useState(false)
    const createCode = () => {
        setWaiting(true)
        retrieveProfile([id]).then(() => {
            setWaiting(false)
        })
    }

    return (
        <div>
            <label><Trans>Einladung zur App</Trans>:</label>
            {invite ?
                <ClipboardButton link={"bumpee.co/addProfile/" + invite.id}/>
                :
                <button className="btn greyButton" onClick={createCode}>
                    <LoadableButton waiting={waiting} prompt={<Trans>Einladung erstellen</Trans>}/>
                </button>}
        </div>
    );
};

export default EditInviteButton;