import React, {useEffect, useMemo, useState} from 'react';
import ProfileDisplay from "./ProfileDisplay";
import ProfileRowDisplay from "./ProfileRowDisplay";
import {Trans} from "@lingui/macro";
import {Link} from "react-router-dom";
import SkeletonOverview from "./SkeletonOverview";
import {ChevronLeft, ChevronRight} from "react-feather";

const EmployeePagination = ({filteredList, displayStyle, selectionMode, addSelection, selected, editEmployee, workersEmpty, dataLoaded, openNewProfiles}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = useMemo(() => {
        const availableWidth = window.innerWidth - 235 - 160;

        const oneItem = 230 + 16

        for (let i = 10; i > 3; i--) {
            if (availableWidth > oneItem * i) {
                return i * 2;
            }
        }

        return 6
    }, []);

    useEffect(() => {
        setCurrentPage(1)
    }, [filteredList.length])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const numberOfPages = Math.ceil(filteredList.length / itemsPerPage);

    return (
        <>
            <div className={"profiles" + (displayStyle === 1 ? " row-profiles" : "")} style={dataLoaded && !workersEmpty ? {display: "flex"} : {}}>
                {
                    dataLoaded ?
                        workersEmpty ?
                            filteredList.length > 0 ?
                                filteredList
                                    .slice(indexOfFirstItem, indexOfLastItem)
                                    .map(worker => (
                                        displayStyle === 0 ?
                                            <ProfileDisplay worker={worker}
                                                            selectionMode={selectionMode}
                                                            addSelection={addSelection}
                                                            selected={selected.some(selection => selection.id === worker.id)}
                                                            key={worker.id}
                                                            editEmployee={editEmployee}
                                                            preview={false}
                                            />
                                            :
                                            <ProfileRowDisplay worker={worker}
                                                               selectionMode={selectionMode}
                                                               addSelection={addSelection}
                                                               selected={selected.some(selection => selection.id === worker.id)}
                                                               key={worker.id}
                                                               editEmployee={editEmployee}
                                                               preview={false}
                                            />
                                    ))
                                :
                                <div className="centerMsg" style={{marginInline: "auto"}}>
                                    <Trans>Keine Ergebnisse gefunden.</Trans>
                                </div>
                            :
                            <div className="centerMsg" style={{marginInline: "auto"}}>
                                <p style={{marginBottom: "1rem"}}><Trans>Fangen Sie zunächst an mit der Erstellung von
                                    Profilen für Ihre Mitarbeiter:innen oder gestalten Sie zunächst eine Vorlage für die
                                    Profile
                                    Ihrer Mitarbeiter:innen</Trans></p>
                                <div>
                                    <Link to={"/templates/profile"}>
                                        <button style={{marginInline: "1rem"}} className="whiteButton btn">
                                            <Trans>Vorlagen bearbeiten</Trans>
                                        </button>
                                    </Link>
                                    <button className="btn" onClick={openNewProfiles}>
                                        <Trans>Profile erstellen</Trans>
                                    </button>
                                </div>
                            </div>
                        :
                        <SkeletonOverview/>
                }
            </div>
            {
                numberOfPages > 1 &&
                <div className="pagination-spacer">
                    <div className="pagination">
                        <button onClick={() => setCurrentPage(prevState => prevState - 1)} disabled={currentPage === 1}>
                            <ChevronLeft />
                        </button>
                        {
                            Array.from(Array(numberOfPages).keys()).map(page => (
                                <button
                                    key={page}
                                    onClick={() => setCurrentPage(page + 1)}
                                    disabled={currentPage === page + 1}
                                    className="pagination-page"
                                >
                                    {page + 1}
                                </button>
                            ))
                        }
                        <button onClick={() => setCurrentPage(prevState => prevState + 1)}
                                disabled={currentPage === numberOfPages}
                        >
                            <ChevronRight/>
                        </button>
                    </div>
                </div>
            }
        </>

    );
};

export default EmployeePagination;