import React from 'react';
import {Trans} from "@lingui/macro";
import {Link} from "react-router-dom";

const WelcomeFinish = () => {
    return (
        <div className="welcome-first-slide">
            <div>
                <h1><Trans>Herzlichen Glückwunsch zur Einrichtung!</Trans></h1>
                <p><Trans>Sie können jetzt loslegen!</Trans></p>
            </div>
            <div className="options-navigation">
                <Link to={"/"}>
                    <button className="btn">
                        <Trans>Zum Dashboard</Trans>
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default WelcomeFinish;