import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {companyCard} from "../CardTemplate/CompanyGeneration";
import {useEffect} from "react";
import {t, Trans} from "@lingui/macro";
import {Edit} from "react-feather";
import Skeleton from "react-loading-skeleton";

const CardTemplatePreview = ({setting, selectionMode}) => {
    const [card, setCard] = useState()

    useEffect(() => {
        const createPreview = async () => {
            return await companyCard(t`Name`, t`Beschreibung`, setting.size, setting.background, setting.textColor, setting.round, setting.logo, true, setting.inverted, setting.alignment, setting.noLogo, setting.noLogoBack, setting.noText, setting.whiteLabel, setting.material)
        }

        createPreview().then(card => setCard(card))
    }, [setting])

    return (
        <div className="card_template_preview">
            <p style={{marginBottom: "0.5rem", fontSize: "18px"}}>{setting.name ?? 404}</p>
            {
                card ?
                    <>
                        <img key={0} src={card[0]} alt="cardFront" className="cardPreview mini_card_preview"/>
                        <img key={1} src={card[1]} alt="cardBack" className="cardPreview mini_card_preview"/>
                    </>
                    :
                    <>
                        <Skeleton containerClassName="cardPreview rectangle mini_card_preview" style={{height: "100%", borderRadius: "20px"}}/>
                        <Skeleton containerClassName="cardPreview rectangle mini_card_preview" style={{height: "100%", borderRadius: "20px"}}/>
                    </>
            }
            <Link to={(selectionMode ? "/ordering/" : "/templates/card/") + setting.id}>
                <button className="btn">
                    {
                        selectionMode ?
                            <Trans>Auswählen</Trans>
                            :
                            <>
                                <Edit stroke={"#fff"}/>
                                <Trans>Bearbeiten</Trans>
                            </>
                    }
                </button>
            </Link>
        </div>
    );
};

export default CardTemplatePreview;