import React, {useRef} from "react";
import Popup from "reactjs-popup";
import {t, Trans} from "@lingui/macro";
import {Settings} from "react-feather";
import PopupFrame from "../Widgets/PopupFrame";
import TemplateSettings from "./Container/TemplateSettings";
import AreYouSure from "../Widgets/AreYouSure";

const TemplatesHeader = ({title, desc, templateName, setTemplateName, deleteTemplate}) => {
    const settingsRef = useRef()
    const deleteTemplatePopRef = useRef()

    return (
        <header className="header">
            <div>
                <div style={{display: "flex"}}>
                    <h1>
                        {title}
                        {templateName ? (": " + templateName) : ""}
                    </h1>
                </div>
                <p>{desc}</p>
            </div>
            <Popup
                trigger={
                    <button className="btn greyButton optionButton" style={{
                        position: "absolute",
                        right: "1rem",
                        top: "1rem"
                    }}>
                        <Settings/>
                        <Trans>Einstellungen</Trans>
                    </button>
                }
                modal
                ref={settingsRef}
            >
                <PopupFrame content={
                    <TemplateSettings templateName={templateName} setTemplateName={setTemplateName}
                                      close={() => settingsRef.current.close()}
                                      deleteTemplate={() => deleteTemplatePopRef.current.open()}/>
                }
                            close={() => settingsRef.current.close()}
                            title={t`Vorlageneinstellungen`}
                            description={t`Hier können Sie die Einstellungen der Vorlage ändern.`}
                />
            </Popup>
            <Popup
                ref={deleteTemplatePopRef}
                modal
                contentStyle={{width: "inherit"}}
            >
                <AreYouSure closePopup={() => deleteTemplatePopRef.current.close()} sure={deleteTemplate}
                            action={<Trans>Löschen</Trans>}
                            description={<Trans>Um eine Vorlage zu löschen, darf kein Profil mehr mit diesem
                                verbunden sein.</Trans>}/>
            </Popup>
        </header>
    )
}

export default TemplatesHeader;