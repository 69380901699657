import React from 'react';
import InvitationWorker from "./InvitationWorker";
import {Plural, Trans} from "@lingui/macro";
import {deleteDoc} from "firebase/firestore";
import Skeleton from "react-loading-skeleton";

const InvitationWorkers = ({filteredList, openPopup, numberOfMissingInvites, waiting}) => {
    const deleteCode = (ref) => {
        //deletes an invitation code from the using deleteDoc() from firebase
        deleteDoc(ref).then(() => console.log("deleted")).catch(e => console.log(e))
    }

    return (
        <div className="workers" style={{marginTop: "1rem"}}>
            {
                !waiting || filteredList.length > 0 ?
                    filteredList.length > 0 || numberOfMissingInvites > 0 ?
                        <>
                            {
                                numberOfMissingInvites > 0 &&
                                <div className="centerMsg" style={{marginInline: "auto"}}>
                                    <p style={{marginBottom: "1rem"}}>
                                        <Plural value={numberOfMissingInvites}
                                                one="Für ein Profil wurde bisher keine App-Einladung erstellt"
                                                other="Für # Profile wurden bisher keine App-Einladungen erstellt."
                                        />
                                    </p>
                                    <button className="btn" onClick={openPopup}>
                                        <Trans>App-Einladungen erstellen</Trans>
                                    </button>
                                </div>
                            }
                            {
                                filteredList.map(worker => <InvitationWorker key={worker.id} worker={worker}
                                                                             deleteCode={deleteCode}/>)
                            }
                        </>
                        :
                        <div className="centerMsg" style={{marginInline: "auto"}}>
                            <Trans>Keine Ergebnisse gefunden.</Trans>
                        </div>
                    :
                    <Skeleton count={10} height={123} style={{marginBottom: "1rem", borderRadius: "20px"}}/>
            }
        </div>
    );
};

export default InvitationWorkers;