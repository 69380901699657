import React from 'react';

const BulletPointBenefit = ({icon, title, text}) => {
    return (
        <div className="bullet-point">
            {icon}
            <div>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default BulletPointBenefit;