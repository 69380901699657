import React from 'react';
import AccountIcon from "./AccountIcon";
import SortableAccounts from "./SortableAccounts";
import {Trans} from "@lingui/macro";

const AccountEditor = ({icons, sorted, setAccounts, accounts, errors}) => {
    const onDelete = (id) => {
        const newArray = accounts.filter((account) => account.id !== id)
        setAccounts(newArray)
    }

    const addAccount = (newElement) => {
        const small = accounts.filter(element => element.type === 0)
        if (small.length === 5) {
            newElement.type = 1
        }

        setAccounts(array => [...array, newElement])
    }

    const updateAccount = (account) => {
        setAccounts(accounts.map(acc => acc.id === account.id ? account : acc))
    }

    return (
        <>
            <div>
                <label><Trans>Wählen Sie die zu verbindenden Accounts aus</Trans></label>
                <div className="account-icons">
                    {sorted.map((type, index) => (
                            <AccountIcon icon={icons.find((image) => image.includes(type.icon))}
                                         addAccount={addAccount}
                                         type={type}
                                         key={index}
                            />
                        )
                    )}
                </div>
            </div>
            <SortableAccounts
                accounts={accounts}
                icons={icons}
                sorted={sorted}
                updateAccount={updateAccount}
                onDelete={onDelete}
                errors={errors}

            />
        </>
    );
};

export default AccountEditor;