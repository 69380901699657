const InputText = ({ label, value, placeholder, onChange, error, type, name, ref}) => {
    return (
        <div className="form-control">
            <label>{label}</label>
            <input type={type ? type : "text"}
                   placeholder={placeholder}
                   value={value}
                   onChange={onChange}
                   name={name ?? name}
                   ref={ref}
            />
            <p style={{ color: "red" }}>{error}</p>
        </div>
    );
};

export default InputText;
