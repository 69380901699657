import React, {useEffect, useState} from 'react';
import {SketchPicker} from 'react-color'
import {Trans} from "@lingui/macro";

const ColorChoice = ({background, setBackground, text, setText}) => {
    return (
        <div className="color-picker">
            <div>
                <h2><Trans>Hintergrundfarbe</Trans></h2>
                <ColorPicker color={background} setColor={setBackground}/>
            </div>
            <div>
                <h2><Trans>Textfarbe</Trans></h2>
                <ColorPicker color={text} setColor={setText}/>
            </div>
        </div>
    );
};

const ColorPicker = ({color, setColor}) => {
    const [local, setLocal] = useState(color)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (local?.hex) setColor(local.hex)
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [local, setColor]);

    return (
        <>
            <SketchPicker color={local}
                          onChange={setLocal}
                          disableAlpha={true}
            />
        </>
    );
}

export default ColorChoice;