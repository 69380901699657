import Employee from "./Employee";
import {updateDoc} from "firebase/firestore";
import {deleteObject, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../firebase";
import {v4 as uuidv4} from "uuid";

class ProfileSettings {
    constructor(map, id, ref) {
        this.background = map.covUrl
        this.webLogo = map.webLogo
        this.color = Employee.radixToHex(map.color)
        this.name = map.name ?? "Standard"
        this.id = id
        this.ref = ref
        const smalls = map.array.map(account => Employee.transformToAccount(account, 0))
        this.accounts = [...smalls, ...map.links.map(link => Employee.transformToAccount(link, 1))]

        if (map.links.some(link => !link.type)) {
            updateDoc(this.ref, this.toMap()).then(() => {
                console.log("Updated employee for app format " + this.name)
            })
        }
    }

    toMap() {
        let map = {
            "color": Employee.hexToRadix(this.color),
            "array": [...this.accounts
                .filter(account => account.type === 0)
                .map(account => Employee.transformToCards(account, 0))
            ],
            "links": [...this.accounts
                .filter(account => account.type === 1)
                .map(account => Employee.transformToCards(account, 1, true))
            ],
            "name": this.name
        }

        if (this.background) {
            map["covUrl"] = this.background
        }

        if (this.webLogo) {
            map["webLogo"] = this.webLogo
        }

        return map
    }

    static uploadImages = async (url, updated, image, setUrl, setUpdated) => {
        let imageURL = url
        if (updated) {
            if (url) {
                try {
                    const desertRef = ref(storage, url)
                    await deleteObject(desertRef)
                } catch (e) {
                    console.log(e)
                }
            }

            const image_id = uuidv4()
            const storageRef = ref(storage, "profile_backgrounds/" + image_id)

            const compressed_file = await Employee.compressImage(Employee.dataURLtoFile(image, "logo.png"))
            const snapshot = await uploadBytes(storageRef, compressed_file)

            imageURL = await getDownloadURL(snapshot.ref)
            setUrl(imageURL)
            setUpdated(false)
        }

        return imageURL
    }
}

export default ProfileSettings;