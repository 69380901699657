import React, {useState} from 'react';
import {Trans} from "@lingui/macro";
import {X} from "react-feather";

const AreYouSure = ({closePopup, sure, action, description}) => {
    const [isError, setIsError] = useState(false)

    const result = async () => {
        const res = await sure()
        setIsError(!res)
    }

    return (
        <div className="popup">
            <button onClick={closePopup} className="btn greyButton closePopupButton">
                <X stroke={"#0a0a0a"}/>
            </button>
            <div>
                <h1><Trans>Sind Sie sich sicher?</Trans></h1>
                <p style={isError ? {fontSize: "16px", color: "#e64f58"} : {fontSize: "16px"}}>{description}</p>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "1rem"
            }}>
                <button type="button" onClick={closePopup} className="btn">
                    <Trans>Abbrechen</Trans>
                </button>
                <button type="button" className="btn greyButton" onClick={result}>
                    {action}
                </button>
            </div>
        </div>
    );
};

export default AreYouSure;