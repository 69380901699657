import React from 'react';
import SortingAccount from "./SortingAccount";

const SortableAccounts = ({accounts, sorted, icons, onDelete, updateAccount, errors}) => {
    return (
        <ul>
            {
                accounts.map((account) => {
                    return <SortingAccount account={account}
                                           sorted={sorted}
                                           icons={icons}
                                           onDelete={onDelete}
                                           updateAccount={updateAccount}
                                           errors={errors}
                                           key={account.id}
                    />
                })
            }
        </ul>
    );
};

export default SortableAccounts;