import React from 'react';
import InputUpload from "../../../Widgets/InputUpload";
import {Trans} from "@lingui/macro";
import InputCheck from "../../../Widgets/InputCheck";
import SizeSelector from "../SizeSelector";

const LogoOptions = ({
                         crop,
                         setCrop,
                         round,
                         setRound,
                         inverted,
                         setInverted,
                         size,
                         setSize,
                         material,
                         setLogoUpdated,
                         setLogo,
                         errors,
                         setErrors
                     }) => {
    return (
        <div className="form-container" style={{paddingTop: "1rem"}}>
            <InputUpload text="logo"
                         description={<Trans>Firmenlogo hochladen (.jpg, .png)</Trans>}
                         setUpload={setLogo}
                         crop={crop}
                         setCrop={setCrop}
                         ratio={0}
                         setLogoUpdated={setLogoUpdated}
                         error={errors["logoUpload"]}
                         setError={setErrors}
            />
            <div className="logo-checkboxes">
                <InputCheck description={<Trans>Abgerundetes Logo?</Trans>}
                            checked={round}
                            onChange={() => {
                                const val = !round
                                setRound(val)
                            }}
                />
                {
                    material?.toLowerCase().includes("wood") &&
                    <InputCheck description={<Trans>Logofarben invertieren?</Trans>}
                                checked={inverted}
                                onChange={() => {
                                    const val = !inverted
                                    setInverted(val)
                                }}
                    />
                }
            </div>
            <SizeSelector size={size}
                          setSize={setSize}/>
        </div>
    );
};

export default LogoOptions;