import TemplatesHeader from "../TemplatesHeader";
import {Trans} from "@lingui/macro";
import CardTemplateView from "./CardTemplateView";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';

const CardTemplateContainer = ({settings, saveCard, setWaiting, deleteCardTemplate, waiting, shopify_client}) => {
    const {templateId} = useParams()
    const [templateName, setTemplateName] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        setTemplateName(settings.find(template => template.id === templateId)?.name)
    }, [settings, templateId])

    const deleteTemplate = () => {
        deleteCardTemplate(templateId).then((res) => {
            if (res) navigate("/templates/card")
        })
    }

    return (
        <>
            <TemplatesHeader title={<Trans>Kartenvorlage</Trans>}
                             desc={<Trans>Gestalten Sie eine Vorlage zur Erstellung von Smart Business Cards für Ihre
                                 Mitarbeiter:innen.</Trans>}
                             templateName={templateName}
                             setTemplateName={setTemplateName}
                             deleteTemplate={deleteTemplate}
            />
            <CardTemplateView settings={settings} saveCard={saveCard} setWaiting={setWaiting}
                              deleteCardTemplate={deleteCardTemplate}
                              templateId={templateId} templateName={templateName}
                              waiting={waiting}
                              shopify_client={shopify_client}
                              isSettings={true}
            />
        </>
    );
};

export default CardTemplateContainer;
