import {Trans} from "@lingui/macro";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const CardPreview = ({card}) => {
    return (
        <>
            {
                <div className="card_container">
                    <div>
                        <p><Trans>Vorderseite</Trans></p>
                        {
                            card[0] ?
                                <img key={0} src={card[0]} alt="cardFront" className="cardPreview"/>
                                :
                                <Skeleton className={"cardPreview rectangle"}/>
                        }
                    </div>
                    <div>
                        <p><Trans>Rückseite</Trans></p>
                        {
                            card[1] ?
                                <img key={1} src={card[1]} alt="cardBack" className="cardPreview"/>
                                :
                                <Skeleton className={"cardPreview rectangle"}/>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default CardPreview;
