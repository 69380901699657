import React from 'react';
import {TailSpin} from "react-loader-spinner";

const LoadableButton = ({prompt, waiting}) => {
    return (
        <>
            {!waiting ? prompt : <div style={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "center"
            }}>
                <TailSpin color={"#ffffff"}
                          height={20}
                          width={20}
                          ariaLabel={"Login-Loading-indicator"}
                          wrapperStyle={{
                              display: "initial",
                              paddingLeft: "4px",
                              height: "20px"
                          }}
                />
            </div>}
        </>
    );
};

export default LoadableButton;