import InputText from "../Widgets/InputText";
import InputUpload from "../Widgets/InputUpload";
import Account from "./Account";
import ProfileRow from "./ProfileRow";
import {FaPlus} from "react-icons/fa";
import {useEffect, useRef, useState} from "react";
import Popup from "reactjs-popup";
import AccountIcon from "../Templates/ProfileTemplate/AccountIcon";
import AccountForm from "../Templates/ProfileTemplate/AccountForm";
import {t, Trans} from "@lingui/macro";
import LoadableButton from "../Widgets/LoadableButton";
import {Check, Plus, X} from "react-feather";

const NewWorkerForm = ({
                           workers,
                           deleteWorker,
                           onSubmit,
                           name,
                           setName,
                           errors,
                           description,
                           setDescription,
                           setAccounts,
                           editId,
                           sorted,
                           accounts,
                           setProfile,
                           images,
                           editWorker,
                           form,
                           setting,
                           waiting
                       }) => {
    const addAdditionalsRef = useRef()
    //Um den Crop für ein neues Bild zurückzusetzen
    const [crop, setCrop] = useState()

    const changeAccount = (account) => {
        setAccounts(prev => prev.map(acc => (
            acc.id === account.id ? account : acc
        )));
    }

    const addAccount = (newElement) => {
        const small = accounts.filter(element => element.type === 0)
        if (small.length === 5) {
            newElement.type = 1
        }

        setAccounts(array => [...array, newElement])
    }

    const onDelete = (id) => {
        const newArray = accounts.filter((account) => account.id !== id)
        setAccounts(newArray)
    }

    const updateAccount = (account) => {
        setAccounts(accounts.map(acc => acc.id === account.id ? account : acc))
    }

    useEffect(() => {
        addAdditionalsRef.current.close()
    }, [accounts.length])

    const submitting = (e) => {
        setCrop({
            unit: 'px', // Can be 'px' or '%'
            x: 25,
            y: 25,
            width: 350,
            height: 350
        })
        onSubmit(e)
    }

    return (
        <div className="template-container" style={form ? {marginBlock: 0, width: "550px"} : {}}>
            {
                form ?
                    <ProfileRow workers={workers} editWorker={editWorker} editId={editId} addNewWorker={submitting}/>
                    :
                    <></>
            }
            <form className='add-form' onSubmit={submitting}>
                <div className="form-height">
                    <InputText placeholder={t`Max Mustermann`}
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               label={t`Name`}
                               error={errors["name"]}
                               name="name"
                    />
                    <InputText placeholder={t`HR-Manager`}
                               value={description}
                               onChange={(e) => setDescription(e.target.value)}
                               label={t`Beschreibung`}
                               name="description"
                    />
                    <InputUpload text={t`Profilbild`}
                                 description={t`Profilbild hochladen`}
                                 setUpload={setProfile}
                                 crop={crop}
                                 setCrop={setCrop}
                                 ratio={1}
                    />
                    {
                        accounts
                            .filter(account => !account.master)
                            .map(account => (
                                setting.accounts.some(acc => acc.id === account.id) ?
                                    <Account account={account}
                                             key={account.id}
                                             onChange={changeAccount}
                                             type={sorted.find(type => (
                                                     type.name.includes(account.name)
                                                 )
                                             )}
                                             icon={images.find((image) => image.includes(account.img.split(".png")[0]))}
                                    />
                                    :
                                    <AccountForm account={account}
                                                 icon={images.find((image) => image.includes(account.img.split(".png")[0]))}
                                                 key={account.id}
                                                 type={sorted.find(type => (
                                                         type.name.includes(account.name)
                                                     )
                                                 )}
                                                 onDelete={onDelete}
                                                 updateAccount={updateAccount}
                                                 error={errors[account.id]}
                                                 individual={true}
                                    />
                            ))
                    }
                    <button className="btn greyButton" style={{width: "100%"}}
                            onClick={() => addAdditionalsRef.current.open()}
                            type="button"
                    >
                        <Plus stroke={"#0a0a0a"}/>
                        <Trans>Individuelle Accounts</Trans>
                    </button>
                </div>
                <Popup
                    ref={addAdditionalsRef}
                    contentStyle={{maxWidth: "800px"}}
                    modal
                >
                    <div className="popup">
                        <button onClick={() => addAdditionalsRef.current.close()}
                                className="btn greyButton closePopupButton">
                            <X stroke={"#0a0a0a"}/>
                        </button>
                        <div>
                            <h1><Trans>Individuelle Accounts</Trans></h1>
                            <p style={{fontSize: "16px"}}><Trans>Dieser Account ist nur für dieses Profil.
                                Wählen Sie zunächst eine Accountart aus.</Trans>
                            </p>
                        </div>
                        <label><Trans>Wählen Sie die zu verbindenden Accounts aus</Trans></label>
                        <div className="account-icons">
                            {sorted.map((type, index) => (
                                    <AccountIcon icon={images.find((image) => image.includes(type.icon))}
                                                 addAccount={addAccount}
                                                 type={type}
                                                 key={index}
                                    />
                                )
                            )}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                            marginTop: "1rem"
                        }}>
                            <button type="button" onClick={() => addAdditionalsRef.current.close()} className="btn">
                                <X stroke={"#fff"}/>
                                <Trans>Abbrechen</Trans>
                            </button>
                        </div>
                    </div>
                </Popup>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    marginTop: "1rem"
                }}>
                    {
                        !form && deleteWorker ?
                            <button type="button" onClick={() => deleteWorker()} className="btn greyButton">
                                <X stroke={"#0a0a0a"}/>
                                <Trans>Abbrechen</Trans>
                            </button>
                            :
                            form && editId ?
                                <button type="button" onClick={() => deleteWorker()} className="btn greyButton">
                                    <X stroke={"#0a0a0a"}/>
                                    <Trans>Löschen</Trans>
                                </button>
                                :
                                <></>
                    }
                    <button type="submit" className={"btn optionButton"}>
                        <LoadableButton waiting={waiting} prompt={
                            !form ?
                                deleteWorker ?
                                    <>
                                        <Check stroke={"#fffffe"}/>
                                        <Trans>Aktualisieren</Trans>
                                    </>
                                    :
                                    <Trans>Speichern & Weiter</Trans>
                                :
                                <>
                                    <FaPlus style={{fontWeight: "400"}}/>
                                    <Trans>Profil hinzufügen</Trans>
                                </>
                        }/>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NewWorkerForm;