class SubscriptionPlan {
    constructor(map, id) {
        this.id = id;
        this.name = map.name;
        this.features = map.description.split(";");
        this.role = map.role;
        this.prices = []
    }

    addPricing = (map, id) => {
        const interval_count = map.interval === "month" ? 1 : 12

        this.prices.push({
            id: id,
            price: new Intl.NumberFormat('de', {
                style: 'currency',
                currency: map.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format((map.unit_amount / 100)),
            pricePerMonth: new Intl.NumberFormat('de', {
                style: 'currency',
                currency: map.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format((map.unit_amount / 100) / interval_count),
            interval: map.interval,
            description: map.description
        })
    }
}

export default SubscriptionPlan