import React, {useState} from 'react';
import {Trans} from "@lingui/macro";
import {Trash2} from "react-feather";
import LogoOptions from "./LogoOptions";

const BacksideChoice = ({
                            noLogo,
                            setNoLogo,
                            whiteLabel,
                            changeVariant,
                            options,
                            noLogoFront,
                            crop,
                            setCrop,
                            round,
                            setRound,
                            inverted,
                            setInverted,
                            size,
                            setSize,
                            material,
                            setLogoUpdated,
                            setLogo,
                            errors,
                            setErrors
                        }) => {
    const priceDifference = new Intl.NumberFormat('de-DE',
        {style: 'currency', currency: 'EUR', maximumSignificantDigits: 2})
        .format(!!options ? parseInt(options[1]?.price) - parseInt(options[0]?.price) : 0)
    const isWhiteLabel = !!options ? options[1]?.value === whiteLabel : false

    const addWhitelabel = (state) => {
        changeVariant("Branding", state ? options[1].value : options[0].value)
    }

    const [showLogoOptions, setShowLogoOptions] = useState(false)

    const changeNoLogo = (state) => {
        setNoLogo(state)
        setShowLogoOptions(!state)
    }

    return (
        <div className="backside-container">
            <div className="backside-form">
                {
                    !isWhiteLabel &&
                    <div className="useLogoPrompt">
                        <h2><Trans>Wollen Sie das bumpee Branding entfernen?</Trans></h2>
                        <button type="button" className="btn" onClick={() => addWhitelabel(true)}>
                            <Trans>Branding entfernen</Trans>{!!priceDifference && ` (+${priceDifference})`}
                        </button>
                    </div>
                }
                <div className="form-container">
                    {
                        noLogoFront && showLogoOptions ?
                            <LogoOptions crop={crop} setCrop={setCrop} round={round} setRound={setRound}
                                         inverted={inverted}
                                         setInverted={setInverted} size={size} setSize={setSize}
                                         material={material}
                                         setLogoUpdated={setLogoUpdated} setLogo={setLogo} errors={errors}
                                         setErrors={setErrors}
                            /> : <button type="button" className={`logo-selection ${!noLogo ? "selected" : ""}`}
                                         onClick={() => changeNoLogo(false)}>
                                <h2><Trans>Mit Logo</Trans></h2>
                            </button>
                    }
                    <button type="button" onClick={() => changeNoLogo(true)}
                            className={`logo-selection ${noLogo ? "selected" : ""}`}>
                        <h2><Trans>Ohne Logo</Trans></h2>
                    </button>
                    <button type="button" className="containerTopButton right" onClick={() => addWhitelabel(false)}>
                        <Trash2/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BacksideChoice;