import React, {useEffect, useRef, useState} from 'react';
import CardTemplateForm from "./CardTemplateForm";
import CardPreview from "./CardPreview";
import {Trans} from "@lingui/macro";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../../firebase";
import Employee from "../../../entities/Employee";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import LoadableButton from "../../Widgets/LoadableButton";
import "./cardTemplate.css"
import useProductVariant from "../../../hooks/UseProductVariant";
import {Check, ChevronLeft, ChevronRight, ShoppingCart, X} from "react-feather";
import Popup from "reactjs-popup";
import PopupFrame from "../../Widgets/PopupFrame";
import OptionButtons from "../../Widgets/OptionButtons";

const CardTemplateView = ({
                              settings,
                              setWaiting,
                              saveCard,
                              templateId,
                              templateName,
                              waiting,
                              shopify_client,
                              isSettings
                          }) => {
    const [card, setCard] = useState([])
    const [errors, setErrors] = useState([])

    const navigate = useNavigate()

    const cancelChangesPop = useRef()

    const [background, setBackground] = useState("");
    const [textColor, setTextColor] = useState("")
    const [logo, setLogo] = useState("")
    const [logoURL, setLogoURL] = useState("")
    const [logoUpdated, setLogoUpdated] = useState(false)
    const [size, setSize] = useState("")
    const [round, setRound] = useState(false)
    const [inverted, setInverted] = useState(false)
    const [alignment, setAlignment] = useState("right aligned")
    const [noLogoBack, setNoLogoBack] = useState(true)
    const [noText, setNoText] = useState(false)

    const setting = settings?.find(setting => templateId === setting.id)
    const [productOptions, variantId, pricing, currentOptions, changeVariant] = useProductVariant(shopify_client, setting)
    const [changesMade, setChangesMade, checkChanges] = CheckForChanges(setting, round, inverted, size, background, textColor, alignment, noLogoBack, noText, variantId, logoUpdated)

    useEffect(() => {
        if (!logo && settings.length > 0) {
            const setting = settings?.find(setting => templateId === setting.id)
            if (!setting) {
                window.history.back()
                return
            }

            setBackground(setting.background)
            setTextColor(setting.textColor)
            setLogo(setting.logo)
            setLogoURL(setting.logo)
            setSize(setting.size)
            setRound(setting.round)
            setInverted(setting.inverted)
            setAlignment(setting.alignment)
            setNoText(setting.noText)
            setNoLogoBack(setting.noLogoBack)
        }
    }, [logo, navigate, settings, templateId])

    const save = async () => {
        setWaiting(true)
        let imageURL = logoURL

        if (logoUpdated) {
            if (logoURL) {
                try {
                    const desertRef = ref(storage, logoURL)
                    // Delete the file
                    // await deleteObject(desertRef)
                    const snapshot = await uploadBytes(desertRef, Employee.dataURLtoFile(logo, desertRef.name))
                    imageURL = await getDownloadURL(snapshot.ref)
                    setLogoURL(imageURL)
                    setLogoUpdated(false)
                } catch (e) {
                    console.log(e)
                }
            } else {
                const image_id = uuidv4()
                const storageRef = ref(storage, 'card_logos/' + image_id);

                const snapshot = await uploadBytes(storageRef, Employee.dataURLtoFile(logo, image_id))
                imageURL = await getDownloadURL(snapshot.ref)
                setLogoURL(imageURL)
                setLogoUpdated(false)
            }
        }

        const setting = settings.find(setting => templateId === setting.id)
        setting.logo = imageURL
        setting.round = round
        setting.inverted = inverted
        setting.size = size
        setting.background = background
        setting.textColor = textColor
        setting.alignment = alignment
        setting.noLogo = currentOptions?.find(option => option.name === "Logo").value === productOptions?.find(option => option.name === "Logo").values[0].value
        setting.noLogoBack = noLogoBack
        setting.noText = noText
        setting.whiteLabel = currentOptions?.find(option => option.name === "Branding").value === productOptions?.find(option => option.name === "Branding").values[1].value
        setting.material = currentOptions?.find(option => option.name === "Material").value
        setting.variantId = variantId
        setting.pricing = pricing
        setting.name = settings.some(setting => setting.id !== templateId && setting.name === templateName) ?
            templateName + "_1" : templateName

        saveCard(setting.toMap(), setting.id).then(() => {
            setChangesMade(false)
            setWaiting(false)
            if (!isSettings)
                navigate("/welcome/finish")
        })
    }

    return (
        <>
            {
                !pricing.includes("NaN") &&
                <p className="price-text"><Trans>Kaufpreis</Trans>: {pricing}<span><Trans>zzgl. Mwst</Trans></span></p>
            }
            <div className="newWorker" style={{justifyContent: "center"}}>
                <CardTemplateForm
                    setCard={setCard}
                    background={background}
                    setBackground={setBackground}
                    setLogo={setLogo}
                    setSize={setSize}
                    setRound={setRound}
                    setLogoUpdated={setLogoUpdated}
                    setTextColor={setTextColor}
                    round={round}
                    inverted={inverted}
                    setInverted={setInverted}
                    size={size}
                    logo={logo}
                    textColor={textColor}
                    errors={errors}
                    setErrors={setErrors}
                    productOptions={productOptions}
                    changeVariant={changeVariant}
                    alignment={alignment}
                    setAlignment={setAlignment}
                    currentOptions={currentOptions}
                    noLogoBack={noLogoBack}
                    setNoLogoBack={setNoLogoBack}
                    noText={noText}
                    setNoText={setNoText}
                    checkChanges={checkChanges}
                />
                <div className="editor_preview template_editing_preview">
                    <CardPreview card={card}/>
                </div>
            </div>
            <div className="options-navigation">
                {
                    isSettings ?
                        <>
                            <Popup ref={cancelChangesPop}
                                   modal
                                   contentStyle={{width: "inherit"}}
                            >
                                <PopupFrame close={() => cancelChangesPop.current.close()}
                                            title={<Trans>Änderungen verwerfen?</Trans>}
                                            description={<Trans>Alle Änderungen an dieser Vorlage werden
                                                verworfen.</Trans>}
                                            content={
                                                <OptionButtons cancel={() => cancelChangesPop.current.close()}
                                                               action={() => {
                                                                   console.log("cancel changes")
                                                                   window.history.back()
                                                               }}
                                                               savePrompt={<Trans>Änderungen verwerfen</Trans>}
                                                />
                                            }
                                />
                            </Popup>
                            {
                                changesMade ?
                                    <>
                                        <button className="btn greyButton" style={{height: "40px", marginLeft: "1rem"}}
                                                onClick={() => cancelChangesPop.current.open()}>
                                            <X stroke={"#0a0a0a"}/>
                                            <Trans>Abbrechen</Trans>
                                        </button>
                                        <button className="btn" onClick={save}>
                                            <LoadableButton waiting={waiting} prompt={
                                                <>
                                                    <Check/>
                                                    <Trans>Speichern</Trans>
                                                </>
                                            }
                                            />
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className="btn greyButton" style={{minWidth: "fit-content"}}
                                                onClick={() => window.history.back()}>
                                            <>
                                                <ChevronLeft/>
                                                <Trans>Zu den Vorlagen</Trans>
                                            </>
                                        </button>
                                        <button className="btn whiteButton" onClick={() => navigate("/ordering")}>
                                            <>
                                                <ShoppingCart/>
                                                <Trans>Bestellen</Trans>
                                            </>
                                        </button>
                                    </>
                            }

                        </>
                        :
                        <>
                            <button className="btn" onClick={save}>
                                <LoadableButton waiting={waiting} prompt={
                                    <Trans>Speichern & Weiter</Trans>
                                }/>
                            </button>
                            <button className="btn greyButton optionButton" style={{gap: "0"}}
                                    onClick={() => navigate("/welcome/finish")}>
                                <Trans>Überspringen</Trans>
                                <ChevronRight/>
                            </button>
                        </>
                }
            </div>
        </>
    );
};

const CheckForChanges = (setting, round, inverted, size, background, textColor, alignment, noLogoBack, noText, variantId, logoUpdated) => {
    const [changesMade, setChangesMade] = useState(false)

    const check = () => {
        if (!!setting)
            setChangesMade(setting.round !== round ||
                setting.inverted !== inverted ||
                setting.size !== size ||
                setting.background !== background ||
                setting.textColor !== textColor ||
                setting.alignment !== alignment ||
                setting.noLogoBack !== noLogoBack ||
                setting.noText !== noText ||
                setting.variantId !== variantId ||
                logoUpdated
            )
    }

    return [changesMade, setChangesMade, check]
}

export default CardTemplateView;