import {useEffect, useMemo, useState} from "react";

const useProductVariant = (shopify_client, setting) => {
    const [product, setProduct] = useState(null)
    const [productOptions, setProductOptions] = useState(null)
    const [selectedVariant, setSelectedVariant] = useState(null)

    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        maximumSignificantDigits: 2
    })

    useEffect(() => {
        const companyEdition = "gid://shopify/Product/7998680334600"
        // Fetch all products in your shop
        shopify_client.product.fetch(companyEdition).then((product) => {
            //Join the three options and their price of the variant as well as the image of the variant
            const productOptions = product.options.map((option) => {
                return {
                    name: option.name,
                    values: product.variants.map((variant) => {
                        const selectedOption = variant.selectedOptions.find((selectedOption) => selectedOption.name === option.name)

                        return {
                            value: selectedOption.value,
                            price: formatter.format(variant.priceV2.amount / 1.19),
                            image: variant.image?.src
                        }
                    }).filter((value, index, self) => self.findIndex((t) => (t.value === value.value)) === index)
                }
            })

            setProductOptions(productOptions)
            if (!!setting) {
                const variant = product.variants.find(variant => variant.id === setting.variantId)
                setSelectedVariant(variant ? variant :
                    //if the variantId is not found, select the first variant of the product
                    //if alignment is not set it is an old company edition
                    !!setting.alignment ? product.variants[0] : product.variants[2])
            }
            setProduct(product)
        })
    }, [shopify_client.product, setting])

    const pricing = useMemo(() => {
        return formatter.format(selectedVariant?.priceV2?.amount / 1.19)
    }, [selectedVariant])

    const currentOptions = useMemo(() => {
        return selectedVariant?.selectedOptions.map((option) => {
            return {
                name: option.name,
                value: option.value
            }
        })
    }, [selectedVariant])

    //find variant that has the same values of the previous selectedVariant but changes the defined option to the new value
    const changeVariant = (option_name, option_value) => {
        const newVariant = product.variants.find((variant) => {
            return variant.selectedOptions.every((option) => {
                if (option.name === option_name) {
                    return option.value === option_value
                } else {
                    return option.value === selectedVariant.selectedOptions.find((selectedOption) => selectedOption.name === option.name).value
                }
            })
        })

        setSelectedVariant(newVariant)
    }

    return [productOptions, selectedVariant?.id, pricing, currentOptions, changeVariant]
}

export default useProductVariant