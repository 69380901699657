import {Trans} from "@lingui/macro";

const SizeSelector = ({size, setSize}) => {
    const buttons = ["Small", "Medium", "Large"]

    return (
        <div className="form-control">
            <label><Trans>Logogröße</Trans></label>
            <div className="rowSelector">
                {
                    buttons.map((btn, index) => (
                        <button key={index}
                                type="button"
                                className={`btn ${size !== btn && "whiteButton inActiveSize"}`}
                                onClick={(e) => setSize(e.target.value)}
                                value={btn}
                        >{btn}
                        </button>
                    ))
                }
            </div>
        </div>
    );
};

export default SizeSelector;
