class InviteCode {
    constructor(map, id, ref) {
        this.date = map.date;
        this.used = map.used;
        this.profile = map.profile;
        this.id = id;
        this.ref = ref;
    }
}

export default InviteCode;