import React from 'react';
import {Trans} from "@lingui/macro";
import {Check, Clock} from "react-feather";

const UsedIndicator = ({used}) => {
    return (
        <div className={"usedIndicator" + (used ? " usedActive" : "")}>
            {
                used ?
                    <>
                        <Check/>
                        <Trans>Verbunden</Trans>
                    </>
                    :
                    <>
                        <Clock/>
                        <Trans>Noch offen</Trans>
                    </>
            }
        </div>
    );
};

export default UsedIndicator;