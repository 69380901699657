import React from 'react';
import avatar from "../../../img/avatar.png";
import {ReactComponent as Logo} from "../../../img/bumpee-logo.svg"
import PreviewIcon from "./PreviewIcon";
import {Trans} from "@lingui/macro";
import {AutoTextSize} from 'auto-text-size'

const templateName = <Trans>Mitarbeitername</Trans>
const templateDescription = <Trans>Beschreibung</Trans>

const PhonePreview = ({name, desc, background, accountTypes, color, profile, template, images, customLogo, isCustomLogoPreview = false}) => {
    return (
        <div className="phone_preview" style={isCustomLogoPreview ? {height: "fit-content"} : {}}>
            <div className="preview_container">
                <div className="brandidentity">
                    <div className="logo">
                        {
                            customLogo ?
                                <img alt="logo preview" className={"logo-company"} src={customLogo}/>
                                :
                                <Logo fill={color}/>
                        }
                    </div>
                </div>
                {
                    background && <div className="profile-back">
                        <img alt="background preview"
                             className="back-image"
                             src={background}/>
                    </div>
                }
                <div className="profile-avatar" style={!background ? {height: "fit-content"} : {}}>
                    <img alt="profile preview" className="profile-image round"
                         style={!background ? {transform: "none"} : {}}
                         src={profile || avatar}/>
                </div>
                <div className="user">
                    <div className="username">
                        <h1 style={{color: color}}>
                            {name || templateName}
                        </h1>
                    </div>
                    <div className="userinfo">
                        <h2 style={{color: color}}>
                            {desc || templateDescription}
                        </h2>
                    </div>
                </div>
                <ul className="account-list">
                    {accountTypes?.map(account => (
                        account.type === 0 && (template || account.info.urlValue || account.master) &&
                        <PreviewIcon color={color} account={account} key={account.id} icon={
                            images.find(image => image.includes(account.img.split(".png")[0]))
                        }/>
                    ))}
                < /ul>
                <div className="additional-accounts">
                    {accountTypes?.map((account) => {
                        if (account.type === 1 && (template || account.info.urlValue)) {
                            if (account.name === "Address") {
                                const urlPrefix = "https://maps.googleapis.com/maps/api/staticmap?center="
                                const locationString = account.info.location ? account.info.location.latitude + "," + account.info.location.longitude : "48.1434636,11.5779133"
                                const size = "&size=550x200&zoom=13&markers="
                                const key = "&key=AIzaSyAfV_212rurPclTNaWObwBc1mXa7L8JLR4"
                                return <a key={account.id} className="bigButton btn-map" href={account.info.urlValue}
                                          target="_blank"
                                          rel="noreferrer" style={{backgroundColor: color}}
                                >
                                    <img className="mapImg"
                                         src={urlPrefix + locationString + size + locationString + key}
                                         alt="Google Maps preview"
                                    />
                                </a>
                            } else {
                                return <a key={account.id} className="bigButton" href={account.info.urlValue}
                                          target="_blank"
                                          style={{backgroundColor: color, minHeight: "45px"}}
                                          rel="noreferrer"
                                >
                                    <AutoTextSize maxFontSizePx={14} minFontSizePx={5} >
                                        {account.info.descValue !== "" ? account.info.descValue : account.name}
                                    </AutoTextSize>
                                </a>
                            }
                        }

                        return <></>
                    })}
                </div>
            </div>
        </div>
    );
};

export default PhonePreview;