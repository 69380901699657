import React from "react";
import { FaPlus } from 'react-icons/fa';
import {v4 as uuidv4} from "uuid";

const AccountIcon = ({ type, addAccount, icon}) => {
    const regular = {
        "urlValue": "",
        "descValue": ""
    }

    const address = {
        "urlValue": "",
        "location": "",
        "descValue": ""
    }

    const addIcon = () => {
        const newElement = {
            "id": uuidv4(),
            "name": type.name,
            "type": type.name === "Address" ? 1 : 0,
            "info": type.name === "Address" ? address : regular,
            "master": type.name === "Address",
            "img": type.icon + ".png"
        }

        addAccount(newElement)
    }

    return (
        <button className="account-item account-option"
                onClick={addIcon}
        >
            <div className="quantityIndicator">
                {
                    <FaPlus style={{ color: "#fffffe" }} />
                }
            </div>
            <img src={icon}
                 alt="Account type icon"
            />
        </button>
    );
};

export default AccountIcon;