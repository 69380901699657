import Popup from "reactjs-popup";
import {useState} from "react";
import cardMontage from "../../img/header-image-phone.jpg"
import {Trans} from "@lingui/macro";

const PopupOrder = ({onBuy, checkout, setCheckout, workers, addNewEmployees}) => {
    const [loading, setLoading] = useState(false)

    return (
        <Popup trigger={<button className="btn"><Trans>Karten bestellen</Trans></button>}
               contentStyle={{maxWidth: "500px"}}
               modal>
            {close => (
                <div className="popup">
                    <div>
                        <h1>{!checkout ? <Trans>Sind Sie sicher?</Trans> : <Trans>Ready to go!</Trans>}</h1>
                        <p style={{fontSize: "16px"}}>
                            {
                                !checkout ? <Trans>Nachdem Ihre Daten verarbeitet wurden, können Sie zum Checkout
                                        fortfahren.</Trans>
                                    :
                                    <Trans>Ihre Daten wurden erfolgreich vearbeitet.</Trans>
                            }
                        </p>
                    </div>
                    {!checkout && <img className="header-image" src={cardMontage} alt="Collection of accounts"/>}
                    {!checkout ?
                        <button key={0} className="btn"
                                onClick={() => {
                                    onBuy(workers)
                                    setLoading(true)
                                }}
                        >
                            {loading ? <Trans>Daten verarbeiten...</Trans> : <Trans>Bestellen</Trans>}
                        </button>
                        :
                        <a href={checkout}
                           target="_blank" rel="noreferrer"
                           style={{color: "#fffffe"}}
                        >
                            <button className="btn"
                                    onClick={addNewEmployees}
                            >
                                <Trans>Zum Checkout</Trans>
                            </button>
                        </a>
                    }
                    <button className="btn greyButton"
                            onClick={() => {
                                setCheckout('')
                                setLoading(false)
                                close();
                            }}
                    >
                        <Trans>Abbrechen</Trans>
                    </button>
                </div>
            )}
        </Popup>
    );
};

export default PopupOrder;
