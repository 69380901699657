import {Trans} from "@lingui/macro";

const MaterialChoice = ({productOptions, selected, changeVariant}) => {
    // useEffect(() => {
    //     if (productOptions)
    //         setSelected(productOptions[0]?.value);
    // }, [productOptions, setSelected]);

    const changeMaterial = (material) => {
        changeVariant("Material", material.value);
    }

    return (
        <div className="card-material-selector">
            {
                productOptions?.map(material => {
                    return (
                        <button onClick={() => changeMaterial(material)}
                                style={selected === material.value ? {border: "2px solid #0a0a0a"} : {}}
                                key={material.value}
                        >
                            <img src={material.image} alt={material.value}/>
                            <h3>{material.value}</h3>
                            <p><Trans>Ab</Trans>: {material.price}</p>
                        </button>
                    )
                })
            }
        </div>
    );
};

export default MaterialChoice;