import React, {useEffect} from 'react';
import NewWorkerForm from "./NewWorkerForm";
import PhonePreview from "../Templates/ProfileTemplate/PhonePreview";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

const NewWorkerContainer = ({
                                onSubmit,
                                setName,
                                name,
                                setDescription,
                                description,
                                setProfile,
                                errors,
                                settings,
                                editWorker,
                                templateId,
                                accounts,
                                setAccounts,
                                newWorkers,
                                editId,
                                sorted,
                                images,
                                setEditId,
                                form,
                                deleteNewWorker,
                                profile,
                                color,
                                background,
                                setColor,
                                setBackground,
                                webLogo,
                                setWebLogo,
                                setNewWorkers,
                                setErrors,
                                waiting
                            }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (name) {
            setErrors([])
            if (newWorkers.some(w => w.id === editId)) {
                setNewWorkers(prevState =>
                    prevState.map(worker => {
                        if (worker.id === editId)
                            return {"id": editId, name, description, profile, accounts}
                        else return worker
                    })
                )
            } else {
                const id = uuidv4()
                setEditId(id)
                const employee = {id, name, description, profile, accounts}
                const converted = JSON.parse(JSON.stringify(employee))
                setNewWorkers([...newWorkers, converted])
            }
        }
    }, [name, description, profile, accounts])

    useEffect(() => {
        if (settings.length > 0) {
            const setting = settings.find(setting => templateId === setting.id)
            if (!setting) {
                navigate("/")
                return
            }
            setBackground(setting.background)
            setWebLogo(setting.webLogo)
            setColor(setting.color)
            if (!editId) {
                setAccounts(JSON.parse(JSON.stringify(setting.accounts)))
            }
        }
    }, [settings])

    const editEmployee = (id) => {
        const employee = newWorkers.find(worker => worker.id === id)
        setName(employee.name)
        setDescription(employee.description)
        setProfile(employee.profile)
        setAccounts(employee.accounts)
        setEditId(id)
    }

    return (
        <div className="newWorker" style={{justifyContent: "center"}}>
            <div className="editor_container">
                <NewWorkerForm
                    onSubmit={onSubmit}
                    setName={setName}
                    name={name}
                    setDescription={setDescription}
                    description={description}
                    setProfile={setProfile}
                    errors={errors}
                    setting={settings.find(setting => {
                        if (editWorker) return editWorker.template === setting.id
                        return templateId === setting.id
                    })}
                    accounts={accounts}
                    setAccounts={setAccounts}
                    workers={newWorkers}
                    deleteWorker={deleteNewWorker}
                    editId={editId}
                    sorted={sorted}
                    images={images}
                    editWorker={editEmployee}
                    form={form}
                    profileImage={profile}
                    waiting={waiting}
                />
            </div>
            <div className="editor_preview">
                <PhonePreview name={name} desc={description} color={color}
                              background={background}
                              accountTypes={accounts} profile={profile}
                              template={false}
                              images={images}
                              customLogo={webLogo}
                />
            </div>
        </div>
    );
};

export default NewWorkerContainer;