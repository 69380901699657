import React, {useMemo} from 'react';
import PopupFrame from "../../Widgets/PopupFrame";
import {t, Trans} from "@lingui/macro";
import OptionButtons from "../../Widgets/OptionButtons";
import PreviewTable from "./PreviewTable";
import {saveAs} from 'file-saver'

const ExportPopup = ({close, profiles, waiting}) => {
    const codes = useMemo(() => {
        return profiles
            .filter(profile => profile.codes?.length > 0)
            .map(profile => {
                const code = profile.codes.sort((a, b) => b.date - a.date)[0].id
                return {
                    "name": profile.name,
                    //Get profile code from profile's codes array with the most recent date
                    "code": code,
                    "link": "bumpee.co/addProfile/" + code
                }
            })
    }, [profiles])

    const createCsvTemplate = () => {
        //Create csv template with a custom header from the json array codes with the keys name, code and link
        const csvTemplate = codes.map(code => {
            return {
                name: code.name,
                code: code.code,
                link: code.link
            }
        })

        //Append the header to the csv template
        csvTemplate.unshift({
            name: t`Name`,
            code: t`Code`,
            link: t`Einladungslink`
        })

        //Convert the json array to a csv string
        const csvString = csvTemplate.map(row => Object.values(row).join(";")).join("\n")

        //Create a csv file with the csv string and download it
        const csvFile = new Blob([csvString], {type: "text/csv;charset=utf-8"})
        saveAs(csvFile, t`app-einladungen` + ".csv")
    }

    return (
        <PopupFrame close={close} title={<Trans>App Einladungen</Trans>}
                    description={<Trans>Laden Sie die Profile Ihrer Mitarbeitenden herunter.</Trans>}
                    content={
                        <div className="">
                            <PreviewTable profiles={codes}/>
                            <p><Trans>Sie erhalten per Download ein CSV-Dokument im Stile dieser Tabelle.</Trans></p>
                            <OptionButtons cancel={close} action={createCsvTemplate}
                                           savePrompt={<Trans>Herunterladen</Trans>}
                                           waiting={waiting}
                            />
                        </div>
                    }
        />
    );
};

export default ExportPopup;