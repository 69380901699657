import React, {useMemo, useRef, useState} from 'react';
import {t, Trans} from "@lingui/macro";
import FilterOptions from "../../ProfileOverview/FilterOptions";
import {retrieveProfile} from "../../../firebase";
import InvitationWorkers from "./InvitationWorkers";
import Popup from "reactjs-popup";

import AddInvitationsPopup from "./AddInvitationsPopup";
import ExportPopup from "./ExportPopup";
import {Download, UserPlus} from "react-feather";

const InvitationOverview = ({workers, invites, setWaiting, waiting}) => {
    const sortingOptions = [
        {
            value: "name",
            label: t`Nachname`
        },
        {
            value: "inviteRecent",
            label: t`Einladungsdatum`
        }
    ]

    const [filteredList, setFilteredList] = useState(workers)
    const createPopupRef = useRef()
    const exportPopupRef = useRef()

    const inviteWorkers = useMemo(() => {
        const inviteArray = []
        workers.forEach(worker => {
            if (worker.user) {
                inviteArray.push({
                    "id": worker.id,
                    "name": worker.name,
                    "image": worker.profile,
                    "user": worker.user,
                    "connectedOnDate": worker.connectedOnDate
                })
            } else {
                const match = invites.filter(invite => invite.profile === worker.id)
                if (match?.length > 0) {
                    inviteArray.push({
                        "id": match[0].profile,
                        "name": worker.name,
                        "image": worker.profile,
                        "user": worker.user,
                        "codes": match
                    })
                }
            }
        })

        return inviteArray
    }, [workers, invites])

    const numberOfMissingInvites = useMemo(() => {
        //Count workers that are not included in inviteWorkers
        if (invites.length === 0) return workers.length
        return workers.filter(worker => !inviteWorkers.find(invite => invite.id === worker.id)).length
    }, [inviteWorkers, workers])

    const createCodes = () => {
        setWaiting(true)
        const ids = workers
            .filter(worker => !worker.user)
            .map(worker => worker.id)
        retrieveProfile(ids).then(() => {
            setWaiting(false)
            createPopupRef.current.close()
        })
    }

    return (
        <div>
            <header className="header">
                <div>
                    <h1><Trans>App-Einladungen verwalten</Trans></h1>
                    <p style={{fontSize: "1rem"}}><Trans>Verwalten Sie die App Einladungen für Ihre
                        Mitarbeitenden.</Trans></p>
                </div>
                <div className="options-navigation">
                    <button className="btn greyButton optionButton" onClick={() => createPopupRef.current.open()}>
                        <UserPlus/>
                        <Trans>Einladungen erstellen</Trans>
                    </button>
                    <button className="btn optionButton" disabled={inviteWorkers?.length === 0}
                            onClick={() => exportPopupRef.current.open()}>
                        <Download/>
                        <Trans>Exportieren</Trans>
                    </button>
                </div>
            </header>
            <FilterOptions setFilter={setFilteredList} workers={inviteWorkers} sortingOptions={sortingOptions}/>
            <InvitationWorkers filteredList={filteredList}
                               openPopup={() => createPopupRef.current.open()}
                               numberOfMissingInvites={numberOfMissingInvites}
                               waiting={waiting}
            />
            <Popup
                ref={createPopupRef}
            >
                <AddInvitationsPopup close={() => createPopupRef.current.close()} waiting={waiting}
                                     createCodes={createCodes}/>
            </Popup>
            <Popup
                ref={exportPopupRef}
                contentStyle={{width: "60%", maxWidth: "800px"}}
            >
                <ExportPopup close={() => exportPopupRef.current.close()} waiting={waiting} profiles={inviteWorkers}/>
            </Popup>
        </div>
    );
};

export default InvitationOverview;