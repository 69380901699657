import React from 'react';
import {Trans} from "@lingui/macro";
import OptionButtons from "../../Widgets/OptionButtons";
import PopupFrame from "../../Widgets/PopupFrame";
import BulletPointBenefit from "../../WelcomePages/BulletPointBenefit";
import {Clock, Smartphone, Users} from "react-feather";

const bulletPoints = [
    {
        icon: <Smartphone/>,
        title: <Trans>Zugang zur App</Trans>,
        text: <Trans>Eingeladende Mitarbeiter:innen können den generierten Link oder Code benutzen um sich an ihrem
            Smartphone in ihr
            Profil einzuloggen</Trans>
    },
    {
        icon: <Clock/>,
        title: <Trans>Befristeter Zugang</Trans>,
        text: <Trans>Die Einladungen sind für einen bestimmten Zeitraum gültig</Trans>
    },
    {
        icon: <Users/>,
        title: <Trans>Für alle Mitarbeitenden erstellen</Trans>,
        text: <Trans>Um Einladungen für einzelne Mitarbeiter:innen zu erstellen, können Sie diese in der Profilübersicht
            erstellen</Trans>
    }
]

const AddInvitationsPopup = ({close, createCodes, waiting}) => {
    return (
        <PopupFrame
            title={<Trans>Einladungen erstellen</Trans>}
            description={<Trans>Erstellen Sie neue App-Einladungen für Ihre Mitarbeitenden.</Trans>}
            close={close}
            content={
                <>
                    <div className="bullet-point-container">
                        {
                            bulletPoints.map((point, index) =>
                                <BulletPointBenefit title={point.title} text={point.text} icon={point.icon}
                                                    key={index}/>)
                        }
                    </div>
                    <OptionButtons cancel={close} action={createCodes}
                                   savePrompt={<Trans>Erstellen</Trans>}
                                   waiting={waiting}/>
                </>
            }
        />
    );
};

export default AddInvitationsPopup;