import React from 'react';
import {Trans} from "@lingui/macro";
import {Crop, Image} from "react-feather";

const UploadButtons = ({text, open, inputImage}) => {
    return (
        <>
            <label className="btn" htmlFor={text} style={{flexGrow: 1}}>
                <Image stroke="white"/>
                <Trans>Bild auswählen</Trans>
            </label>
            {
                inputImage && <button className="btn greyButton cropButton" type="button"
                                      onClick={open}>
                    <Crop stroke={"#0a0a0a"}/>
                </button>
            }
        </>
    );
};

export default UploadButtons;