import {Check} from "react-feather";

const InputCheck = ({description, checked, onChange}) => {
    return (
        <label className={`checkbox ${checked ? "checked" : ""}`} onClick={onChange}>
            <Check />
            <p>{description}</p>
        </label>
    );
};

export default InputCheck;
