import React, {useEffect, useState} from 'react';
import InputUpload from "../../Widgets/InputUpload";
import InputText from "../../Widgets/InputText";
import AccountEditor from "./AccountEditor";
import {Trans} from "@lingui/macro";
import CustomLogoUpload from "./CustomLogoUpload";

const ProfileEditor = ({
                           setBackground,
                           setLogo,
                           setColor,
                           color,
                           images,
                           sorted,
                           accounts,
                           setAccounts,
                           setBackgroundUpdated,
                           setLogoUpdated,
                           errors,
                           activePlan,
                           save,
                           waiting
                       }) => {
    const [colorField, setColorField] = useState("")
    const [crop, setCrop] = useState()

    useEffect(() => {
        setColorField(color)
    }, [color])

    const updateColor = (val) => {
        if (val.match(/^[#0-9A-Fa-f]+$/)) {
            if (val[0] !== '#') {
                val = '#' + val
            }
            if (val.length < 8) setColorField(val)
            if (val.length === 7) setColor(val)
        }
    }

    return (
        <div className="template-container">
            {
                activePlan !== "notAvailable" &&
                <CustomLogoUpload activePlan={activePlan} waiting={waiting} save={save} setLogo={setLogo}
                                  setLogoUpdated={setLogoUpdated}/>
            }
            <InputUpload text="backgroundUpload"
                         description={<Trans>Hintergrundbild hochladen</Trans>}
                         setUpload={setBackground}
                         crop={crop}
                         setCrop={setCrop}
                         ratio={16 / 9}
                         setLogoUpdated={setBackgroundUpdated}
                         error={errors["background"]}
            />
            <InputText placeholder="#078080"
                       value={colorField}
                       onChange={
                           (e) => {
                               updateColor(e.target.value)
                           }
                       }
                       label={<Trans>Profilfarbe</Trans>}
            />
            <AccountEditor icons={images}
                           sorted={sorted}
                           accounts={accounts}
                           setAccounts={setAccounts}
                           errors={errors}
            />
        </div>
    )
}

export default ProfileEditor;