import React, {useEffect, useState} from 'react';
import Switch from "../../Widgets/Switch";
import RadioSelector from "../../Widgets/RadioSelector";
import GoogleMapsInput from "../../Widgets/GoogleMapsInput";
import {t, Trans} from "@lingui/macro";
import Popup from "reactjs-popup";
import {Info} from "react-feather";

const AccountForm = ({account, type, onDelete, updateAccount, icon, error, individual}) => {
    const [value, setValue] = useState(account.info.urlValue || "")
    const [description, setDescription] = useState(account.info.descValue || "")
    const [master, setMaster] = useState(account.master)
    const [smallBig, setSmallBig] = useState(account.type)

    useEffect(() => {
        let updatedAccount = account

        updatedAccount.info.urlValue = updateURLValue(value)
        updatedAccount.info.descValue = description
        updatedAccount.master = master
        updatedAccount.type = smallBig

        updateAccount(updatedAccount)
    }, [value, description, master, smallBig])

    const updateURLValue = (val) => {
        if (!val) return ""
        const prefix = type.prefix
        return prefix && val.substring(0, 4) !== "http" && val.substring(0, prefix.length) !== prefix ? prefix + val : val
    }

    return (
        <div className="form-control account-container">
            <div className="account-header" style={{marginBottom: "1rem"}}>
                <div className="account-title">
                    <div className="account-item" style={{marginLeft: "0px"}}>
                        <img src={icon}
                             alt="Account type icon"
                        />
                    </div>
                    <p className="account-text">{account.name}</p>
                </div>
                {
                    !individual && <>
                        <p className="account-text" style={{width: "100%", textAlign: "right"}}>
                            <Trans>Masterinformation</Trans></p>
                        <Popup
                            trigger={<Info style={{flexShrink: 0, width: "1rem"}}/>}
                            position="top center"
                            on="hover"
                            mouseEnterDelay={100}
                            contentStyle={{padding: "10px", fontSize: "0.8rem", maxWidth: "300px", textAlign: "center"}}
                            arrow={true}
                        >
                            <Trans>Masterinformationen werden auf allen Online-Profilen mit den selben Werten gefüllt.
                                Beispielsweise Ihrer Website die auf den Profilen all Ihrer Mitarbeitenden verlinkt sein
                                soll.</Trans>
                        </Popup>
                        <Switch checked={master} handleToggle={() => {
                            setMaster(!master)
                            setValue("")
                        }}/>
                    </>
                }
                {/*<a data-tip data-for="masterInfo"><AiFillQuestionCircle /></a>*/}
                {/*<ReactTooltip id="masterInfo" effect="solid">*/}
                {/*    <span>Hiermit wird unterschieden zwischen den Informationen die auf allen Profilen standardisiert sein sollen und denjenigen die auf die einzelnen Nutzer zu geschnitten sind.</span>*/}
                {/*</ReactTooltip>*/}
                <button className="btn greyButton second-level-btn"
                        style={{padding: 0, height: "30px"}}
                        onClick={() => onDelete(account.id)}><Trans>Entfernen</Trans>
                </button>
            </div>
            {
                (master || individual) && (account.name !== "Address" ?
                        <input type="text"
                               placeholder={type.hint}
                               value={value}
                               onChange={(e) => setValue(e.target.value)}
                               inputMode="text"
                        />
                        :
                        <GoogleMapsInput updateAccount={updateAccount} account={account}/>
                )
            }
            <p style={{color: "red"}}>{error}</p>
            <div className="account-header account-size" style={{marginTop: "1rem"}}>
                {
                    smallBig === 1 && account.name !== "Address" && <input type="text"
                                                                           placeholder={t`Beschreibung`}
                                                                           value={description}
                                                                           onChange={(e) => setDescription(e.target.value)}
                    />
                }
                {(smallBig === 0 || account.name === "Address") &&
                    <p className="account-text"><Trans>Wählen Sie die Button-Art</Trans></p>}
                <RadioSelector selected={smallBig} setSelected={setSmallBig}
                               options={[<Trans>Klein</Trans>, <Trans>Groß</Trans>]}
                               id={account.id}/>
            </div>
        </div>
    );
};

export default AccountForm;