import React from 'react';
import {X} from "react-feather";

const PopupFrame = ({content, title, description, close}) => {
    return (
        <div className="popup">
            <button onClick={close}
                    className="btn greyButton closePopupButton">
                <X stroke={"#0a0a0a"}/>
            </button>
            <div>
                <h1>{title}</h1>
                <p style={{fontSize: "16px"}}>{description}</p>
            </div>
            {content}
        </div>
    );
};

export default PopupFrame;