import React, {useRef} from 'react';
import Popup from "reactjs-popup";
import {useLocation} from "react-router-dom";
import {Trans} from "@lingui/macro";
import {Check, Plus, X} from "react-feather";

const NewTemplate = ({createTemplate, isCard, isPopup}) => {
    // const type = useLocation().pathname
    const isCardTemplate = useLocation().pathname.includes("card") || isCard
    const newTemplatePop = useRef()

    const namenRef = useRef()

    const beschreibung = {
        "card": <Trans>Mit einer Kartenvorlage gestalten Sie die Grundlage für die Smart Business Cards Ihrer
            Mitarbeiter:innen</Trans>,
        "profile": <Trans>Mit einer Profilvorlage gestalten Sie die Grundlagen auf denen die Profile Ihrer
            Mitarbeiter:innen basieren sollen.</Trans>
    }

    const onSubmit = (e) => {
        e.preventDefault()
        createTemplate(namenRef.current.value, isCardTemplate)
    }

    return (
        <button className="card_template_preview new_template_container" onClick={() => newTemplatePop.current.open()}
                style={isPopup && {marginInline: "auto"}}>
            <div className="btn optionButton" style={{margin: "auto"}}>
                <Plus stroke={"#fff"}/>
                <Trans>Neue Vorlage</Trans>
            </div>
            <Popup
                ref={newTemplatePop}
                contentStyle={{maxWidth: "800px"}}
            >
                <div className="popup">
                    <button onClick={() => newTemplatePop.current.close()} className="btn greyButton closePopupButton">
                        <X stroke={"#0a0a0a"}/>
                    </button>
                    <div>
                        <h1>{isCardTemplate ? <Trans>Kartenvorlage erstellen</Trans> : <Trans>Profilvorlage erstellen</Trans>}</h1>
                        <p style={{fontSize: "16px"}}>{isCardTemplate ? beschreibung.card : beschreibung.profile}</p>
                        <form onSubmit={onSubmit}>
                            <div className="form-control">
                                <label><Trans>Vorlagenname</Trans></label>
                                <input type="text"
                                       placeholder="Standard"
                                       name="Vorlagenname"
                                       ref={namenRef}
                                />
                            </div>
                            <button className="btn" type="button" onClick={(e) => onSubmit(e)} style={{margin: "auto"}}>
                                <Check stroke={"#fffffe"}/>
                                <Trans>Speichern</Trans>
                            </button>
                        </form>

                    </div>
                </div>
            </Popup>
        </button>
    );
};

export default NewTemplate;